import React, { Fragment, useEffect, useState  } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

import { useHistory, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get_headerMenu } from '../../../../actions/homepageActions';

const Sitemap = () => {
  const { headermenus } = useSelector((state) => state.home);

  return (
    <Fragment>
      <div className="float-left w-100 contactPage my-3 wow fadeInUp">
        <h3><span>Sitemap Page</span></h3>
        <div className="row">
          <div className="col-md-12 contactForm">
            <div className="sitemapSect">
              <ul>
              <li>
                  <Link to='/'>Home</Link>
                </li>
              {headermenus &&
                  headermenus.map((item, key) => {
                    return (
                      <li key={key}>
                        <Link
                          to={`/category/${item.title
                            .split(' ')
                            .join('-')
                            .toLowerCase()}/${item.id}`}
                          title={item.title}
                        >
                          {item.title}
                        </Link>{' '}
                        
                        {item.children.length > 0 && (
                          
                            <ul>
                              {item.children.length > 0 &&
                                item.children.map((child, index) => {
                                  return (
                                    <li key={index}>
                                      <Link
                                        to={`/category/${child.title
                                          .split(' ')
                                          .join('-')
                                          .toLowerCase()}/${child.id}`}
                                        title={child.title}
                                      >
                                        {child.title}
                                      </Link>
                                      {child.children.length > 0 && (
                                        <ul>
                                          {child.children.map((subchild, i) => {
                                            return (
                                              <li key={i}>
                                                <Link
                                                  to={`/category/${subchild.title
                                                    .split(' ')
                                                    .join('-')
                                                    .toLowerCase()}/${
                                                    subchild.id
                                                  }`}
                                                  title={subchild.title}
                                                >
                                                  {subchild.title}
                                                </Link>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      )}
                                    </li>
                                  );
                                })}
                            </ul>
                          
                        )}
                      </li>
                    );
                  })}
                
                
                <li>
                <Link to="/aboutus" title=" About Us ">
                  {' '}
                  About Us{' '}
                </Link>
              </li>
              <li>
              <Link to="/contactus" title=" Contact Us ">
                  {' '}
                  Contact Us{' '}
                </Link>
              </li>
              <li>
              <a
                  href="https://blog.100plusmall.com/"
                  title="BLOG"
                  target="_blank"
                >
                  {' '}
                  Blog{' '}
                </a>
              </li>
              <li>
              <Link to="/carrers" title=" Careers">
                  {' '}
                  Careers{' '}
                </Link>
              </li>
              <li>
                <Link to="FranchiesLogin" title="Become Franchise">
                  Become Franchise
                </Link>
              </li>
              <li>
                <Link to="/contactus" title="Bulk Buy">
                  Bulk Buy
                </Link>
              </li>
              <li>
                <Link to="/contactus" title="B2B">
                  B2B
                </Link>
              </li>
              <li>
              <Link to="/returnpolicy" title="Return Policy">
                  Return Policy
                </Link>
              </li>
              <li>
              <Link to="/termcondition" title="Term of Use">
                  Term of Use
                </Link>
              </li>
              <li>
              <Link to="/privacypolicy" title=" Privacy Policy ">
                  Privacy Policy
                </Link>
              </li>
              <li>
              <Link to="/faq" title=" FAQ's">
                  {' '}
                  FAQ's{' '}
                </Link>
              </li>
              <li>
                <a href="https://www.youtube.com/100plusmall" title="Youtube" target="_blank">
                Youtube
                </a>
              </li>
              
              <li>
                <a href="https://www.facebook.com/100PlusMall/" title="Facebook" target="_blank">
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/100plusmall/" title="LinkedIn" target="_blank">
                LinkedIn
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/100plusmall/" title="Instagram" target="_blank">
                  {' '}
                  Instagram{' '}
                </a>
              </li>
              <li>
                <a href="#" title="Twitter" target="_blank">
                  {' '}
                  Twitter{' '}
                </a>
              </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Sitemap;
