/* eslint-disable no-mixed-operators */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch,Redirect,Link } from 'react-router-dom';
import { useAuth } from '../../hooks';
import Payment from '../Pages/PaymentPage'
import WordDownload from '../Components/download'
import {
  HomePage,
  ProductDetail,
  CartPage,
  OrderSummary,
  AddAddress,
  ChooseAddress,
  OrderDetails,
  UserInfo,
  ListingPage,
  ListingSearch,
  UserAddress,
  Wishlist,
  FranchiesPartner,
  MyOrder,
  SignUp,
  VerifyAccount,
  ForgotPassword,
  LogIn,
  ProductListByCategories,
  ProductsByBrands,
  Aboutus,
  ContactUs,
  Faq,
  TermCondition,
  PrivacyPolicy,
  FranchiesLogin,
  Carrers,
  Support,
  Sitemap,
  ReturnPolicy,
  OrderInvoice
} from '../Pages';
import Header from './Header';
import Footer from './Footer';

import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { isLoggedIn } = useAuth()
  if(!isLoggedIn){
    localStorage.setItem('redirectUrl', path)
  }
  return(
    <Route
    path={path}
    {...rest}
    render={(props) =>
      isLoggedIn === true ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
  )

};

const NotFound = () => (
  <div className="notfoundData">
    <h1>404 - Not Found!</h1>
    <Link to="/">
      Go Home
    </Link>
  </div>
);

function Layout(props) {
  const { pathname } = useLocation();

  return (
    <>
      <ScrollToTop />
     
      <Header />
      <Switch>
        <Route exact path="/orderinvoice/:orderID/:userID" component={OrderInvoice} />
        <Route exact path="/" component={HomePage} />
        <Route exact path="/prductdetails" component={ProductDetail} />
        <Route exact path="/prducts/:product_slug" component={ProductDetail} />
        <Route exact path="/cart" component={CartPage} />
        <Route exact path="/payment" component={Payment} />
        
        <PrivateRoute exact path="/ordersummary" component={OrderSummary} />
        <PrivateRoute exact path="/orderdetails/:orderId" component={OrderDetails} />
        <PrivateRoute exact path="/orderdetails/:orderId/:removeCartData" component={OrderDetails} />
        <PrivateRoute exact path="/useraddress" component={UserAddress} />
        {/* <Route exact path="/Wishlist" component={Wishlist} /> */}
        <PrivateRoute path="/wishlist" component={Wishlist}  /> 
        <PrivateRoute exact path="/myorder" component={MyOrder} />
        <PrivateRoute exact path="/chooseaddress" component={ChooseAddress} />
        <PrivateRoute exact path="/userinfo" component={UserInfo} />
        <Route exact path="/addaddress" component={AddAddress} />
        <Route exact path="/products/:filter" component={ListingPage} />
        <Route exact path="/search/:seachProduct" component={ListingSearch} />
        <Route
          exact
          path="/brands/:brandName/:brandId"
          component={ProductsByBrands}
        />
        <Route
          exact
          path="/category/:metaWord/:catId"
          component={ProductListByCategories}
        />

        <Route exact path="/franchiespartner" component={FranchiesPartner} />
        <Route exact path="/register" component={SignUp} />
        <Route exact path="/verifyaccount" component={VerifyAccount} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/aboutus" component={Aboutus} />
        <Route exact path="/contactus" component={ContactUs} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/termcondition" component={TermCondition} />
        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
        <Route exact path="/franchieslogin" component={FranchiesLogin} />
        <Route exact path="/carrers" component={Carrers} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/sitemap" component={Sitemap} />
        <Route exact path="/returnpolicy" component={ReturnPolicy} />
        <Route exact path="/download" component={WordDownload} />
        <Route exact path="*" component={NotFound} />
      </Switch>
      <Footer />
      {/* {pathname === '/register' ? null : pathname === '/login' ? null : (
        <Footer />
      )} */}
    </>
  );
}

export default Layout;
