import React, { Fragment } from "react";
import {useDispatch,useSelector} from 'react-redux'
const AddAddress = () => {
  const dispatch = useDispatch()
  const {user} = useSelector((state)=>state.account)
  
  return (
    <Fragment>
      <div class="addAddressPage float-left w-100 my-3 wow fadeInUp">
        <div class="addressBx">
          <div class="adressInner float-left w-100">
            <div class="pageTitle float-left w-100 mb-3">
              <h3>Enter Address</h3>
            </div>
            <div class="float-left w-100 addresInfo">
              <form>
              <div class="form-group">
                <label for="inputname">Name</label>
                <input type="text" class="form-control" id="inputname"  placeholder="Enter Name" />
              </div>
              <div class="form-group">
                <label for="inputHn">House No, Flat, Street Number, Building, Office</label>
                <input type="text" class="form-control" id="inputHn" placeholder="Enter House No" />
              </div>
              <div class="form-group">
                <label for="inputArea">Village, Area, Locality, Colony</label>
                <input type="text" class="form-control" id="inputArea" placeholder="Enter Village, Area, Locality, Colony" />
              </div>
              <div class="form-group">
                <label for="inputLandmark">Landmark</label>
                <input type="text" class="form-control" id="inputLandmark" placeholder="Enter Landmark" />
              </div>
              <div class="form-group">
                <label for="inputPin">Pincode</label>
                <input type="text" class="form-control" id="inputPin" placeholder="Enter Pincode" />
              </div>
              <div class="form-group">
                <label for="inputCity">City/District</label>
                <input type="text" class="form-control" id="inputCity" placeholder="Enter City/District" />
              </div>
              <div class="form-group">
                <label for="inputstate">State</label>
                <input type="text" class="form-control" id="inputstate" placeholder="Enter State" />
              </div>
              <button type="submit" class="applyBtn d-inline-block mr-2 backBtn">Cancle</button>
              <button type="submit" class="applyBtn d-inline-block">Save Address</button>
            </form>
            
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AddAddress;