import 'firebase/auth';

import * as firebase from 'firebase/app';

import { firebaseconfig } from './config'

firebase.initializeApp({
  apiKey: firebaseconfig.FIREBASE_APIKEY,
  authDomain: firebaseconfig.FIREBASE_AUTH_DOMAIN,
  databaseURL: firebaseconfig.FIREBASE_DB_URL,
  projectId: firebaseconfig.FIREBASE_PROJECT_ID,
  storageBucket: firebaseconfig.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: firebaseconfig.FIREBASE_MESSAGE_SENDER_ID,
  appId: firebaseconfig.FIREBASE_APP_ID
});

export default firebase;
