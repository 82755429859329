/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Fragment, useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { useHistory, Link } from 'react-router-dom';
import { ImgBaseUrl } from '../../../../config';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import { Sidebar } from '../../../Components';
import { Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMyoders,
  changeOderstatus,
  updateOrderAction,
} from '../../../../actions/accountActions';
import moment from 'moment';

const MyOrder = () => {
  const dispatch = useDispatch();
  const { user, myoders } = useSelector((state) => state.account);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [orderAction, setOrderAction] = useState('');
  const [status, setStatus] = useState();
  const [changeStatuValue, setchangeStatuValue] = useState([]);
  const toggle1 = () => setModal(!modal);

  const fetchMyoders = async (userInfo) => {
    await dispatch(getMyoders(userInfo));
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (user) {
      const reqData = {
        user_id: user && user.id,
        search: '',
        before: '',
      };
      fetchMyoders(reqData);
    }
  }, [user]);

  const [filterValue, setfilterValue] = useState('');
  const handleChange = (e) => {
    const reqData = {
      user_id: user && user.id,
      search: '',
      before: e.target.value,
    };
    setfilterValue(e.target.value);
    fetchMyoders(reqData);
  };
  const [updating, setUpdating] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState();
  const toggle = (id) => setDropdownOpen((prev) => (prev !== id ? id : ''));

  const [orderId, setOrderId] = useState();
  const changeOrder = async () => {
    try {
      const reqData = {
        user_id: user && user.id,
        order_id: orderId,
        order_detail_id:
        status === 4 ? "": changeStatuValue.length > 0 ? changeStatuValue.join() : '',
        status: status,
      };
      setUpdating(true);
      await dispatch(updateOrderAction(reqData));
      setUpdating(false);
      toggle1();
    } catch (error) {
      NotificationManager.error('Opps something went wrong.');
      setUpdating(false);
      toggle1();
    }
  };

  const [search, setSearch] = useState('');
  const handleChangeSearch = (e) => {
    const reqData = {
      user_id: user && user.id,
      search: search,
      before: '',
    };
    fetchMyoders(reqData);
    setSearch(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();
    const reqData = {
      user_id: user && user.id,
      search: search,
      before: '',
    };
    fetchMyoders(reqData);
  };

  const handleChangereturn = (e, id) => {
    if (e.target.checked) {
      setchangeStatuValue((prev) => [...prev, id]);
    } else {
      setchangeStatuValue((prev) => prev.filter((item) => id !== item));
    }
  };

  return (
    <Fragment>
      <div className="userDashboard float-left w-100 my-3 ">
        <div className="row">
          <Sidebar />
          <div className="col-md-9">
            <div className="userInfoDtl float-left w-100 ">
              <h4>Your Orders</h4>
              <div className="float-left w-100 my-3">
                <form onSubmit={submitForm}>
                  <div className="seacrhSect float-left">
                    <input
                      onChange={handleChangeSearch}
                      required
                      value={search}
                      type="text"
                      placeholder="Search Product & Services"
                      className="searchInput float-left"
                    />
                    <button type="submit" className="srchBtn float-left">
                      <img src="images/ico_search.png" alt="" />
                    </button>
                  </div>
                </form>
              </div>
              <div className="previousOrders float-left w-100 mb-3">
                <span className="float-left pr-4">Orders placed in last </span>
                <div className="float-left">
                  <select
                    className="selectTime"
                    onChange={handleChange}
                    value={filterValue}
                  >
                    <option value="" disabled>
                      Choose
                    </option>
                    {/* <option value={1}>1 Months</option> */}
                    <option value={3}>3 Months</option>
                    <option value={6}>6 Months</option>
                    <option value={9}>9 Months</option>
                    <option value={12}>12 Months</option>
                  </select>
                </div>
              </div>
              {isLoading ? (
                <p>Loading ...</p>
              ) : myoders && myoders.length > 0 ? (
                myoders.map((item) => {
                  let a = moment(
                    moment(item.updated_at).format('YYYY-MM-DD'),
                    'YYYY-MM-DD'
                  );

                  let b = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD');
                  const deffre = a.diff(b, 'days'); // 1

                  return (
                    <div className="OrderDtlBx float-left w-100 mb-3">
                      <div className="OrderDtlBxTop float-left w-100 ">
                        <div className="row">
                          <div className="col OrderTopContent text-center">
                            <span>Order Date</span>
                            <strong>
                              {moment(item.created_at).format('DD MMMM, YYYY')}
                            </strong>
                          </div>
                          <div className="col OrderTopContent text-center">
                            <span>Total </span>
                            <strong>Rs {item.total}</strong>
                          </div>
                          <div className="col OrderTopContent text-center">
                            <span>Shipping To </span>
                            <strong>
                              <Dropdown
                                isOpen={dropdownOpen === item.id}
                                toggle={() => toggle(item.id)}
                              >
                                <DropdownToggle caret>
                                  {item.toname}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <div className="p-2">
                                    <span className="d-block w-100">
                                      {`${item.ship_address1}, ${item.ship_address2}`}
                                    </span>
                                    {/* <span className="d-block">Building name</span> */}
                                    <span className="d-block w-100">
                                      {item.ship_city}{' '}
                                    </span>
                                    <span className="d-block w-100">
                                      {item.ship_city}
                                    </span>
                                    <span className="d-block w-100">
                                      {item.ship_state}
                                    </span>
                                    <span className="d-block w-100">
                                      {item.ship_pincode}
                                    </span>
                                  </div>
                                </DropdownMenu>
                              </Dropdown>
                            </strong>
                          </div>
                          {user && user.act_type === 1 && (
                            <div className="col OrderTopContent text-center">
                              <span>Commission </span>
                              <strong>Rs. {item.commission}</strong>
                            </div>
                          )}

                          <div className="col OrderTopContent text-center">
                            <span>Order Number</span>
                            <strong>#{item.id}</strong>
                          </div>
                        </div>
                      </div>
                      <div className="OrderDtlBtm float-left w-100">
                        <div className="orderRow float-left w-100 pb-2">
                          {item.Products.map((product, index) => {
                            return (
                              <div className="row mb-3">
                                <div className="col-md-3">
                                  <p>
                                    <input
                                      type="checkbox"
                                      value={product.id}
                                      id={product.id}
                                      onChange={(e) =>
                                        handleChangereturn(e, product.id)
                                      }
                                      checked={changeStatuValue.includes(
                                        product.id
                                      )}
                                    />
                                    <label for={product.id}>&nbsp;</label>
                                  </p>
                                  <div className="imgb float-left w-100">
                                    <Link
                                      to={`${'/prducts/'}${
                                        product.product_details.slug
                                      }`}
                                    >
                                      <img
                                        src={
                                          ImgBaseUrl +
                                          product.product_details.image
                                        }
                                        onError={(e) => {
                                          e.target.src =
                                            '/images/Img_product.jpg';
                                        }}
                                        alt="product Image"
                                      />
                                    </Link>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="orderDtlName">
                                    <h5>
                                      <Link
                                        to={`${'/prducts/'}${
                                          product.product_details.slug
                                        }`}
                                      >
                                        {product.name}
                                      </Link>
                                    </h5>
                                    <div className="float-left w-100 pt-2 commisionSect">
                                      <p>
                                        <span>Qty</span>
                                        <strong>: {product.qty}</strong>
                                      </p>
                                      <p>
                                        <span>Price</span>
                                        <strong>
                                          <samp>:</samp>
                                          <img
                                            src="/images/ico_rupeeSmall.png"
                                            alt=""
                                          />{' '}
                                          {product.price * product.qty}
                                        </strong>
                                      </p>
                                      {user && user.act_type === 1 && (
                                        <p>
                                          <span>Commissionn</span>
                                          <strong>
                                            <samp>:</samp>
                                            <img
                                              src="/images/ico_rupeeSmall.png"
                                              alt=""
                                            />
                                            {product.commission}
                                          </strong>
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {index === 0 && (
                                  <div className="col-md-3">
                                    <div className="orderActBtn float-left w-100 p-0">
                                      <Link to={`orderdetails/${item.id}`}>
                                        Order detail
                                      </Link>
                                      {item.shipping_status === 3 &&
                                        deffre < 8 && (
                                          <span
                                            onClick={
                                              changeStatuValue.length > 0
                                                ? () => {
                                                    setOrderAction(
                                                      'Return Order'
                                                    );
                                                    setStatus(7);
                                                    setOrderId(item.id);
                                                    toggle1();
                                                  }
                                                : () =>
                                                    NotificationManager.error(
                                                      'Please select Item to return'
                                                    )
                                            }
                                          >
                                            Return Order
                                          </span>
                                        )}
                                      {item.shipping_status !== 3 &&
                                        deffre < 8 && (
                                          <span
                                          onClick={ item.status === 4 ? console.log("Cancelled") : () => {
                                            setOrderAction('Cancel Order');
                                            setStatus(4);
                                            toggle();
                                          }}
                                          // disabled={changeStatuValue.length > 0 ? true : false}
                                        >
                                         { item.status === 4 ? `Cancelled`: `Cancel Order` } 
                                          </span>
                                        )}
                                      {/* <Link
                                        to={`/orderinvoice/${item.id}/${
                                          user && user.id
                                        }`}
                                      >
                                        Download Invocie
                                      </Link> */}
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No record!</p>
              )}
            </div>

            {/* <div className="w-100 float-left paginationSect">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li className="page-item disabled">
                    <a className="page-link" href tabindex="-1">
                      Previous
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href>
                      1
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href>
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href>
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href>
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div> */}
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle1} centered>
        <ModalBody>Are sure want to {orderAction} ?</ModalBody>
        <ModalFooter>
          <button
            color="primary"
            onClick={() => changeOrder()}
            disabled={updating}
            className="applyBtn"
          >
            {updating && <Spinner color="success" />} Yes
          </button>{' '}
          <button
            color="secondary"
            onClick={toggle1}
            className="applyBtn backBtn"
          >
            No
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default MyOrder;
