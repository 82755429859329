import React, { useEffect,useState } from 'react';
import { ImgBaseUrl } from '../../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { get_categories } from '../../../../actions/homepageActions';
import { SlickSlider } from '../../../Components';
import { Link } from 'react-router-dom';

const Category = () => {
  const dispatch = useDispatch();

  const { categories } = useSelector((state) => state.home);
  const [pageNo, setPageNo] = useState(1);

  const fetch_category = async (page) => {
    try {
      await dispatch(get_categories({ page }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
   
    const { last_page = 1 } = categories ? categories:{}
    if(pageNo <= last_page  ){
      console.log("call api")
    fetch_category(pageNo);
    }
  }, [pageNo]);

  const { data = [] } = categories ? categories:{};
  return (
    <>
      <div class="productBx w-100 float-left shopByCat">
        <div class="shopByBrand w-100 float-left">
          <div class="shopByBrandTop w-100 float-left">
            <h3>Shop By Category</h3>
          </div>
          <div class="catList w-100 float-left mt-3">
            <SlickSlider changePage={(next)=>{ next +1 > pageNo && setPageNo(next + 1)}} slideshow={5}>
              {data.map((item, index) => (
                <div class="catBx text-center float-left" key={index}>
                  <div class="catImage">
                    <Link to={`/category/${item.title.split(' ').join('-').toLowerCase()}/${item.id}`} title="">
                      <img
                        src={ImgBaseUrl + item.image}
                        onError={(e) => {
                          e.target.src = 'images/img_cat3.jpg';
                        }}
                        alt={item.title}
                      />
                    </Link>
                  </div>
                  <div class="catName w-100 float-left">
                    <a href title="Storage">
                      {item.title}
                    </a>
                  </div>
                </div>
              ))}
            </SlickSlider>
          </div>
        </div>
      </div>
    </>
  );
};

Category.propTypes = {
  //categories : PropTypes.array
};

export default Category;
