import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom'

const Helment = ({ title ,description,matakeyword}) => {

     const location = useLocation();
     console.log(location)

    return (
        <Helmet>
        <title>{title}</title>
        <meta
          name="description"
          content={description}
        />
        <meta
          name="metakeyword"
          content={matakeyword}
        />
      </Helmet>
    );
};

Helment.defaultProps = {
    title:'100plus',
    description: "100plus",
    matakeyword: "100plus"
  };

Helment.propTypes = {
    title:PropTypes.string,
    description:PropTypes.string,
    metakeyword:PropTypes.string
};




export default Helment;