import React from 'react';


function Breadcrumb(props) {
    return (
        <>
          <div class="breadCrumb w-100 float-left pb-3">
            <ul>
                <li><a href="/" title="Home">Home</a></li>

                <li>Franchies Partner</li>
            </ul>
        </div>
        </>
    );
}

export default Breadcrumb;