import React from 'react';


function OurHistory(props) {
    return (
        <>
          <div className="float-left w-100 ourHistory wow fadeInUp">
            <img src="/images/img_ourHistory.jpg" />
          </div>
        </>
    );
}

export default OurHistory;