/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImgBaseUrl } from '../../../config';
import { addTocart, add_to_wishlist } from '../../../actions/productAction';
import { NotificationManager } from 'react-notifications';
import SocailShare from '../Socialshare';

const ProjctCard = ({ item }) => {
  const [quintity, setQuintity] = useState(1);
  const { user } = useSelector((state) => state.account);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const setaddTocart = async (item) => {
    if (quintity > 0) {
      const cartItem = { ...item };
      cartItem.quintity = quintity;
      await dispatch(addTocart(cartItem));
      NotificationManager.success('Product added in your cart.','',1000);
    } else {
    
      NotificationManager.error('Please choose quintity','',2000);
    }
  };

  const addToWishList = (id) => {
    if (user) {
      const reqtoData = {
        user_id: user.id,
        product_id: id,
        status: 2,
        quantity: 0,
      };
      dispatch(add_to_wishlist(reqtoData));
    } else {
      localStorage.setItem('redirectUrl', `${location.pathname}`);
      history.push('/login');
    }
  };

  return (
    <>
      <div className="proImage">
      <Link to={`${'/prducts/'}${item.slug}`} title={item.title}>
        <img
          src={ImgBaseUrl + item.image}
          onError={(e) => {
            e.target.src = '/images/Img_product.jpg';
          }}
          alt="product Image"
        />
        </Link>
      </div>
      <div className="prod_desc w-100 p-2 text-center float-left">
        <div className="productName w-100 float-left pb-3">
          <Link to={`${'/prducts/'}${item.slug}`} title={item.title}>
            {item.title}
          </Link>
        </div>
        <div className="priceSect w-100 float-left pb-1 text-center">
          <span className="d-inline-block px-2">
            <samp className="d-inline-block">
              <img src="/images/ico_rupeeLarg.png" />
            </samp>
            <samp className="d-inline-block"> {item.plus_price}</samp>
          </span>
          <span className="d-inline-block px-2 priceDiscount red">
            <samp className="d-inline-block">
              <img src="/images/ico_rupeeRed.png" />
            </samp>
            <samp className="d-inline-block smallSize"> {item.mrp}</samp>
          </span>
        </div>
        <div className="counterSect w-100 float-left text-center">
          <div className="float-left btnSect">
            <button
              className="d-inline-block decreaceBtn counterBtn"
              onClick={() => setQuintity(quintity > 0 ? quintity - 1 : 0)}
            >
              -
            </button>
            <input
              type="text"
              value={quintity}
              className="counterInput d-inline-block"
            />
            <button
              className="d-inline-block increaceBtn counterBtn"
              onClick={() => setQuintity(quintity + 1)}
            >
              +
            </button>
          </div>
          <div
            className="float-left productCard"
            onClick={() => setaddTocart(item)}
          >
            <a href title="cart" className="d-inline-block">
              <img src="/images/ico_cartBlack.png" />
            </a>
          </div>
        </div>
        <div className="w-100 float-left text-center socialSect">
          <Link to={`${'/prducts/'}${item.slug}`} title={item.title}>
            <img src="/images/ico_eye.png" />
          </Link>
          <Link to="" title="wishlist" onClick={() => addToWishList(item.id)}>
            <img src="/images/ico_wishlist.png" />
          </Link>
          <SocailShare
            url={`${window.location.origin.toString()}${'/prducts/'}${
              item.slug
            }`}
            media={ImgBaseUrl + item.image}
            prodttitle={item.title}
          />
        </div>
      </div>
    </>
  );
};

ProjctCard.propTypes = {};

export default ProjctCard;
