/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { ImgBaseUrl } from '../../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { get_storeDeals } from '../../../../actions/homepageActions';
import { SlickSlider, ProjectCard } from '../../../Components';

function Store(props) {
  const dispatch = useDispatch();

  const { storedeals } = useSelector((state) => state.home);

  const fetch_store_deals = async (page) => {
    try {
      await dispatch(get_storeDeals(page));
    } catch (err) {
      console.log(err);
    }
  };
  const [pageNo, setPageNo] = useState(1);

  useEffect(() => {
    const { last_page = 1 } = storedeals ? storedeals : {};
    if (pageNo <= last_page) {
      fetch_store_deals(pageNo);
    }
  }, [pageNo]);

  const { data = [] } = storedeals ? storedeals : {};

  return (
    <>
      <div class="productBx w-100 float-left">
        <div class="productTop w-100 float-left pb-2">
          <Link
            to="/products/store-99"
            title="View More"
            class="greenBtn float-right"
          >
            View More
          </Link>
          <h3>Value Store </h3>
        </div>
        <div class="productBtm w-100 float-left">
          <SlickSlider
            changePage={(next) => {
              next + 1 > pageNo && setPageNo(next + 1);
            }}
          >
            {data.map((item, index) => (
              <div className="proSect" key={item.id}>
                <ProjectCard item={item} />
              </div>
            ))}
          </SlickSlider>
        </div>
      </div>
    </>
  );
}

export default Store;
