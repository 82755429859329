import React, { Fragment } from "react";
import { Link } from "react-router-dom";
//import WOW from "wowjs";


const VerifyAccount = () => {
  //const wow = new WOW.WOW();
  //  wow.init();
  return (
    <Fragment>
      <div className="logInPage float-left wow fadeInUp">
            <div className="float-left w-100 logInDtls">
              <div className="logInRight float-right text-center">
                  <h3>Welcome to<span className="d-block"> 100PlusMall</span> </h3>
              </div>
              <div className="logInLeft float-left">
                <div class="logInDesc float-left w-100 text-center">
                    <p>It'll take 48 hours to verify and 
                        activate your Franchise Partner Acounnt, 
                        you can browse through our catalogue 
                        until then!
                    </p>
                    <Link href="/contactus" title="Contact Us!">Contact Us!</Link>
                </div> 
              </div>
            </div>
        </div>
    </Fragment>
  );
}

export default VerifyAccount;