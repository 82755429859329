import axios from '../utils/axios';
import { NotificationManager } from 'react-notifications';
export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const OPEN_LOGIN = '@account/open-login';
export const OPEN_REGISTER = '@account/open-register';
export const WISHLIST = '@account/wish-list';
export const ADDUSERADDRESS = '@account/add-address';
export const USERADDRESS = '@account/user-address';
export const GETMYODERS = '@account/user-oders';
export const GETFRECHISE = '@account/frenchi-list';
export const GETAPPROVEDFRANCHISE = '@account/frenchi-apporved';
export const USERADDRESSPAGI = '@account/address-without-pagi';
export const CHANGEADDRESS = '@account/change-address';
export const CHOOSEADDRESS = '@account/choose-address';
export const ORDERDETAILS = '@account/order-details';
export const SEARCH = '@account/product-search';
export const SEARCHPAGE = '@account/product-search-page';

export function login(userInfo) {
  return async (dispatch) => {
    try {
      let user = null;
      if(userInfo.act_type === 1){
         user = await axios.post('login-frenchise', userInfo);
      }
      else{
         user = await axios.post('login', userInfo);
      }
    
      if (user.statusText === 'OK' || user.data) {
        if (user.data.status === 1) {
          await localStorage.setItem('userId', user.data.data.id);
          const response = await axios.post(`cart-get`, { user_id: user.data.data.id });
          if (response.data.status === 1) {
          if(response.data.data.cart_data.length > 0){
            await response.data.data.cart_data.map(async(item)=>{
              await dispatch(addTocart(item))
            })
          }
        }

          const cartrecord = localStorage.getItem('cart')
        
            ? JSON.parse(localStorage.getItem('cart'))
            : [];

            console.log(cartrecord,'record');
          if (cartrecord.length > 0) {
            await axios.post('cart-add', {
              user_id: user.data.data.id,
              cart: cartrecord,
            });
          }
          localStorage.setItem("cartset","cartupdate")
          dispatch({
            type: LOGIN_SUCCESS,
            payload: { user: user.data.data },
          });
        } else {
          const error = user.data.message
          throw error;
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function addTocart(cart) {
  return async (dispatch) => {
    try {
      const cartData = localStorage.getItem('cart')
        ? JSON.parse(localStorage.getItem('cart'))
        : [];
      if (cartData.length > 0) {
        const Index = cartData.findIndex(
          (item) => Number(item.id) === Number(cart.id)
        );
        if (Index !== -1) {
          cartData[Index].quintity = cartData[Index].quintity + cart.quintity;
          localStorage.setItem('cart', JSON.stringify(cartData));

        } else {
          const setcart = [...cartData, cart];
          localStorage.setItem('cart', JSON.stringify(setcart));

        }
      } else {
        const setcart = [...cartData, cart];
        localStorage.setItem('cart', JSON.stringify(setcart));
      }
    } catch (error) {
      console.log(error);
      // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

// export function addTocart_database(user, cart) {
//   return async (dispatch) => {
//     try {
//       const result = await axios.post('cart-add',{user_id:user, cart:cart});
//       if (result.statusText === 'OK') {
//         dispatch({
//           type: SETCART,
//           payload: { storeDeals: result.data.data },
//         });
//       }
//     } catch (error) {
//       //console.log(error);
//       // dispatch({ type: LOGIN_FAILURE });
//       throw error;
//     }
//   };
// }

export function setUserData() {
  return async (dispatch) => {
    const userId = await localStorage.getItem('userId');
    if(userId){
      const user = await axios.get(`user-detail/${userId}`);
      if (user.statusText === 'OK') {
        dispatch({
          type: SILENT_LOGIN,
          payload: {
            user: user.data.data,
          },
        });
      }
    }
    

  };
}

export function logout(userId, cartUpdate) {
  return async (dispatch) => {
    await axios.post('cart-add', { user_id: userId, cart: cartUpdate });
    dispatch({
      type: LOGOUT,
    });
  };
}

export function register(userInfo) {
  return async (dispatch) => {
    try {
      let user=null;
      
      if(userInfo.act_type === "1" || userInfo.act_type === 1){
        user = await axios.post('user-add-frenchise', userInfo);
      }
      else{

         user = await axios.post('user-add', userInfo);
      }
      
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          if (user.data.data.act_type === 1) {
            localStorage.removeItem('userId');
            localStorage.removeItem('x-auth');
            localStorage.removeItem('UID');
          } else {
            localStorage.setItem('userId', user.data.data.id);
            dispatch({
              type: LOGIN_SUCCESS,
              payload: { user: user.data.data },
            });
          }
        } else {
          throw new Error(user.data.message);
        }
      }
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      const shwoErro = { code: 'allready/user', message: error };
      throw shwoErro;
    }
  };
}

export function validate_user(userInfo) {
  return async (dispatch) => {
    try {
      const user = await axios.post('validate-user', userInfo);
      
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          
        } else {
          throw new Error(user.data.message);
        }
      }
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      const shwoErro = { code: 'allready/user', message: error };
      throw shwoErro;
    }
  };
}




export function updateProfile(update) {
  return async (dispatch) => {
    try {
      const result = await axios.post('user-profile-update', update);
      if (result.statusText === 'OK' && result.data) {
        if (result.data.status === 1) {
          dispatch({
            type: UPDATE_PROFILE,
            payload: result.data.data,
          });
          NotificationManager.success('Profile updated successfully','',2000);
        } else {
          NotificationManager.error('Opps something went wrong.','',2000);
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function changePassword(update) {
  return async (dispatch) => {
    try {
      const result = await axios.post('change-password', update);
      if (result.statusText === 'OK' && result.data) {
        if (result.data.status === 1) {
          // dispatch({
          //   type: UPDATE_PROFILE,
          //   payload: result.data.data
          // });
          NotificationManager.success('Password  updated successfully','',2000);
        } else {
          NotificationManager.error('Opps something went wrong.');
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function openLogin(open_login) {
  return (dispatch) => {
    dispatch({
      type: OPEN_LOGIN,
      payload: open_login,
    });
  };
}

export function openRegister(open_register) {
  return (dispatch) => {
    dispatch({
      type: OPEN_REGISTER,
      payload: open_register,
    });

    dispatch({
      type: OPEN_LOGIN,
      payload: !open_register,
    });
  };
}

export function getWishlist(userInfo) {
  return async (dispatch) => {
    try {
      const user = await axios.get(`wishlist-list/${userInfo}`);
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          dispatch({
            type: WISHLIST,
            payload: user.data.data.data,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function removeWishlistItem(reqData) {
  return async (dispatch) => {
    try {
      const result = await axios.post(`wishlist-remove`, reqData);
      if (result.statusText === 'OK' && result.data) {
        if (result.data.status === 1) {
          dispatch(getWishlist(reqData.user_id));
        } else {
          NotificationManager.error('Opps something went wrong.');
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function getDefaultAddress(reqData) {
  return async (dispatch) => {
    try {
      const shippingAddress = await axios.post(`user-default-address`, {
        user_id: reqData,
        status: 2,
      });
      const billingAddress = await axios.post(`user-default-address`, {
        user_id: reqData,
        status: 3,
      });

      if (
        shippingAddress.statusText === 'OK' &&
        billingAddress.statusText === 'OK' &&
        billingAddress.data &&
        shippingAddress.data
      ) {
        console.log(
          shippingAddress.data.data,
          billingAddress.data.data,
          'address'
        );
        // if(shippingAddress.data.status === 1)
        // {
        //   console.log(shippingAddress)
        const add = [
          { address: shippingAddress.data.data, add_type: 'ship' },
          { address: billingAddress.data.data, add_type: 'bill' },
        ];
        dispatch({
          type: CHOOSEADDRESS,
          payload: add,
        });
        // }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function getUseraddress(userInfo, pageNo = 1) {
  return async (dispatch) => {
    try {
      const user = await axios.get(`user-address/${userInfo}?page=${pageNo}`);
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          dispatch({
            type: USERADDRESS,
            payload: user.data.data,
          });
        } else {
          dispatch({
            type: USERADDRESS,
            payload: [],
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };
}
export function getAddresswithoutpagination(userInfo, pageNo = 1) {
  return async (dispatch) => {
    try {
      const user = await axios.get(`user-address-wpage/${userInfo}`);
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          dispatch({
            type: USERADDRESSPAGI,
            payload: user.data.data,
          });
        } else {
          dispatch({
            type: USERADDRESS,
            payload: [],
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function getMyoders(userInfo) {
  return async (dispatch) => {
    try {
      const user = await axios.post(`orders-list?page=1`, userInfo);
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          dispatch({
            type: GETMYODERS,
            payload: user.data.data,
          });
        } else {
          dispatch({
            type: GETMYODERS,
            payload: user.data.data,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function getFrenchi() {
  return async (dispatch) => {
    try {
      const user = await axios.get(`user-frenchise`);
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          dispatch({
            type: GETFRECHISE,
            payload: user.data.data,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function changeOderstatus(data) {
  return async (dispatch) => {
    try {
      const user = await axios.post(`orders-return-cancel`, data);
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          NotificationManager.success('Order status updated successfully','',2000);
        } else {
          NotificationManager.error('Opps Something went wrong!','',2000);
        }
      }
    } catch (error) {
      throw error;
    }
  };
}
export function updateOrderAction(data) {
  return async (dispatch) => {
    try {
      const user = await axios.post(`ordersdetail-return-cancel`, data);
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          NotificationManager.success('Order status updated successfully','','',2000);
        } else {
          NotificationManager.error('Opps Something went wrong!','','',2000);
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function send_opt(data) {
  return async (dispatch) => {
    try {
      const user = await axios.post(`send-otp`, data);
      if (user.statusText === 'OK' && user.data) {
        NotificationManager.success('OTP sent successfully','',2000);
      }
    } catch (error) {
      NotificationManager.error('Opps Something went wrong!','',2000);
      throw error;
    }
  };
}

export function forgot_password(data) {
  return async (dispatch) => {
    try {
      const user = await axios.post(`forgot-password`, data);
      if (user.statusText === 'OK' && user.data) {
        NotificationManager.success('Password changed successfully','',2000);
      }
    } catch (error) {
      NotificationManager.error('Opps Something went wrong!','',2000);
      throw error;
    }
  };
}

export function verifyotp(data) {
  return async (dispatch) => {
    try {
      const user = await axios.post(`otp-verify`, data);
      if (user.statusText === 'OK' && user.data) {
        if (user.data.data.type === 'error') {
          throw user.data.data.message;
        }
      }
    } catch (error) {
      console.log(error);
      // NotificationManager.error(user.data.data.message);
      NotificationManager.error(error ? error : 'Opps Something went wrong!');
      throw error;
    }
  };
}

export function getApprovedFranchi(userId) {
  return async (dispatch) => {
    try {
      const user = await axios.post(`frenchise-approved`, { user_id: userId });
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          dispatch({
            type: GETAPPROVEDFRANCHISE,
            payload: user.data.data,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };
}
export function order_details(request) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`order-detail`, request);
      if (response.statusText === 'OK' && response.data) {
        if (response.data.status === 1) {
          dispatch({
            type: ORDERDETAILS,
            payload: response.data.data,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function addAddress(reqData) {
  return async (dispatch) => {
    try {
      const user = await axios.post(`user-add-address`, reqData);
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          NotificationManager.success(
            'Frechise request has been sent successfully','',2000
          );
        } else {
          NotificationManager.error('Opps Something went wrong!');
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function changeFrenchise(reqData) {
  return async (dispatch) => {
    try {
      const user = await axios.post(`frenchise-request`, reqData);
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          NotificationManager.success(
            'Frechise request has been sent successfully','',2000
          );
        } else {
          NotificationManager.error('Opps something went wrong.');
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function update_address(reqData) {
  return async (dispatch) => {
    try {
      const user = await axios.post(`user-update-address`, reqData);
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          NotificationManager.success('Address updated successfully','',2000);
        } else {
          NotificationManager.error('Opps something went wrong.','',2000);
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function remove_address(reqData) {
  return async (dispatch) => {
    try {
      const user = await axios.post(`user-remove-address`, reqData);
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          NotificationManager.success('Address removed successfully','',2000);
        } else {
          NotificationManager.error('Opps something went wrong.','',2000);
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function default_address(reqData) {
  return async (dispatch) => {
    try {
      const user = await axios.post(`user-make-default-address`, reqData);
      if (user.statusText === 'OK' && user.data) {
        if (user.data.status === 1) {
          NotificationManager.success('Default address changed.','',2000);
        } else {
          NotificationManager.error('Opps something went wrong.','',2000);
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function change_Address(reqData) {
  return async (dispatch) => {
    try {
      dispatch({
        type: CHOOSEADDRESS,
        payload: reqData,
      });
    } catch (error) {
      throw error;
    }
  };
}

export function product_search(request) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`product-search`, request);
      if (response.statusText === 'OK' && response.data) {
        if (response.data.status === 1) {
          dispatch({
            type: SEARCH,
            payload: response.data.data.data,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };
}

export function product_searchpage(request,pageNo) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`product-search?page=${pageNo}`, request);
      if (response.statusText === 'OK' && response.data) {
        if (response.data.status === 1) {
          dispatch({
            type: SEARCHPAGE,
            payload: response.data.data,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };
}
