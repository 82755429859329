import axios from '../utils/axios';
import { NotificationManager } from 'react-notifications';
export const GET_HOME_PAGE = '@homepage/home-page';
export const GETCATEGORIES = '@homepage/home-categories';
export const GETBRANDS = '@homepage/home-brands';
export const GETHOTDEALS = '@homepage/home-hotdeals';
export const GETSMARTEXCLUSIVE = '@homepage/home-smartexclusive';
export const GETSTOREDEALS = '@homepage/home-store-deals';
export const GETHOMEBANNERS = '@homepage/home-banners';
export const GETFOOTER = '@homepage/footer';
export const GETHEADERMENU = '@homepage/header-menu';
export const GETFOOTERMENU = '@homepage/footer-menu';
export const GETFAQDATA = '@homepage/faq';
export const RECOMMENDED = '@homepage/recommended'

export function get_homepageData(userInfo) {
  return async (dispatch) => {
    try {
      const result = await axios.get('/home');
     
      if(result.statusText === 'OK'){
       dispatch({
          type: GET_HOME_PAGE,
           payload: {homedata: result.data}
        });
      }

    } catch (error) {
      console.log(error)
     // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function get_categories (data) {
  return async (dispatch) => {
    try {
      const result = await axios.get('home/cat?page='+data.page);
     
      if(result.statusText === 'OK'){
       dispatch({
          type: GETCATEGORIES,
           payload: {category: result.data.data}
        });
      }

    } catch (error) {
      console.log(error)
     // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function get_brands () {
  return async (dispatch) => {
    try {
      const result = await axios.get('home/brand');
     
      if(result.statusText === 'OK'){
       dispatch({
          type: GETBRANDS,
           payload: {brand: result.data.data.data}
        });
      }

    } catch (error) {
      console.log(error)
     // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}


export function get_hotdeals (page) {
  return async (dispatch) => {
    try {
      const result = await axios.get(`home/hot-deals?page=${page}`);
     
      if(result.statusText === 'OK'){
       dispatch({
          type: GETHOTDEALS,
           payload: {hotdeal: result.data.data}
        });
      }

    } catch (error) {
      console.log(error)
     // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}


export function get_recommended (page) {
  return async (dispatch) => {
    try {
      const result = await axios.get(`home/recommended?page=${page}`);
     
      if(result.statusText === 'OK'){
       dispatch({
          type: RECOMMENDED,
           payload: {recommendeds: result.data.data}
        });
      }

    } catch (error) {
      console.log(error)
     // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}



export function get_smartExclusive (page) {
  return async (dispatch) => {
    try {
      const result = await axios.get('home/smart-deals?page='+page);
    
      if(result.statusText === 'OK'){
       dispatch({
          type: GETSMARTEXCLUSIVE,
           payload: {smartexclusive: result.data.data}
        });
      }

    } catch (error) {
      console.log(error)
     // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function get_storeDeals (page) {
  return async (dispatch) => {
    try {
      const result = await axios.get('home/store-deals?page='+page);
     
      if(result.statusText === 'OK'){
       dispatch({
          type: GETSTOREDEALS,
           payload: {storeDeals: result.data.data}
        });
      }

    } catch (error) {
      console.log(error)
     // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function get_homeSlider (page) {
  return async (dispatch) => {
    try {

      let [result, result1] = await Promise.all([axios.get('home/sliders?page=1'), axios.get('home/home-slider')]);
     
      if(result.statusText === 'OK'){
       dispatch({
          type: GETHOMEBANNERS,
           payload: {homebanner: result.data.data.data, bannerbottom :result1.data.data.data}
        });
      }

    } catch (error) {
      console.log(error)
     // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function get_Footer (page) {
  return async (dispatch) => {
    try {
      
      let [brands, offers] = await Promise.all([axios.get('home/brands-all'), axios.get('home/offering')]);
      if(brands.statusText === 'OK'){
       dispatch({
          type: GETFOOTER,
           payload: {brands: brands.data.data,offers: offers.data.data}
        });
      }

    } catch (error) {
      console.log(error)
     // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function get_headerMenu (page) {
  return async (dispatch) => {
    try {
      
      let result = await axios.get('home/menus');
      if(result.statusText === 'OK'){
       dispatch({
          type: GETHEADERMENU,
           payload: {headermenu: result.data.data}
        });
      }

    } catch (error) {
      console.log(error)
     // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function get_FooterMenu () {
  return async (dispatch) => {
    try {
      let result = await axios.post('home/menu-list',{ln:1});
      if(result.status == 200){
       dispatch({
          type: GETFOOTERMENU,
           payload: {footermenu: result.data.data}
        });
      }

    } catch (error) {
      console.log(error)
     // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}


export function get_FaqData () {
  return async (dispatch) => {
    try {
      let result = await axios.post('/faq',{ln:1});
      if(result.status == 200){
       dispatch({
          type: GETFAQDATA,
           payload: {faq: result.data.data}
        });
      }

    } catch (error) {
      console.log(error)
     // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function contact_us(reqData) {
  return async (dispatch) => {
    try {
      const result = await axios.post(`contact-support`, reqData);
      if (result.statusText === 'OK' && result.data) {
        if (result.data.status === 1) {
          NotificationManager.success('Our team contact you.','',2000);
        } else {
          NotificationManager.error('Opps something went wrong.','',2000);
        }
      }
    } catch (error) {
      throw error;
    }
  };
}


