import React from 'react';
import { ProjectCard, SlickSlider } from '../../../Components';

function Recommend({ recomendProduct }) {
  return (
    <>
      <div className="w-100 float-left recomnded">
        <div className="productTop w-100 float-left pb-4">
          <h3>You May Also Be Interested In </h3>
          <p>Bringning you the most exclusive products from best brands </p>
        </div>
        <div className="productBtm w-100 float-left">
          <SlickSlider>
            {recomendProduct &&
              recomendProduct.length > 0 &&
              recomendProduct.map((item) => (
                <div className="proSect w-100">
                <ProjectCard item={item} key={item.id} />
                </div>
              ))}
          </SlickSlider>
        </div>
      </div>
    </>
  );
}

export default Recommend;
