// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const APP_VERSION = '2.0.0';
export const API_BASE_URL = 'https://app.100plusmall.com/api';
//export const API_BASE_URL = 'https://app.daivu.in/public/api/';

export const ENABLE_REDUX_LOGGER = false
export const ImgBaseUrl = 'https://app.100plusmall.com/'
export const CodCharge = 0;
//export const ImgBaseUrl = 'https://app.daivu.in/'
//test payments
//export const AppId = '8579707b81255ce62c7151349758';
//live payments
export const AppId = '2409457c34c6c8b4f2b9575c649042';
export const zipcodebaseApiKey ='3554d1c0-0ea9-11eb-b8aa-cd7e88e70d27'

const firebaseconfig = {

    FIREBASE_APIKEY : "AIzaSyD98Ht2HTK3FhpUcPXSlsoEFdi5voYRUhI",
    FIREBASE_AUTH_DOMAIN : "hundredplus-otp-service.firebaseapp.com",
    FIREBASE_DB_URL : "https://hundredplus-otp-service.firebaseio.com",
    FIREBASE_PROJECT_ID : "hundredplus-otp-service",
    FIREBASE_STORAGE_BUCKET :"hundredplus-otp-service.appspot.com",
    FIREBASE_MESSAGE_SENDER_ID : "816820722188",
    FIREBASE_APP_ID : "1:816820722188:web:511404aa4de8ec3648ec29",
}



export  { firebaseconfig } ;

