import React, { Fragment, useEffect, useState } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { removeCart } from '../../../../actions/productAction';
import {
  order_details,
  updateOrderAction,
} from '../../../../actions/accountActions';

import { ImgBaseUrl } from '../../../../config';
import { Button, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import moment from 'moment';
import { date } from 'yup';

const OrderDetails = () => {
  const { orderId, removeCartData } = useParams();
  const dispatch = useDispatch();
  const { user, orderdetails } = useSelector((state) => state.account);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const [orderAction, setOrderAction] = useState('');

  const toggle = () => setModal(!modal);

  const fetchRecord = async () => {
    try {
      const reqData = {
        user_id: user.id,
        order_id: orderId,
      };
      setIsLoading(true);
      await dispatch(order_details(reqData));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const [updating, setUpdating] = useState(false);
  const [changeStatuValue, setchangeStatuValue] = useState([]);
  const [status, setStatus] = useState();
  const ferformAction = async () => {
    const reqData = {
      user_id: user && user.id,
      order_id: orderId,
      order_detail_id:
        status === 4
          ? ''
          : changeStatuValue.length > 0
          ? changeStatuValue.join()
          : '',
      status: status,
    };
    setUpdating(true);
    await dispatch(updateOrderAction(reqData));
    await dispatch(order_details(reqData));
    setchangeStatuValue([]);
    toggle();
    setUpdating(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (user) {
      fetchRecord();
    }
  }, [user, orderId]);

  const handleChange = (e, id) => {
    if (e.target.checked) {
      setchangeStatuValue((prev) => [...prev, id]);
    } else {
      setchangeStatuValue((prev) => prev.filter((item) => id !== item));
    }
  };

  const paymentStatus = ($payment) => {
    let typeValue = '';
    switch ($payment) {
      case 1:
        typeValue = 'Unpaid';
        break;
      case 2:
        typeValue = 'Partial Payment';
        break;
      case 3:
        typeValue = 'Paid';
        break;
      case 4:
        typeValue = 'Refund';
        break;
      default:
        typeValue = 'Default';
    }
    return typeValue;
  };

  const shippingStatus = ($shiping) => {
    let typeValue = '';
    switch ($shiping) {
      case 1:
        typeValue = 'Purchased';
        break;
      case 2:
        typeValue = 'Shipped';
        break;
      case 3:
        typeValue = 'Delivered';
        break;
      default:
        typeValue = 'Default';
    }
    return typeValue;
  };

  const order_status = (status) => {
    let typeValue = '';
    switch (status) {
      case 1:
        typeValue = 'New';
        break;
      case 2:
        typeValue = 'Confirmed';
        break;
      case 3:
        typeValue = 'Hold';
        break;
      case 4:
        typeValue = 'Cancelled';
        break;
      case 5:
        typeValue = 'Done';
        break;
      case 6:
        typeValue = 'Failed';
        break;
      case 7:
        typeValue = 'Return';
        break;
      default:
        typeValue = 'Nil';
    }
    return typeValue;
  };

  const [deliveryDate, setdeliveryDate] = useState(0);
  useEffect(() => {
    if (orderdetails) {
      let a = moment(
        moment(orderdetails && orderdetails.updated_at).format('YYYY-MM-DD'),
        'YYYY-MM-DD'
      );

      let b = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD');
      const deffre = a.diff(b, 'days'); // 1
      setdeliveryDate(deffre);
    }
  }, [orderdetails]);

  if (removeCartData === 'cart') {
    localStorage.removeItem('cart');
    dispatch(removeCart());
    return <Redirect to={`/orderdetails/${orderId}`} />;
  }

  return (
    <Fragment>
      {isLoading ? (
        <p>Loading ...</p>
      ) : (
        <div className="orderDetailPage float-left w-100 my-3 wow fadeInUp">
          <div className="orderDtlHeader float-left w-100 mb-3">
            <h4 className="float-left mr-4 ">
              Order # {orderdetails && orderdetails.id} Details{' '}
            </h4>
            <button className="float-left btntransit">
              {orderdetails &&
                paymentStatus(orderdetails && orderdetails.payment_status)}
            </button>
          </div>
          <div className="cardBox float-left w-100 mb-4 p-0 borderNone">
            <div className="row">
              <div className="col-md-4">
                <div className="float-left w-100 h-100 addressDtl">
                  <h5>Shipping Address</h5>
                  <div className="adrsInfo float-left w-100 p-3">
                    <span>{orderdetails && orderdetails.toname}</span>
                    <span>{orderdetails && orderdetails.ship_address1}</span>
                    <span>{orderdetails && orderdetails.ship_address2}</span>
                    <span>{orderdetails && orderdetails.ship_city}</span>
                    <span>{orderdetails && orderdetails.ship_city}</span>
                    <span>{orderdetails && orderdetails.ship_state}</span>
                    <span>{orderdetails && orderdetails.ship_pincode}</span>
                    <span>Contact : {orderdetails && orderdetails.phone}</span>
                  </div>
                </div>
              </div>

              {orderdetails && orderdetails.billing_address && (
                <div className="col-md-4">
                  <div className="float-left w-100 h-100 addressDtl">
                    <h5>Billing Address</h5>
                    <div className="adrsInfo float-left w-100 p-3">
                      <span>
                        {orderdetails && orderdetails.billing_address.name}
                      </span>
                      <span>
                        {' '}
                        {orderdetails &&
                          orderdetails.billing_address.ship_address1}
                      </span>
                      <span>
                        {' '}
                        {orderdetails &&
                          orderdetails.billing_address.ship_address2}
                      </span>
                      <span>
                        {orderdetails && orderdetails.billing_address.ship_city}
                      </span>
                      <span>
                        {orderdetails && orderdetails.billing_address.ship_city}
                      </span>
                      <span>
                        {orderdetails &&
                          orderdetails.billing_address.ship_state}
                      </span>
                      <span>
                        {orderdetails &&
                          orderdetails.billing_address.ship_pincode}
                      </span>
                      <span>
                        Contact :{' '}
                        {orderdetails && orderdetails.billing_address.mobile}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-4">
                <div className="float-left w-100 h-100 addressDtl">
                  <h5>
                    Order Status : In{' '}
                    {orderdetails &&
                      order_status(
                        orderdetails && orderdetails.status
                      )}
                  </h5>

                  <h5>
                    Shipping Status : 
                    {orderdetails &&
                      shippingStatus(
                        orderdetails && orderdetails.shipping_status
                      )}
                  </h5>
                  <h5>
                    Payment Type :{' '}
                    {orderdetails && orderdetails.payment_method === 'cash'
                      ? 'COD'
                      : 'Online Payment'}
                  </h5>
                  {user && user.act_type === 1 && (
                    <h5>
                      Total Commission :{' '}
                      {orderdetails && orderdetails.commission}
                    </h5>
                  )}

                  <h5>Total Amount : {orderdetails && orderdetails.total}</h5>

                  <div className="orderActBtn float-left w-100">
                    <a href title="Track Package">
                      Track Package
                    </a>
                    {shippingStatus(
                      orderdetails && orderdetails.shipping_status
                    ) === 'Delivered' &&
                      deliveryDate < 8 && (
                        <span
                          title="Return Order"
                          onClick={() => {
                            if (changeStatuValue.length > 0) {
                              setOrderAction('Return Order');
                              setStatus(7);
                              toggle();
                            } else {
                              alert('Please select Item to return');
                            }
                          }}
                        >
                          Return Order
                        </span>
                      )}

                    {shippingStatus(
                      orderdetails && orderdetails.shipping_status
                    ) !== 'Delivered' && (
                      <span
                        title="Cancel Order"
                        onClick={
                          orderdetails && orderdetails.status === 4
                            ? console.log('Cancelled')
                            : () => {
                                setOrderAction('Cancel Order');
                                setStatus(4);
                                toggle();
                              }
                        }
                        // disabled={changeStatuValue.length > 0 ? true : false}
                      >
                        {orderdetails && orderdetails.status === 4
                          ? `Cancelled`
                          : `Cancel Order`}
                      </span>
                    )}

                    {/* <Link
                      to={`/orderinvoice/${orderdetails && orderdetails.id}/${
                        user && user.id
                      }`}
                      target="_blank"
                    >
                      Download Invoice{' '}
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="franchPartner float-left w-100 my-2">
            {/* <div className="franchTop float-left w-100">
            <h4>Franchise Partner details </h4>
            <p>
              <span>Name</span>:<strong>Ajay Kumar</strong>
            </p>
            <p>
              <span>Phone</span>:<strong>9999999999</strong>
            </p>
            <p>
              <span>Email</span>:<strong>abc@gmail.com</strong>
            </p>
          </div> */}
            <div className="franchBtm float-left w-100 mt-3">
              {orderdetails &&
                orderdetails.Products.map((item) => {
                  return (
                    <div className="row mb-4">
                      <div className="col-md-2">
                        <div className="float-left pr-2">
                          <div className="new">
                            <p>
                              <input
                                type="checkbox"
                                value={item.id}
                                id={item.id}
                                onChange={(e) => handleChange(e, item.id)}
                                checked={changeStatuValue.includes(item.id)}
                              />
                              <label for={item.id}>&nbsp;</label>
                            </p>
                          </div>
                        </div>
                        <div className="float-left prodImage">
                          <div className="imgb">
                            <a href title="">
                              <img
                                src={`${ImgBaseUrl}${item.product_details.image}`}
                                alt=""
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="partnerOrderDtl float-left w-100">
                          <h5>
                            <a
                              href
                              title="LARAH FLUTED OPALGLASSWARE Premium Dinner Set 27 Pcs -Helena Design "
                            >
                              {item.name}
                            </a>
                          </h5>
                          <p className="orderInfoPrice">
                            <span>Price</span>
                            <strong>
                              <samp>:</samp>
                              <img
                                src="/images/ico_rupeeSmall.png"
                                alt=""
                              />{' '}
                              <span>{item.price * item.qty}</span>
                            </strong>
                          </p>

                          {/* <div className="franchTop float-left w-100">
                      <p>
                        <span>Name</span>:<strong>Ajay Kumar</strong>
                      </p>
                      <p>
                        <span>Phone</span>:<strong>9999999999</strong>
                      </p>
                      <p>
                        <span>Email</span>:<strong>abc@gmail.com</strong>
                      </p>
                    </div> */}
                          {/* <button className="applyBtn">Submit </button> */}
                        </div>
                        {user && user.act_type === 1 && (
                          <p
                            className="orderInfoPrice"
                            style={{ color: '#616161' }}
                          >
                            <span>Commission</span>
                            <strong>
                              <samp>:</samp>
                              <img src="/images/ico_rupeeSmall.png" alt="" />
                              {item.commission}
                            </strong>
                          </p>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          {/* <div className="cardBox float-left w-100 borderNone">
            <div className="row">
              <div className="col-md-2 imageBx">&nbsp;</div>
              <div className="col-md-6 cartProductName"></div>
              <div className="col-md-4 cartPrice">
                <div className="cartProductPrice float-left w-100 h-100">
                  <div className="PriceCommision float-left w-100">
                    <div className="priceSect w-100 float-left">
                      <p>
                        <strong>Subtotal</strong>
                        <span className="d-inline-block pb-1 pl-1 ">
                          {' '}
                          :
                          <samp className="d-inline-block ">
                            <img src="/images/ico_rupeeSmall.png" />
                          </samp>
                          <samp className="d-inline-block smallSize">
                            {orderdetails && orderdetails.subtotal}
                          </samp>
                        </span>
                      </p>
                      <p>
                        <strong>Delivery Charge</strong>
                        <span className="d-inline-block pb-1 pl-1 ">
                          {' '}
                          :
                          <samp className="d-inline-block ">
                            <img src="/images/ico_rupeeSmall.png" />
                          </samp>
                          <samp className="d-inline-block smallSize">
                            {orderdetails && orderdetails.shipping}
                          </samp>
                        </span>
                      </p>
                      {orderdetails && orderdetails.discount > 0 ? (
                        <p>
                          <strong>Discount </strong>
                          <span className="d-inline-block pb-1 pl-1 ">
                            {' '}
                            :
                            <samp className="d-inline-block ">
                              <img src="/images/ico_rupeeSmall.png" alt="" />
                            </samp>
                            <samp className="d-inline-block smallSize">
                              {200}
                            </samp>
                          </span>
                        </p>
                      ) : null}

                      <p>
                        <strong>Total</strong>
                        <span className="d-inline-block pb-1 pl-1 ">
                          {' '}
                          :
                          <samp className="d-inline-block ">
                            <img src="/images/ico_rupeeSmall.png" alt="" />
                          </samp>
                          <samp className="d-inline-block smallSize">
                            {orderdetails && orderdetails.total}
                          </samp>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      )}
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalBody>Are sure want to {orderAction} ?</ModalBody>
        <ModalFooter>
          <button
            color="primary"
            onClick={() => ferformAction()}
            disabled={updating}
            className="applyBtn"
          >
            {updating && <Spinner color="success" />} Yes
          </button>{' '}
          <button
            color="secondary"
            onClick={toggle}
            className="applyBtn backBtn"
          >
            No
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default OrderDetails;
