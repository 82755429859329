import axios from 'axios';
import { API_BASE_URL } from '../config'

const instance = axios.create({
    baseURL: API_BASE_URL
    // headers: {
    //     'Authorization' : `Bearer ${localStorage.getItem('x-auth')}`
    // }

})

instance.interceptors.request.use(
    config => {
      const token = localStorage.getItem('x-auth');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error)
  );

//instance.defaults.baseURL = API_BASE_URL;
//instance.defaults.headers.common['Authorization'] = localStorage.getItem('x-auth') ? `Bearer ${localStorage.getItem('x-auth')}`:'';

export default instance;
