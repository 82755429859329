/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_products } from '../../../../actions/productAction';
import { get_brands} from '../../../../actions/homepageActions';
import Pagination from 'react-js-pagination';
import { ProjectCard,Helmet } from '../../../Components';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useParams } from 'react-router-dom';

const ListingPage = () => {
  const dispatch = useDispatch();
 const { filter } = useParams()
  const { products } = useSelector((state) => state.product);
  const { brands } = useSelector((state) => state.home);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [CountPerPage, setCountPerPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  //filter section
  const [openFilter, setOpenFilter] = useState(false);
  const [brandSelected, setBrandSelected] = useState([]);
  const [priceValue, setPriceValue] = useState('custom');
  const [customMin, setcustomMin] = useState('');
  const [customMax, setcustomMax] = useState('');
  const [sortBy, setSortBy] = useState({value:'',label:''});

  const fetch_products = async (page) => {
    try {
      setIsLoading(true);
      const reqTosend = {
        type: filter,
        brandID: brandSelected.length > 0 ? brandSelected.join() : '',
        pricerange:
          priceValue !== 'custom'
            ? priceValue
            : customMin && customMax
            ? `${customMin}-${customMax}`
            : '',
        sortby: sortBy.value ? sortBy.value:'id-asc',
      };
      await dispatch(get_products(reqTosend,page));
      await dispatch(get_brands());
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(setActivePage);
    fetch_products(pageNumber);
  };

  useEffect(() => {
    fetch_products(activePage);
  }, []);

  useEffect(() => {
    if (products) {
      setActivePage(products.current_page);
      setCountPerPage(products.per_page);
      setPageCount(products.last_page);
    }
  }, [products]);

  const apllyFilter = () => {
    fetch_products(activePage);
    setOpenFilter(false);
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);
  const { data = [] } = products ? products : {};

  const handlebrand = (e) => {
    if (e.target.checked) {
      setBrandSelected([...brandSelected, e.target.value]);
    } else {
      const remove = [...brandSelected];
      const index = remove.findIndex((item) => item === e.target.value);
      if (index !== -1) {
        remove.splice(index, 1);
        setBrandSelected([...remove]);
      }
    }
  };

  const sortByfilter = (value)=>{
     setSortBy(value)
   
  }

  useEffect(() => {
    fetch_products(activePage);
  }, [sortBy]);

  return (
    <>
     <Helmet title={filter} description={filter} metakeyword={filter}/>
      <section className="listingPage w-100 float-left pt-2 pb-3">

      <div className="w-100 float-left mb-3 filterSect">
          <div className="float-left applyFilters">
            <div className="filterDropDown" onClick={() => setOpenFilter(!openFilter)}>
              <span>Filters</span>
            </div>
            <div
              className="filterSelectionArea"
              style={{ display: openFilter ? 'block' : 'none' }}
            >
              <div className="filterSectInner float-left w-100">
                <button
                  type="button"
                  className="closeFilter"
                  onClick={() => setOpenFilter(false)}
                >
                  x
                </button>
                <div className="brandSect float-left">
                  <h4>Brands</h4>
                  <div className="new float-left w-100">
                    <div className="scrollDiv float-left w-100">
                      <form action="#">
                        {brands &&
                          brands.length > 0 &&
                          brands.map((item, key) => (
                            <p key={key}>
                              <input
                                type="checkbox"
                                id={item.id}
                                value={item.id}
                                onChange={handlebrand}
                                checked={
                                  brandSelected.length > 0
                                    ? brandSelected.join().includes(item.id)
                                    : false
                                }
                              />
                              <label for={item.id}>{item.name}</label>
                            </p>
                          ))}
                      </form>
                    </div>
                  </div>
                </div>
                <div className="brandSect float-left">
                  <h4>PRICE</h4>
                  <div className="new float-left w-100">
                    <div className="scrollDiv float-left w-100">
                      <form action="#">
                        <p>
                          <input
                            type="checkbox"
                            id="price1"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setPriceValue(e.target.value);
                              }
                            }}
                            value="99-100"
                            checked={priceValue === '99-100'}
                          />
                          <label for="price1">99</label>
                        </p>
                        <p>
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setPriceValue(e.target.value);
                              }
                            }}
                            value="99-299"
                            checked={priceValue === '99-299'}
                            id="price2"
                          />
                          <label for="price2">99 - 299</label>
                        </p>
                        <p>
                          <input
                            type="checkbox"
                            id="price3"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setPriceValue(e.target.value);
                              }
                            }}
                            value="299-599"
                            checked={priceValue === '299-599'}
                          />
                          <label for="price3">299 - 599 </label>
                        </p>
                        <p>
                          <input
                            type="checkbox"
                            id="price4"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setPriceValue(e.target.value);
                              }
                            }}
                            value="599-799"
                            checked={priceValue === '599-799'}
                          />
                          <label for="price4">599 - 799</label>
                        </p>
                        <p>
                          <input
                            type="checkbox"
                            id="price7"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setPriceValue(e.target.value);
                              }
                            }}
                            value="799-999"
                            checked={priceValue === '799-999'}
                          />
                          <label for="price7">799 - 999</label>
                        </p>
                        <p>
                          <input
                            type="checkbox"
                            id="price8"
                            vlaue="custom"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setPriceValue('custom');
                              }
                            }}
                            checked={priceValue === 'custom'}
                          />
                          <label for="price8">Custom</label>
                        </p>
                        <p>
                          <div className="float-left borderBx">
                            <div className="float-left rupee">
                              <img src="/images/ico_rupeeSmall.png" alt="" />
                            </div>
                            <div className="float-left customInput">
                              <input
                                type="number"
                                min="0"
                                value={customMin}
                                onChange={(e) => setcustomMin(e.target.value)}
                              />
                            </div>
                          </div>
                          <div class="float-left px-2 dash">
                            {' '}
                            <span> - </span>{' '}
                          </div>
                          <div className="float-left borderBx">
                            <div className="float-left rupee">
                              <img src="/images/ico_rupeeSmall.png" alt="" />
                            </div>
                            <div className="float-left customInput">
                              <input
                                type="number"
                                min={customMin}
                                value={customMax}
                                onChange={(e) => setcustomMax(e.target.value)}
                              />
                            </div>
                          </div>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="brandSect float-left">
                  <h4>&nbsp;</h4>
                  <div class="new float-left w-100">
                    <div class="actBtn">
                      <button
                        onClick={apllyFilter}
                        type="button"
                        class="font-weight-bold btn-block mb-4 btn-lg text-uppercase applyBtn"
                      >
                        Apply
                      </button>
                      <button
                        onClick={() => setOpenFilter(false)}
                        type="button"
                        class="font-weight-bold btn-block btn-lg text-uppercase backBtn"
                      >
                        Cancle
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sortBy float-right">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
               <DropdownToggle caret>{sortBy.label ? sortBy.label:'Sort'}</DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={()=>{sortByfilter({value:"price-asc",label:"Price Low to High"})}}>Price Low to High</DropdownItem>
                <DropdownItem onClick={()=>{sortByfilter({value:"price-desc",label:"Price High to Low"})}}>Price High to Low</DropdownItem>
                <DropdownItem  onClick={()=>{sortByfilter({value:"id-asc",label:"Sort by oldest product"})}} >Sort by oldest product</DropdownItem>
                <DropdownItem  onClick={()=>{sortByfilter({value:"id-desc",label:"Sort by latest product"})}}>Sort by latest product</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div className="w-100 float-left">
          <div className="row">
            <div className="productList w-100 float-left">
              <div className="productBtm w-100 float-left pb-3">
                {isLoading ? <p>Loading ...</p> :products && data.length > 0 ? 
                  data.map((item, key) => {
                    return (
                    
                    <div className="proSect mb-2" key={item.id}>
                    <ProjectCard item={item} key={item.id} />
                      </div>
                    );
                  }):<p>No record</p>}
              </div>
            </div>
            <div className="w-100 float-left paginationSect tex-center">
              <nav aria-label="Page navigation example">
                {products && data.length > 0 && 
                <Pagination
                innerclassName="pagination justify-content-center"
                itemclassName="page-item"
                linkclassName="page-link"
                prevPageText="Previous"
                nextPageText="Next"
                hideFirstLastPages
                activePage={activePage}
                itemsCountPerPage={CountPerPage}
                totalItemsCount={pageCount}
                //pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
              }
                
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ListingPage;
