import React, { Fragment,useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImgBaseUrl } from '../../../../config';
import { get_FooterMenu } from '../../../../actions/homepageActions';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import parse from 'html-react-parser';

const TermCondition = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { footermenu } = useSelector((state) => state.home);
  const [isLoading, setIsLoading] = useState(false);
  
  const fetch_products = async () => {
    try {
      setIsLoading(true);
      await dispatch(get_FooterMenu());
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetch_products();
  }, []);


  return (
    <Fragment>
    <div className="float-left w-100 contactPage my-3 wow fadeInUp">
    {footermenu.map((item, index) => (
      <div className="row">
      { //Check if message failed
        (item.alias === 'term-of-use')
          ? <div> 
            {parse(item.content.article_description)}
           
          </div> 
          : <div> </div> 
      }
    </div>
    ))}
    </div>
  </Fragment>

    
  );
};

export default TermCondition;
