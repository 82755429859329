import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthProvider, UserProvider } from './contexts';
import './App.css';
import { NotificationContainer } from 'react-notifications';
import Layout from './app/Layout';
import 'react-notifications/lib/notifications.css';

function App() {
  return (
    <AuthProvider>
      <UserProvider>
        <NotificationContainer />
        <Router>
          <Layout />
        </Router>
        {/* <Route path="/" component={Layout} /> */}
      </UserProvider>
    </AuthProvider>
  );
}

export default App;
