import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { get_Footer } from '../../actions/homepageActions';
import { Link } from 'react-router-dom';

const Footer = (props) => {
  const dispatch = useDispatch();

  const { alloffers, allbrands } = useSelector((state) => state.home);
  const fetch_footer = async (page) => {
    try {
      await dispatch(get_Footer(page));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetch_footer();
  }, []);

  return (
    <>
      <section className="float-left w-100 btmStripSect">
        <div className="container">
          <div className="btmList">
            <ul>
              <li>
                <div className="btmStrip">
                  <span className="d-block">
                    <img src="/images/btmIcon1.png" alt="" />
                  </span>
                  <strong>All India Delivery. Kashmir to Kanyakumari</strong>
                </div>
              </li>
              <li>
                <div className="btmStrip">
                  <span className="d-block">
                    <img src="/images/btmIcon2.png" alt="" />
                  </span>
                  <strong>Genuine and Authentic Products from Brands</strong>
                </div>
              </li>
              <li>
                <div className="btmStrip">
                  <span className="d-block">
                    <img src="/images/btmIcon3.png" alt="" />
                  </span>
                  <strong>Verified Products For Women By Women</strong>
                </div>
              </li>
              <li>
                <div className="btmStrip">
                  <span className="d-block">
                    <img src="/images/btmIcon4.png" alt="" />
                  </span>
                  <strong>
                    Local Human Assistance
                  </strong>
                </div>
              </li>
              <li>
                <div className="btmStrip">
                  <span className="d-block">
                    <img src="/images/btmIcon5.png" alt="" />
                  </span>
                  <strong>
                    <Link to="/contactus" style={{ color: '#4b4322' }}>
                      Call and Buy
                    </Link>
                  </strong>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <footer className="w-100 float-left wow slideInUp">
        <div className="container">
          <div className="footerTop w-100 float-left">
            <div className="w-100 float-left pb-3 desc">
              <h5>
                HuPlus Market Innovations Pvt. Ltd was established in 2019.{' '}
              </h5>
              <p>

                100 Plus Mall, is Bharat's First assisted e-store dedicated 
                to women and driven by women in semi-urban and rural areas.
                 Hundred Plus Mall, has Bharatiya roots. We provide verified, 
                 quality Products and original Brands to women, with 100% guarantee.
                  We specialize in kitchenware products from your favourite categories 
                  and leading brands. Our strong focus is on quality homegrown regional 
                  brands such as Suryaflame, Ritu, Glare, Diamond, Rishabh Gold etc
                   with 100 % made in India products.
              </p>
              <p>
                {' '}
                Our vision is to Increase the Happiness Index of Bharat by 
                Reaching the corners of Bharat and giving it access to Unlimited 
                solutions (Retail Products/Brands/Services) with Knowledge and Ease of shopping, 
                by Installing necessary enablers in the Retail Ecosystem.
              </p>
              <p>
                {' '}
                We aim to empower 1 Lakh women from semi-urban and 
                rural areas as entrepreneurs. We provide a platform that would help 
                women to build a financial cushion for themselves and be self-reliant
              </p>
            </div>
            <div className="w-100 float-left offerList">
              <h5>Offerings:</h5>
              {alloffers.map((item) => {
                return (
                  <div className="float-left w-100 pb-2" key={item.id}>
                    <div className="float-left">
                      <span className="float-left">
                        <strong className="text-uppercase">
                          {' '}
                          {item.title} :{' '}
                        </strong>
                      </span>
                      {item.children.map((value) => (
                        <samp className="float-left" key={value.id}>
                          <span>
                            <Link
                              title={value.title}
                              to={{
                                pathname: `/category/${value.title
                                  .split(' ')
                                  .join('-')
                                  .toLowerCase()}/${value.id}`,
                                state: {
                                  metatitle: value.metatitle,
                                  description: value.metatitle,
                                  metakeyword: value.metakeyword,
                                },
                              }}
                            >
                              {value.title}
                            </Link>
                          </span>
                        </samp>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="w-100 float-left offerList">
              <h5>Brands:</h5>
              <ul>
                {allbrands.map((item) => (
                  <li key={item.id}>
                    <span>
                      <Link
                        to={{
                          pathname: `/brands/${item.name
                            .split(' ')
                            .join('-')
                            .toLowerCase()}/${item.id}`,
                          state: {
                            metatitle: item.metatitle,
                            description: item.metatitle,
                            metakeyword: item.metakeyword,
                          },
                        }}
                        title={item.name}
                      >
                        {item.name}
                      </Link>{' '}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="footerBtm w-100 float-left">
            <div className="footerLinks float-left">
              <h6>About</h6>
              <ul>
                {/* <li>
                  <Link to="/aboutus" title=" About Us ">
                    {' '}
                    About Us{' '}
                  </Link>
                </li> */}
                <li>
                  <Link to="/contactus" title=" Contact Us ">
                    {' '}
                    Contact Us{' '}
                  </Link>
                </li>
                <li>
                  <a
                    href="https://blog.100plusmall.com/"
                    title="BLOG"
                    target="_blank"
                  >
                    {' '}
                    Blog{' '}
                  </a>
                </li>
                <li>
                  <Link to="/carrers" title=" Careers">
                    {' '}
                    Careers{' '}
                  </Link>
                </li>
              </ul>
            </div>

            <div className="footerLinks float-left">
              <h6>Buy From Us</h6>
              <ul>
                <li>
                  <Link to="/register" title="Become Franchise">
                    Become Franchise
                  </Link>
                </li>
                <li>
                  <Link to="/contactus" title="Bulk Buy">
                    Bulk Buy
                  </Link>
                </li>
                <li>
                  <Link to="/contactus" title="B2B">
                    B2B
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footerLinks float-left">
              <h6>Policies</h6>
              <ul>
                <li>
                  <Link to="/returnpolicy" title="Return Policy">
                 Return, Refund and Cancellation Policy
                  </Link>
                </li>
                <li>
                  <Link to="/termcondition" title="Term of Use">
                    Term of Use
                  </Link>
                </li>
                <li>
                  <Link to="/privacypolicy" title=" Privacy Policy ">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/faq" title=" FAQ's">
                    {' '}
                    FAQ's{' '}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footerLinks float-left">
              <h6>Reach out of Us</h6>
              <ul>
                <li>
                  <a
                    href="https://www.youtube.com/100plusmall"
                    title="Youtube"
                    target="_blank"
                  >
                    Youtube
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.facebook.com/100PlusMall/"
                    title="Facebook"
                    target="_blank"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/100plusmall/"
                    title="LinkedIn"
                    target="_blank"
                  >
                    LinkedIn
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/100plusmall/"
                    title="Instagram"
                    target="_blank"
                  >
                    {' '}
                    Instagram{' '}
                  </a>
                </li>
                {/* <li>
                  <a href="#" title="Twitter" target="_blank">
                    {' '}
                    Twitter{' '}
                  </a>
                </li> */}
              </ul>
            </div>

            <div className="footerLinks float-left">
              <div className="imgb">
                <img src="/images/trustedLogo.png" alt="" />
              </div>
            </div>
          </div>
          <div className="copyRight float-left w-100 mt-3 pt-4">
            <p>
              Copyright 2020 Huplus Market Innovations Pvt. Ltd.{' '}
              <a href="/sitemap" title="Sitemap">
                Sitemap
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

Footer.propTypes = {};

export default Footer;
