import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get_headerMenu } from '../../actions/homepageActions';
import { logout, product_search } from '../../actions/accountActions';
import { removeCart } from '../../actions/productAction';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { ImgBaseUrl } from '../../config';

import { useAuth } from '../../hooks';

const Header = (props) => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const { headermenus } = useSelector((state) => state.home);
  const { cart } = useSelector((state) => state.product);
  const { user, options } = useSelector((state) => state.account);
  const { pathname } = useLocation();
  const fetch_footer = async (page) => {
    try {
      await dispatch(get_headerMenu(page));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetch_footer();
  }, []);
  const history = useHistory();

  const handleInfo = () => {
    history.push('/userInfo');
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggle1 = () => setDropdownOpen1((prevState) => !prevState);

  const logoutUser = async () => {
    await auth.logout();
    await dispatch(logout(user && user.id, cart));
    await dispatch(removeCart());
    history.push('/');
  };

  const [isLoading, setIsLoading] = useState(false);
  const [slug, setSlug] = useState('');
  const [searchKey, setSearchKey] = useState('');

  const handleSearch =  (e) => {
    try {
     // setIsLoading(true);
      setSearchKey(e.target.value);
     // await dispatch(product_search({ search: query }));
     // setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const hadleSubmit = (e) => {
    e.preventDefault();
    searchKey ? history.push(`/search/${searchKey}`) : console.log(searchKey);
  };

  return (
    <header>
      <div className="headerTop float-left w-100 wow fadeInDown">
        <div className="container">
          <div className="logo float-left mr-5">
            <Link to="/" title="logo">
              <img src="/images/logo.png" alt="logo" />
            </Link>
          </div>
          <div className="float-right topMenu">
            <div className="float-right dotedSection">
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle
                  tag="span"
                  data-toggle="dropdown"
                  aria-expanded={dropdownOpen}
                >
                  <a href title="doted">
                    <img src="/images/doted.png" alt="" />
                  </a>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem href={'/support'}>Support</DropdownItem>
                  {/* <DropdownItem href={'/support'}>Report A Bug</DropdownItem> */}
                </DropdownMenu>
              </Dropdown>
            </div>
            <div className="topRightMenu float-right">
              <ul>
                {!user && (
                  <li className="float-left text-center partner">
                    <Link
                      to={{
                        pathname: '/login',
                        state: { logintype: 'franchise' },
                      }}
                      title="Franchise Partner"
                      className="d-block text-center"
                    >
                      <span className="d-block">
                        <img
                          src="/images/ico_partner.png"
                          alt="Franchise Partner"
                        />
                      </span>
                      Franchise Partner
                    </Link>
                  </li>
                )}
                <li className="float-left text-center login">
                  <Dropdown isOpen={dropdownOpen1} toggle={toggle1}>
                    {user ? (
                      <DropdownToggle
                        tag="span"
                        data-toggle="dropdown"
                        aria-expanded={dropdownOpen1}
                      >
                        <Link className="d-block text-center">
                          <span className="d-block">
                            <img src="/images/ico_user.png" alt="Login" />
                          </span>
                          {user && user.first_name
                            ? `${user.first_name} ${user.last_name}`
                            : 'Welcome'}
                        </Link>
                      </DropdownToggle>
                    ) : (
                      <Link
                        to="/login"
                        title="Login"
                        className="d-block text-center"
                        onClick={handleInfo}
                      >
                        <span className="d-block">
                          <img src="/images/ico_user.png" alt="Login" />
                        </span>
                        Login
                      </Link>
                    )}
                    <DropdownMenu>
                      <DropdownItem>
                        <Link to="/userinfo">
                          <img src="/images/ico_useImage.png" alt="Login" />
                          <span>My Profile</span>
                        </Link>
                      </DropdownItem>
                      <DropdownItem>
                        <Link to="/myorder">
                          <img src="/images/ico_UserBeg.png" alt="Login" />
                          <span>My Oders</span>
                        </Link>
                      </DropdownItem>
                      <DropdownItem>
                        <Link to="/wishlist">
                          <img src="/images/ico_userWishlist.png" alt="Login" />
                          <span>Wishlist</span>
                        </Link>
                      </DropdownItem>
                      <DropdownItem>
                        <Link to="/useraddress">
                          <img src="/images/ico_userAdress.png" alt="Login" />
                          <span>My Address</span>
                        </Link>
                      </DropdownItem>
                      {/* <DropdownItem>
                            <Link to="/wishlist">Wishlist</Link>
                          </DropdownItem> */}
                      <DropdownItem onClick={logoutUser}>
                        {' '}
                        <img src="/images/ico_logout.jpg" alt="Login" />{' '}
                        <span>Logout</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>
                {user && (
                  <li className="float-left text-center phone">
                    <Link
                      to="/wishlist"
                      title="Wish list"
                      className="d-block text-center"
                    >
                      <span className="d-block">
                        <img
                          src="/images/ico_wishList_Green.png"
                          alt="Wish List"
                        />
                      </span>
                    </Link>
                  </li>
                )}

                <li className="float-left text-center phone">
                  <Link
                    to="/contactus"
                    title="Phone"
                    className="d-block text-center"
                  >
                    <span className="d-block">
                      <img src="/images/ico_phone.png" alt="Phone" />
                    </span>
                  </Link>
                </li>
                <li className="float-left text-center cartSect">
                  <a href="/cart" title="Phone" className="d-block text-center">
                    <samp>{cart.length}</samp>
                    <span className="d-block">
                      <img src="/images/ico_cart.png" alt="Cart" />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="seacrhSect float-left">
         
            <form onSubmit={hadleSubmit}>
            <input
              type="text"
              placeholder="Search products"
              className="searchInput float-left"
              onChange={handleSearch}
              value={searchKey}
            />
              {/* <AsyncTypeahead
                id="async-example"
                isLoading={isLoading}
                labelKey="title"
                minLength={3}
                onSearch={handleSearch}
                onChange={(e) => {
                  if (e.length > 0) {
                    setSlug(`/prducts/${e[0].slug}`);
                    history.push(`/prducts/${e[0].slug}`);
                  }
                }}
                // filterBy={['title','slug']}
                options={
                  options ? options : []
                }
                className="searchInput float-left"
                placeholder="Search"
                renderMenuItemChildren={(option, props) => {
                  return (
                    <Link to={`/prducts/${option.slug}`}>
                      <img
                        alt={option.title}
                        src={`${ImgBaseUrl}${option.image}`}
                        style={{
                          height: '24px',
                          marginRight: '10px',
                          width: '24px',
                        }}
                      />
                      <span>{option.title}</span>
                    </Link>
                  );
                }}
              /> */}
              <button
                className="srchBtn float-right"
                type="submit"
                // onClick={() =>
                //   searchKey
                //     ? history.push(`/search/${searchKey}`)
                //     : console.log(searchKey)
                // }
              >
                <img src="/images/ico_search.png" alt="" />
              </button>
            </form>
          </div>
        </div>
      </div>
      {/* {pathname === '/register' ? null : pathname === '/login' ? null : ( */}
      <div className="headerBtm float-left w-100 wow fadeInUp">
        <div className="container">
          <div className="navigation float-left w-100">
            <div className="mobileMenu">
              <a href title="">
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
              </a>
            </div>
            <ul className="float-left navigationList">
              <li className="cloaseMenu">
                <a href="javascript:void(0)" title="">
                  {' '}
                  X{' '}
                </a>
              </li>
              {headermenus &&
                headermenus.map((item, key) => {
                  return (
                    <li key={key}>
                      <Link
                        title={item.title}
                        to={{
                          pathname: `/category/${item.title
                            .split(' ')
                            .join('-')
                            .toLowerCase()}/${item.id}`,
                          state: {
                            metatitle: item.metatitle,
                            description: item.metatitle,
                            metakeyword: item.metakeyword,
                          },
                        }}
                      >
                        {item.title}
                      </Link>{' '}
                      <span
                        style={{
                          display:
                            item.children.length > 0 ? 'inline-block' : 'none',
                        }}
                      >
                        <img src="/images/arrow.png" alt="" />
                      </span>
                      {item.children.length > 0 && (
                        <div className="dropdownMenu">
                          <ul>
                            {item.children.length > 0 &&
                              item.children.map((child, index) => {
                                return (
                                  <li key={index}>
                                    <Link
                                      to={{
                                        pathname: `/category/${child.title
                                          .split(' ')
                                          .join('-')
                                          .toLowerCase()}/${child.id}`,
                                        state: {
                                          metatitle: child.metatitle,
                                          description: child.metatitle,
                                          metakeyword: child.metakeyword,
                                        },
                                      }}
                                      title={child.title}
                                    >
                                      {child.title}
                                    </Link>
                                    {child.children.length > 0 && (
                                      <ul>
                                        {child.children.map((subchild, i) => {
                                          return (
                                            <li key={i}>
                                              <Link
                                                title={subchild.title}
                                                to={{
                                                  pathname: `/category/${subchild.title
                                                    .split(' ')
                                                    .join('-')
                                                    .toLowerCase()}/${
                                                    subchild.id
                                                  }`,
                                                  state: {
                                                    metatitle:
                                                      subchild.metatitle,
                                                    description:
                                                      subchild.metatitle,
                                                    metakeyword:
                                                      subchild.metakeyword,
                                                  },
                                                }}
                                              >
                                                {subchild.title}
                                              </Link>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    )}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      )}
                    </li>
                  );
                })}
            </ul>
            <div className="float-right blogSect">
              <a
                href="https://blog.100plusmall.com/"
                title="BLOG"
                target="_blank"
              >
                <span className="pr-2 float-left">
                  <img src="/images/ico_blog.png" alt="" />
                </span>
                BLOG
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </header>
  );
};

Header.propTypes = {};

export default Header;
