import React, { Fragment, useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  get_FooterMenu,
  contact_us,
} from '../../../../actions/homepageActions';

const suportInfo = {
  id: '',
  suport_email: '',
  suport_name: '',
  mobile: '',
  suport_msg: '',
};

const Support = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [supportForm, setsupportForm] = useState(suportInfo);
  const [formErr, setFormErr] = useState(suportInfo);

  const formValidation = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let error = true;
    const formerror = { ...formErr };
    if (!supportForm.suport_name) {
      error = false;
      formerror.suport_name = 'Name is required!';
    }
    if (!supportForm.suport_email) {
      error = false;
      formerror.suport_email = 'Email is required!';
    }
    if (!supportForm.mobile) {
      error = false;
      formerror.mobile = 'Phone is required!';
    }
    if (!supportForm.suport_msg) {
      error = false;
      formerror.suport_msg = 'Message is required!';
    }
    if (
      supportForm.suport_email &&
      !re.test(String(supportForm.suport_email).toLowerCase())
    ) {
      error = false;
      formerror.suport_email = 'Enter valid email!';
    }
    setFormErr(formerror);
    return error;
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const formvalue = { ...supportForm };
    const formError = { ...formErr };
    formError[name] = '';
    formvalue[name] = value;
    setsupportForm(formvalue);
    setFormErr(formError);
  };

  const contactUs = async () => {
    try {
      const reqdata = {
        name: supportForm.suport_name,
        message: supportForm.suport_msg,
        email: supportForm.suport_email,
        phone: supportForm.mobile,
        page_id: 2,
      };
      await dispatch(contact_us(reqdata));
      setsupportForm(suportInfo);
    } catch (error) {}
  };

  const submitSupportinfo = (e) => {
    e.preventDefault();
    console.log('hello');
    if (formValidation()) {
      contactUs();
    }
  };

  return (
    <Fragment>
      <div className="float-left w-100 contactPage my-3 wow fadeInUp">
        <h3>
          <span>Support </span>
        </h3>
        <div className="row">
          <div className="col-md-12 contactForm">
            <Form className="supportpage" onSubmit={submitSupportinfo}>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Name"
                  name="suport_name"
                  value={supportForm.suport_name}
                  onKeyDown={(event) => {
                    var inputValue = event.keyCode;
                    // allow letters and whitespaces only.
                    if (
                      !(inputValue >= 65 && inputValue <= 120) &&
                      inputValue !== 32 &&
                      inputValue !== 0
                    ) {
                      event.preventDefault();
                    }
                  }}
                  onChange={handleChange}
                  className="contactInput"
                />
                <FormText color="danger">{formErr.suport_name}</FormText>
              </FormGroup>

              <FormGroup>
                <Input
                  type="text"
                  placeholder="Email"
                  name="suport_email"
                  value={supportForm.suport_email}
                  onChange={handleChange}
                  className="contactInput"
                />
                <FormText color="danger">{formErr.suport_email}</FormText>
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Phone Number"
                  name="mobile"
                  value={supportForm.mobile}
                  onChange={handleChange}
                  className="contactInput"
                />
                <FormText color="danger">{formErr.mobile}</FormText>
              </FormGroup>
              <FormGroup>
                <Input
                  type="textarea"
                  name="suport_msg"
                  value={supportForm.suport_msg}
                  onChange={handleChange}
                  placeholder="Add a Message"
                  className="contactTextArea"
                />
                <FormText color="danger">{formErr.suport_msg}</FormText>
              </FormGroup>

              <Button type="submit" className="applyBtn float-right">
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Support;
