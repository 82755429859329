import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Sidebar } from '../../../Components';
import { useDispatch, useSelector } from 'react-redux';
import { AddAddressModal } from '../../../Components';
import Pagination from 'react-js-pagination';

import { getUseraddress,remove_address,default_address } from '../../../../actions/accountActions';
const UserAddress = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, userAddrss } = useSelector((state) => state.account);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [CountPerPage, setCountPerPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const fetchAddress = async (pageNo) => {
    try {
      setIsLoading(true);
      await dispatch(getUseraddress(user && user.id,pageNo));
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setIsLoading(true);
    if (user) {
      fetchAddress(activePage);
    }
  }, [user]);

  const [modal, setModal] = useState(false);
  const toggle = () =>{ 
    setModal(!modal)
    //setAddress(null)
  };
  const [address, setAddress] = useState(null);

  const editOpen =(item)=>{
    setAddress(item)
    toggle()
  }

  const fetchremoveAddress= async (item)=>{
    console.log("remove",item)
    try {
      const reqData = {
        "user_id":user && user.id,
        "id":item.id
      }
     await dispatch(remove_address(reqData))
     await dispatch(getUseraddress(user && user.id));
    } catch (error) {
      console.log(error)
    }
  }

  const removeAddress = (item)=>{
    fetchremoveAddress(item)
  }

  const defaultAddress= async(item)=>{
    try {
      const reqData = {
        "user_id":user && user.id,
        "id":item.id,
        "status":2
      }
     await dispatch(default_address(reqData))
     await dispatch(getUseraddress(user && user.id));
    } catch (error) {
      console.log(error)
    }
    
  }

  useEffect(() => {
    if (userAddrss) {
      setActivePage(userAddrss.current_page);
      setCountPerPage(userAddrss.per_page);
      setPageCount(userAddrss.total);
    }
  }, [userAddrss]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    fetchAddress(pageNumber);
  };

  const { data = [] } = userAddrss ? userAddrss : {};
  return (
    <Fragment>
      <div className="userDashboard float-left w-100 my-3">
        <div className="row">
          <Sidebar />
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-4 mb-3">
                <div className="adrsBorderBx float-left w-100 h-100">
                  <div className="addNewAdrs float-left w-100 text-center">
                    <a href title="Add New Address">
                      <span className="d-block pb-2" onClick={toggle}>
                        <img src="/images/icoPlusBlk.png" alt="" />
                      </span>
                      Add New Address
                    </a>
                  </div>
                </div>
              </div>
              {isLoading ? (
                <p>Loading ...</p>
              ) : userAddrss && data.length > 0 ? (
                data.map((item) => (
                  <div className="col-md-4 mb-3" key={item.id}>
                    <div className="adrsBorderBx float-left w-100 h-100">
                      <div className="userAdrssInfo float-left w-100 h-100">
                        <div className="adrsWrap float-left w-100">
                        <div className="float-left w-100 py-2">
                          <div className="adrsLeft">
                            <span>Name</span>
                          </div>
                          <div className="adrsRight">
                            <span>{item.name}</span>
                          </div>
                        </div>
                        <div className="float-left w-100 py-2">
                          <div className="adrsLeft">
                            <span>Address</span>
                          </div>
                          <div className="adrsRight">
                            <span>{item.ship_address1}</span>
                            <span>{item.ship_address2}</span>
                            <span>{item.ship_city} </span>
                            {/* <span>item.ship_city</span> */}
                            <span>{item.ship_state}</span>
                            <span>{item.ship_pincode}</span>
                          </div>
                        </div>
                        <div className="float-left w-100 py-2">
                          <div className="adrsLeft">
                            <span>Contact</span>
                          </div>
                          <div className="adrsRight">
                            <span>{item.mobile}</span>
                          </div>
                        </div>
                        </div>
                        <div className="actLists float-left w-100 text-center">
                          <span>
                            <Link onClick={()=>editOpen(item)} title="Edit">
                              Edit
                            </Link>
                          </span>
                          <span>
                            <Link onClick={()=>removeAddress(item)} title="Remove">
                              Remove
                            </Link>
                          </span>
                          <span>
                            <Link onClick={()=>defaultAddress(item)} title="Default Address">
                              Default Address
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No record</p>
              )}
            </div>
            <div className="w-100 float-left paginationSect my-4">
              <nav aria-label="Page navigation example">
              {userAddrss && data.length > 0 && (
                <Pagination
                  innerclassNameName="pagination justify-content-center"
                  itemclassNameName="page-item"
                  linkclassNameName="page-link"
                  prevPageText="Previous"
                  nextPageText="Next"
                  hideFirstLastPages
                  activePage={activePage}
                  itemsCountPerPage={CountPerPage}
                  totalItemsCount={pageCount}
                  //pageRangeDisplayed={5}
                  onChange={handlePageChange}
                />
              )}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <AddAddressModal modal={modal} toggle={toggle} setModal={setModal} address={address} />
    </Fragment>
  );
};

export default UserAddress;
