/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { ImgBaseUrl } from '../../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { get_smartExclusive } from '../../../../actions/homepageActions';
import { SlickSlider, ProjectCard } from '../../../Components';

function SmartExclusive(props) {
  const dispatch = useDispatch();

  const { smartExclusive } = useSelector((state) => state.home);
  const [pageNo, setPageNo] = useState(1);
  const fetch_smartExclusive = async (page) => {
    try {
      await dispatch(get_smartExclusive(page));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const { last_page=1 } = smartExclusive ? smartExclusive:{}
    console.log(pageNo);
    if(pageNo <= last_page  ){
    fetch_smartExclusive(pageNo);
    }
  }, [pageNo]);

  const { data = [] } = smartExclusive ? smartExclusive:{};

  return (
    <>
      <div className="productBx w-100 float-left ">
        <div className="productTop w-100 float-left pb-2">
          <Link to="/products/smart-exclusive" title="View More" className="greenBtn float-right">
            View More
          </Link>
          <h3>Smart & Exclusive </h3>
        </div>
        <div className="productBtm w-100 float-left">
          <SlickSlider changePage={(next)=>{ next +1 > pageNo && setPageNo(next + 1)}}>
            {data.map((item, index) => (
              <div className="proSect" key={item.id}>
              <ProjectCard item={item}  />
              </div>
            ))}
          </SlickSlider>
        </div>
      </div>
    </>
  );
}

export default SmartExclusive;
