/* eslint-disable no-param-reassign */
import produce from 'immer';
import { object } from 'yup';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  OPEN_LOGIN,
  OPEN_REGISTER,
  WISHLIST,
  USERADDRESS,
  GETMYODERS,
  GETFRECHISE,
  GETAPPROVEDFRANCHISE,
  USERADDRESSPAGI,
  CHOOSEADDRESS,
  ORDERDETAILS,
  SEARCH,
  SEARCHPAGE
} from '../actions/accountActions';

const initialState = {
  user: null,
  loginOpen: false,
  registerOpen: false,
  wishlists:[],
  userAddrss:null,
  userAddrsss:[],
  myoders:[],
  frenchises:null,
  frachiseApporved:null,
  selectedAddress:[{address:null,add_type:'ship'},{address:null,add_type:'bill'}],
  orderdetails:null,
  options:[],
  optionspage:null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      const  user  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = user;
      });
    }

    case LOGIN_SUCCESS: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
      });
    }

    case OPEN_LOGIN: {
     return produce(state, (draft) => {
        draft.loginOpen = action.payload;
      });
    }

    case WISHLIST: {
      return produce(state, (draft) => {
         draft.wishlists = action.payload;
       });
     }

     case USERADDRESS: {
      return produce(state, (draft) => {
        console.log(action.payload)
         draft.userAddrss = action.payload;
       });
     }

     case USERADDRESSPAGI: {
      return produce(state, (draft) => {
        console.log(action.payload)
         draft.userAddrsss = action.payload;
       });
     }

     case GETMYODERS: {
      return produce(state, (draft) => {
        console.log(action.payload)
         draft.myoders = action.payload;
       });
     }
     case GETFRECHISE: {
      return produce(state, (draft) => {
        console.log(action.payload)
         draft.frenchises = action.payload;
       });
     }

     case GETAPPROVEDFRANCHISE: {
      return produce(state, (draft) => {
      
         draft.frachiseApporved = action.payload;
       });
     }
     case ORDERDETAILS: {
      return produce(state, (draft) => {
        
         draft.orderdetails = action.payload;
       });
     }

    case OPEN_REGISTER: {
      return produce(state, (draft) => {
         draft.registerOpen = action.payload;
       });
     }

     case CHOOSEADDRESS: {
      return produce(state, (draft) => {
         draft.selectedAddress = action.payload;
       });
     }

     case SEARCH: {
      return produce(state, (draft) => {
         draft.options = action.payload;
       });
     }

     case SEARCHPAGE: {
      return produce(state, (draft) => {
         draft.optionspage = action.payload;
       }); 
     }
     
    default: {
      return state;
    }
  }
};

export default accountReducer;
