/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_products } from '../../../../actions/productAction';
import { get_brands} from '../../../../actions/homepageActions';
import { product_searchpage } from '../../../../actions/accountActions';
import Pagination from 'react-js-pagination';
import { ProjectCard,Helmet } from '../../../Components';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useParams } from 'react-router-dom';

const ListingPage = () => {
  const dispatch = useDispatch();
 const { seachProduct } = useParams()
  const { optionspage } = useSelector((state) => state.account);
  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [CountPerPage, setCountPerPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [brandSelected, setBrandSelected] = useState([]);


  const fetch_products = async (page) => {
    try {
      setIsLoading(true);    
      await dispatch(product_searchpage({ search: seachProduct },page));
      await dispatch(get_brands());
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(setActivePage);
    fetch_products(pageNumber);
  };

  useEffect(() => {
    fetch_products(1)
  }, [seachProduct]);

  useEffect(() => {
    if (optionspage) {
      setActivePage(optionspage.current_page);
      setCountPerPage(optionspage.per_page);
      setPageCount(Number(optionspage.total) );
    }
  }, [optionspage]);

  const { data = [] } = optionspage ? optionspage : {};

  return (
    <>
     <Helmet title={'search '} description={'search'} metakeyword={'search'}/>
      <section className="listingPage w-100 float-left pt-2 pb-3">        
        <div className="row">
          <div className="productList w-100 float-left">
            <div className="productBtm w-100 float-left pb-3">
              {isLoading ? <p>Loading ...</p> :optionspage && data.length > 0 ? 
                data.map((item, key) => {
                  return (
                  
                  <div className="proSect mb-2" key={item.id}>
                  <ProjectCard item={item} key={item.id} />
                    </div>
                  );
                }):<p>No record</p>}
            </div>
          </div>
          <div className="w-100 float-left paginationSect tex-center">
            <nav aria-label="Page navigation example">
              {optionspage && data.length > 0 && 
              <Pagination
              innerclassName="pagination justify-content-center"
              itemclassName="page-item"
              linkclassName="page-link"
              prevPageText="Previous"
              nextPageText="Next"
              hideFirstLastPages
              activePage={activePage}
              itemsCountPerPage={CountPerPage}
              totalItemsCount={pageCount}
              //pageRangeDisplayed={5}
              onChange={handlePageChange}
            />
            }
              
            </nav>
          </div>
        </div>
      </section>
    </>
  );
};

export default ListingPage;
