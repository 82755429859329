import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Sidebar } from '../../../Components';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFrenchi,
  changeFrenchise,
  getApprovedFranchi,
} from '../../../../actions/accountActions';
import { FormText } from 'reactstrap';

const FranchiesPartner = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { frenchises, user, frachiseApporved } = useSelector(
    (state) => state.account
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState('');
  const [formErr, setFormErr] = useState({ selected: '', message: '' });

  const fetchFrenchi = async (userId) => {
    try {
      setIsLoading(true);
      await dispatch(getFrenchi());
      await dispatch(getApprovedFranchi(userId));
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchFrenchi(user && user.id);
    }
  }, [user]);

  useEffect(() => {
    if (frachiseApporved) {
      setSelected(frachiseApporved && frachiseApporved.frenchise_id);
    }
  }, [frachiseApporved]);

  const { data = [] } = frenchises ? frenchises : {};
  const [message, setMessage] = useState();

  const handleChange = (e) => {
    setFormErr({ selected: '', message: '' });
    setMessage(e.target.value);
  };

  const formValidation = () => {
    const formerror = { ...formErr };
    let error = true;
    if (!selected) {
      error = false;
      formerror.selected = 'Please Choose frechise partner!';
    }
    if (!message) {
      error = false;
      formerror.message = 'Message field is required!';
    }
    setFormErr(formerror);
    return error;
  };

  const formSubmit = async () => {
    try {
      const reqData = {
        user_id: user && user.id,
        frenchise_id: selected,
        message: message,
      };
      await dispatch(changeFrenchise(reqData));
      await dispatch(getApprovedFranchi(user && user.id));
    } catch (error) {}
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formValidation()) {
      formSubmit();
    }
  };

  return (
    <Fragment>
      <div class="userDashboard float-left w-100 my-3">
        <div class="row">
          <Sidebar />
          {isLoading ? (
            <p>Loading ...</p>
          ) : (
            <div class="col-md-9">
              <div class="franchiesPage float-left w-100 ">
                <h4>Your Franchise Partner</h4>
                <div class="franchPartner float-left w-100 my-2">
                  <div class="franchTop float-left w-100 mb-4">
                    {frachiseApporved &&
                      frachiseApporved.frenchise_details.first_name && (
                        <p>
                          <span>Name</span>:
                          <strong>
                            {frachiseApporved &&
                              `${frachiseApporved.frenchise_details.first_name} ${frachiseApporved.frenchise_details.last_name}`}
                          </strong>
                        </p>
                      )}
                       {frachiseApporved &&
                      frachiseApporved.frenchise_details.mobile && (
                    <p>
                      <span>Phone</span>:
                      <strong>
                        {frachiseApporved &&
                          frachiseApporved.frenchise_details.mobile}
                      </strong>
                    </p>)}
                    {frachiseApporved &&
                      frachiseApporved.frenchise_details.email && (
                    <p>
                      <span>Email</span>:
                      <strong>
                        {frachiseApporved &&
                          frachiseApporved.frenchise_details.email}
                      </strong>
                    </p>)}
                  </div>
                  <div class="franchPartnerTillDate float-left w-100 mb-3">
                    <h5>Franchise Partner's Till Date</h5>
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                          </tr>
                        </thead>
                        <tbody>
                          {frenchises && data.length > 0 ? (
                            data.map((item, i) => {
                              if (user && user.id !== item.id) {
                                return (
                                  <tr key={item.id}>
                                    <td>
                                      {` ${i + 1}  `}
                                      &nbsp; &nbsp;
                                      <input
                                        type="radio"
                                        value={item.id}
                                        onChange={(e) => {
                                          setSelected(Number(e.target.value));
                                          // setFormErr(
                                          //   (preState) => (preState.selected = '')
                                          // );
                                        }}
                                        checked={
                                          Number(selected) === Number(item.id)
                                        }
                                      />
                                    </td>
                                    <td>{`${item.first_name} ${item.last_name}`}</td>
                                    <td>{item.mobile}</td>
                                    <td>{item.email}</td>
                                  </tr>
                                );
                              }
                            })
                          ) : (
                            <tr>
                              <td className="noborder">&nbsp;</td>
                              <td className="noborder">No record!</td>
                              <td className="noborder">&nbsp;</td>
                              <td className="noborder">&nbsp;</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="franchPartnerTillDate float-left w-100 mb-4">
                    <h5>Need to change your Franchise Partner?</h5>
                    <form onSubmit={handleSubmit}>
                      <textarea
                        onChange={handleChange}
                        value={message}
                        class="form-control mb-3 franchTextArea"
                        placeholder="Write down the reason due to which you want to change your Franchise Partner"
                      ></textarea>
                      <FormText color="danger">{formErr.selected}</FormText>
                      <FormText color="danger">{formErr.message}</FormText>
                      <button type="submit" class="applyBtn">
                        Submit request to change Franchise Partner{' '}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default FranchiesPartner;
