import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CartCard } from '../../../Components';

const CartPage = () => {
  const history = useHistory();

  const handleSummery = () => {
    history.push('/chooseaddress');
  };
  const { cart } = useSelector((state) => state.product);
  return (
    <Fragment>
      <div className="cartPage float-left w-100 py-3 wow fadeInUp">
        <div className="pageTitle float-left w-100 mb-3">
          <h3>Items in your cart</h3>
        </div>
        <CartCard />
        <div className="cardBox float-left w-100 mb-2 py-0 borderNone">
          {cart && cart.length > 0 && (
            <div className="row">
              <div className="col-md-12">
                <div className="float-right">
                  <button
                    className="float-left applyBtn"
                    onClick={handleSummery}
                  >
                    Proceed to Checkout
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CartPage;
