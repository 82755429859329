/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import Breadcrumb from './Includes/breadcrumb';
import CartPage from './Includes/Cart';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultAddress } from '../../../actions/accountActions';
const HomePage = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.account);
  useEffect(() => {
    if (user) {
      dispatch(getDefaultAddress(user && user.id));
    }
  }, [user]);

  return (
    <div>
      <section class="homePageSect w-100 float-left pt-2 pb-3">
        <div class="container">
          <Breadcrumb />
          <CartPage />
        </div>
      </section>
    </div>
  );
};

HomePage.propTypes = {};

export default HomePage;
