/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Breadcrumb from './Includes/breadcrumb';
import OrderSummary from './Includes/OrderSummary';
import Buynow from './Includes/buynow';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const HomePage = (props) => {
  const location = useLocation();
  const { state } = location;
  const { user } = useSelector((state) => state.account);
  return (
    <div>
      {/* {!user && <Redirect to="/login"/>} */}
      <section class="homePageSect w-100 float-left pt-2 pb-3">
        <div class="container">
          <Breadcrumb />
          {state && state.buynow ? <Buynow /> : <OrderSummary />}
        </div>
      </section>
    </div>
  );
};

HomePage.propTypes = {};

export default HomePage;
