import React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  PinterestShareButton,
  PinterestIcon,
  //TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

const SocialShare = ({url,media,prodttitle=''}) => {
  const shareUrl = url;
  const title = prodttitle ? prodttitle: 'Kitchen and Home Appliances Store in India - 100 Plus Mall';
  return (
    <div>
      <FacebookShareButton url={shareUrl} quote={title} >
        <FacebookIcon size={32}  />
      </FacebookShareButton>
      {/* <PinterestShareButton
            url={shareUrl}
            media={media}
            className="Demo__some-network__share-button"
          >
            <PinterestIcon size={32} />
          </PinterestShareButton> */}
      {/* <TwitterShareButton
        url={shareUrl}
        title={title}
        className="Demo__some-network__share-button"
      >
       <TwitterIcon size={32}  /> 
      </TwitterShareButton> */}

      <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
        <WhatsappIcon size={32} />
      </WhatsappShareButton>
    </div>
  );
};

export default SocialShare;
