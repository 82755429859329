/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_HOME_PAGE,
  GETCATEGORIES,
  GETBRANDS,
  GETHOTDEALS,
  GETSMARTEXCLUSIVE,
  GETSTOREDEALS,
  ADDTOCART,
  GETPRODUCTS,
  GETPRODUCT,
  GETPRODUCTSBYCATEGORY,
  GETPRODUCTSBYBRANDS,
  ADDQUANTITY,
  SUBQUANTITY,
  APPLYCOUPONCODE,
  SETFINALORDER,
  CREATEORDER
} from '../actions/productAction';

const initialState = {
  homedata: null,
  products:null,
  brands:[],
  hotdeals:null,
  smartExclusive:null,
  storedeals:null,
  homebanners:[],
  allbrands:[],
  alloffers:[],
  product:null,
  bannerbottoms:[],
  recomendProduct:[],
  productsbycategory:[],
  couponinfo:null,
  finalOrder:null,
  orderInfo:null,
  cart : localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) :[],
  
};

const homepageReducer = (state = initialState, action) => {
  switch (action.type) {

    case GETPRODUCTS: {
      const  { products }  = action.payload;
     
      return produce(state, (draft) => {
        if(state.products){
          //const prevdata  = state.products.data
         // const nextData  = products.data
         // products.data = prevdata.concat(nextData);
        }
        // Ensure we clear current session
        draft.products = products;
      });
    }

    case GETBRANDS: {
      const  { brand }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.brands = brand;
      });
    }

    case GETHOTDEALS: {
      const  { hotdeal }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        if(state.hotdeals){
          const prevdata  = state.hotdeals.data
          const nextData  = hotdeal.data
          hotdeal.data = prevdata.concat(nextData);
         console.log(hotdeal,"merge")
        }
        draft.hotdeals = hotdeal;
      });
    }

    case GETSMARTEXCLUSIVE: {
      const  { smartexclusive }  = action.payload;
      return produce(state, (draft) => {
        if(state.smartExclusive){
          const prevdata  = state.smartexclusive.data
          const nextData  = smartexclusive.data
          smartexclusive.data = prevdata.concat(nextData);
        }
        // Ensure we clear current session
        draft.smartExclusive = smartexclusive;
      });
    }

    case GETSTOREDEALS: {
      const  { storeDeals }  = action.payload;
      return produce(state, (draft) => {
        if(state.storedeals){
          const prevdata  = state.storedeals.data
          const nextData  = storeDeals.data
          storeDeals.data = prevdata.concat(nextData);
        }
        // Ensure we clear current session
        draft.storedeals = storeDeals;
      });
    }

    case ADDTOCART: {
      const  { cart }  = action.payload;
      return produce(state, (draft) => {
        draft.cart = cart;
        //draft.bannerbottoms = bannerbottom;
        
      });
    }

    case ADDQUANTITY: {
      const  { product }  = action.payload;
      
      const addQuantity = {...state.cart.find(item => item.id === product.id)}
      addQuantity.quintity += 1
     const newcart = [...state.cart]
     const index = newcart.findIndex((item)=>item.id === product.id)
     newcart[index] = {...addQuantity}
    localStorage.setItem('cart',JSON.stringify(newcart))
     
      return {...state,cart:newcart}
    }

    case SUBQUANTITY: {
      const  { product }  = action.payload;
      const addQuantity = {...state.cart.find(item => item.id === product.id)}

      
      if(addQuantity.quintity === 1){
        let new_items = state.cart.filter(item=> product.id !== item.id)
        localStorage.setItem('cart',JSON.stringify(new_items))
        return {...state,cart: new_items}
      }
      else{
        addQuantity.quintity -= 1
        const newcart = [...state.cart]
        const index = newcart.findIndex((item)=>item.id === product.id)
        newcart[index] = {...addQuantity}
       localStorage.setItem('cart',JSON.stringify(newcart))
         return {...state,cart:newcart}
      }
 
    }

    case GETPRODUCT: {
      const  { product,recomend }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.product = product;
        draft.recomendProduct = recomend;
        
      });
    }

    case APPLYCOUPONCODE: {
      const  { couponinfo }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.couponinfo = couponinfo;
        
      });
    }

    case SETFINALORDER: {
      const  { finalOrder }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.finalOrder = finalOrder;
        
      });
    }

    case GETPRODUCTSBYCATEGORY: {
      const  { productsbycategory }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.productsbycategory = productsbycategory;
        
      });
    }
    case GETPRODUCTSBYBRANDS: {
      const  { productsbybrands }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.productsbybrands = productsbybrands;
        
      });
    }

    case CREATEORDER: {
      const  { orderInfo }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.orderInfo = orderInfo;
        
      });
    }


    

    default: {
      return state;
    }
  }
};

export default homepageReducer;
