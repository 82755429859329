/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';

import { ImgBaseUrl } from '../../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { get_homeSlider } from '../../../../actions/homepageActions';
import { SlickSlider } from '../../../Components';

function Slider(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { homebanners, bannerbottoms } = useSelector((state) => state.home);

  const fetch_homeSlider = async (page) => {
    try {
      await dispatch(get_homeSlider(page));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetch_homeSlider(1);
  }, []);

  const handleClick = () => {
    history.push('/prductdetails');
  };
  return (
    <>
      <div className="sliderSect w-100 float-left mb-5 ">
        <SlickSlider slideshow={1}>
          {homebanners.map((item, index) => (
            <div className="largSlider w-100 float-left mb-2">
              <Link to={item.link}>
                {' '}
                <img
                  onError={(e) => {
                    e.target.src = 'images/img_banner.jpg';
                  }}
                  src={item.file}
                  alt="Banner Image"
                />
              </Link>
            </div>
          ))}
        </SlickSlider>

        <div className="btmSlider w-100 float-left">
          <div className="row">
            {bannerbottoms.map((item) => (
              <div className="col">
                <div className="smallSlider" >
                <Link to={item.link}>
                  <img 
                   onError={(e) => {
                    e.target.src = 'images/img_banner1.jpg';
                  }}
                  src={item.file} 
                  />
                 </Link>
                </div>
              </div>
            ))}

            {/* <div className="col">
              <div className="smallSlider" onClick={handleClick}>
                <img src="images/img_banner2.jpg" alt="Banner Image" />
              </div>
            </div>
            <div className="col">
              <div className="smallSlider" onClick={handleClick}>
                <img src="images/img_banner3.jpg" alt="Banner Image" />
              </div>
            </div>
            <div className="col">
              <div className="smallSlider" onClick={handleClick}>
                <img src="images/img_banner4.jpg" alt="Banner Image" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Slider;
