import React, { useEffect,useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { ImgBaseUrl } from '../../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { get_recommended } from '../../../../actions/homepageActions';
import { SlickSlider, ProjectCard } from '../../../Components';

function Recommend(props) {
  const dispatch = useDispatch();

  const { recommended } = useSelector((state) => state.home);
  const [pageNo, setPageNo] = useState(1);
  const fetch_hotdeals = async (page) => {
    try {
      await dispatch(get_recommended(page));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const { last_page } = recommended ? recommended:{}
    if(pageNo <= last_page  ){
      fetch_hotdeals(pageNo);
    }
  }, [pageNo]);

  useEffect(() => {
    fetch_hotdeals(1);
  }, []);

  const { data = [] } = { ...recommended };

  return (
    <>
      <div class="productBx w-100 float-left wow fadeInUp">
        <div class="productTop w-100 float-left pb-2">
          <Link
            to="/products/recommend"
            href
            title="View More"
            class="greenBtn float-right"
          >
            View More
          </Link>
          <h3>Recommended Products</h3>
        </div>
        <div class="productBtm w-100 float-left">
          <SlickSlider changePage={(next)=>{ next +1 > pageNo && setPageNo(next + 1)}}>
            {data.map((item, index) => (
              <div className="proSect" key={item.id}>
                <ProjectCard item={item} />
              </div>
            ))}
          </SlickSlider>
        </div>
      </div>
    </>
  );
}

export default Recommend;
