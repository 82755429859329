/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Breadcrumb from './Includes/breadcrumb'
import ProductList from './Includes/ProductList'

const ProductsByBrands = (props) => {
  return (
    <div>
      <section class="homePageSect w-100 float-left pt-2 pb-3">
        <div class="container">
            <Breadcrumb />
            <ProductList />    
          </div>
      </section>
      
    </div>
  );
};

ProductsByBrands.propTypes = {};

export default ProductsByBrands;
