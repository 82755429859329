import React, { Fragment, useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText,Spinner } from 'reactstrap';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  send_opt,
  forgot_password,
  verifyotp,
} from '../../../../actions/accountActions';
import { useAuth } from '../../../../hooks';

const passworChangeForm = {
  mobile: '',
  password: '',
  cppassword: '',
  otp: '',
  //user_id:''
};
const ForgotPassword = () => {
  const { isLoggedIn } = useAuth();
  const history = useHistory();
  const [changepassForm, setChangepassForm] = useState(passworChangeForm);
  const [changepassFormErr, setChangepassFormErr] = useState(passworChangeForm);
  const dispatch = useDispatch();
  const [otpSent, setOtpSent] = useState(false);
  const changePasswordValidation = () => {
    let error = true;
    const formerror = { ...changepassFormErr };
    if (!changepassForm.otp) {
      error = false;
      formerror.otp = 'Old password is required!';
    }
    if (!changepassForm.password) {
      error = false;
      formerror.password = 'Password is required!';
    }
    if (!changepassForm.cppassword) {
      error = false;
      formerror.cppassword = 'Confirm password is required!';
    }

    if (!changepassForm.mobile) {
      error = false;
      formValidation();
    }

    if (
      changepassForm.cppassword &&
      changepassForm.password &&
      changepassForm.cppassword !== changepassForm.password
    ) {
      error = false;
      formerror.cppassword = 'Confirm password and password should be same!';
    }
    setChangepassFormErr(formerror);
    return error;
  };
  const [mobile_noErr, setMobile_noErr] = useState('');

  const formValidation = () => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let error = true;
    if (!changepassForm.mobile) {
      setMobile_noErr('Phone No is required.');
      error = false;
    }

    if (changepassForm.mobile && !phoneno.test(changepassForm.mobile)) {
      setMobile_noErr('Enter valid phone no.');
      error = false;
    }
    return error;
  };

  const handleChangePassword = (e) => {
    console.log(e.target);
    const name = e.target.name;
    const value = e.target.value;
    const formvalue = { ...changepassForm };
    const formError = { ...changepassFormErr };
    formError[name] = '';
    formvalue[name] = value;
    setChangepassForm(formvalue);
    setChangepassFormErr(formError);
    setMobile_noErr('');
  };
  const [isLoading, setIsLoading] = useState(false);

  const change_password = async () => {
    try {
      const reqData = {
        mobile: changepassForm.mobile,
        password: changepassForm.password,
      };
      const verifyOtp = {
        mobile: changepassForm.mobile,
        otp: changepassForm.otp,
      };
      setIsLoading(true);
      await dispatch(verifyotp(verifyOtp));
      await dispatch(forgot_password(reqData));
      history.push('/login');
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const submitChangePassword = (e) => {
    e.preventDefault();
    if (changePasswordValidation()) {
      change_password();
    }
  };

  const sendOtp = async () => {
    try {
      if (formValidation()) {
        console.log('hello');
        dispatch(send_opt({ mobile: changepassForm.mobile }));
      } else {
        console.log('eroro');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return isLoggedIn ? (
    <Redirect to="/" />
  ) : (
    <Fragment>
      <div className="logInPage forgotPassword float-left wow fadeInUp">
        <div className="float-left w-100 logInDtls">
          <div className="logInRight float-right text-center ">
            <h3>
              Forgot<span className="d-block"> Password</span>{' '}
            </h3>
            <div className="float-left w-100 greenBx mt-2">
              <h4>
                Do not worry,<span className="d-block">we got you covered</span>{' '}
              </h4>
            </div>
          </div>
          <div className="logInLeft float-left">
            <div className="signUpPage float-left w-100">
              <h5>Enter your Mobile Number</h5>
              <Form onSubmit={submitChangePassword}>
                <FormGroup>
                  <Link
                    className="changeAct"
                    onClick={() => {
                      setOtpSent(false);
                      setChangepassForm((prev) => {
                        return { ...prev, mobile: '' };
                      });
                    }}
                  >
                    Change
                  </Link>
                  <Input
                    type="text"
                    name="mobile"
                    placeholder="Mobile Number"
                    className="signUpInput"
                    value={changepassForm.mobile}
                    onChange={handleChangePassword}
                    onBlur={() => {
                      if (!otpSent) {
                        console.log('hellow');
                        setOtpSent(true);
                        sendOtp();
                      }
                    }}
                   // autoFocus
                    onKeyDown={(e) => {
                      e.keyCode > 31 &&
                        (e.keyCode < 48 || e.keyCode > 57) &&
                        e.preventDefault();
                    }}
                    disabled={otpSent}
                  />
                  <FormText color="danger">{mobile_noErr}</FormText>
                </FormGroup>

                <FormGroup>
                  <Link className="changeAct" onClick={sendOtp}>
                    Resend
                  </Link>
                  <Input
                    type="text"
                    name="otp"
                    placeholder="Enter OTP"
                    className="signUpInput"
                    value={changepassForm.otp}
                    onChange={handleChangePassword}
                  />
                  <FormText color="danger">{changepassFormErr.otp}</FormText>
                </FormGroup>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Enter New Password"
                    className="signUpInput"
                    name="password"
                    value={changepassForm.password}
                    onChange={handleChangePassword}
                  />
                  <FormText color="danger">
                    {changepassFormErr.password}
                  </FormText>
                </FormGroup>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Confirm New Password"
                    className="signUpInput"
                    name="cppassword"
                    value={changepassForm.cppassword}
                    onChange={handleChangePassword}
                  />
                  <FormText color="danger">
                    {changepassFormErr.cppassword}
                  </FormText>
                </FormGroup>

                <Button type="submit" className="applyBtn btn-block">
                  {' '}
                  {isLoading && (
                    <Spinner
                      style={{ width: '1.5rem', height: '1.5rem' }}
                      color="primary"
                    />
                  )}
                  Change password
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPassword;
