import React, { Fragment, useState, useEffect } from 'react';
import { Button, Form, FormGroup, Input, FormText } from 'reactstrap';
import { useHistory} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get_FooterMenu,contact_us } from '../../../../actions/homepageActions';
import parse from 'html-react-parser';

const contactInfo = {
  id: '',
  contact_email: '',
  contact_name: '',
  contact_msg: ''
};


const ContactUs = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [contactForm, setcontactForm] = useState(contactInfo);
  const [formErr, setFormErr] = useState(contactInfo);
  const { footermenu } = useSelector((state) => state.home);
  const [isLoading, setIsLoading] = useState(false);



  const formValidation = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let error = true;
    const formerror = { ...formErr };
    if (!contactForm.contact_name) {
      error = false;
      formerror.contact_name = 'Name is required!';
    }
    if (!contactForm.contact_email) {
      error = false;
      formerror.contact_email = 'Email is required!';
    }
    if(contactForm.contact_email && !re.test(String(contactForm.contact_email).toLowerCase())){
      error = false;
      formerror.contact_email = 'Enter valid email!';
    }
   
    if (!contactForm.contact_msg) {
      error = false;
      formerror.contact_msg = 'Message is required!';
    }
    setFormErr(formerror);
    return error;
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const formvalue = { ...contactForm };
    const formError = { ...formErr };
    formError[name] = '';
    formvalue[name] = value;
    setcontactForm(formvalue);
    setFormErr(formError);
  };

  const contactUs = async()=>{
    try {
      const reqdata = {
        name: contactForm.contact_name,
        message:contactForm.contact_msg,
        email: contactForm.contact_email,
        phone: '12345678911',
        page_id: 1,
      };
     await dispatch(contact_us(reqdata));
     setcontactForm(contactInfo)
    } catch (error) {
      
    }
  }

  const submitContactinfo = (e) => {
    e.preventDefault();
    console.log('hello');
    if (formValidation()) {
      contactUs()
    }
  };

  const fetch_products = async () => {
    try {
      setIsLoading(true);
      await dispatch(get_FooterMenu());
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    fetch_products();
  }, []);

  return (
    <Fragment>
      <div className="float-left w-100 contactPage my-3 wow fadeInUp">
        <h3><span>Contact Us</span></h3>
        <div className="row">
          <div className="col-md-8 contactForm">
            <Form onSubmit={submitContactinfo}>
              <FormGroup>
                <Input type="text" 
                name="contact_name"  
                placeholder="Name" 
                value={contactForm.contact_name}
                onChange={handleChange}
                className="contactInput" />
                <FormText color="danger">{formErr.contact_name}</FormText>
              </FormGroup>
              
              <FormGroup>
                <Input type="text" 
                name="contact_email"  
                placeholder="Email"
                value={contactForm.contact_email}
                onChange={handleChange} 
                className="contactInput"  />
                <FormText color="danger">{formErr.contact_email}</FormText>
              </FormGroup>
              <FormGroup>
                <Input type="textarea" 
                name="contact_msg"  
                placeholder="Add a Message" 
                value={contactForm.contact_msg}
                onChange={handleChange}
                className="contactTextArea"  />
                <FormText color="danger">{formErr.contact_msg}</FormText>
              </FormGroup>
              
              <Button type="submit" className="applyBtn float-right">Submit</Button>
            </Form>
          </div>
          <div className="col-md-4 contactAdrs">

          {footermenu.map((item, index) => (
              <div className="w-100">
              { //Check if message failed
                (item.alias === 'contact-us') 

                  ? <div> 
                    {parse(item.content.article_description)}
                  </div> 
                  : <div> </div> 
              }
            </div>
            ))}
  
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ContactUs;
