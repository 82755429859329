import React, { Fragment } from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { Link } from 'react-router-dom';
//import WOW from "wowjs";

const FranchiesLogin = () => {
  //const wow = new WOW.WOW();
  //  wow.init();
  return (
    <Fragment>
      <div className="logInPage LogInPageSect float-left wow fadeInUp">
        <div className="float-left w-100 logInDtls">
          <div className="logInRight float-left text-center">
            <h3>Welcome Back</h3>
            <div className="float-left w-100 greenBx mt-2">
              <h4>
                <Link to="">
                  Log-In <span className="d-block">as Customer</span>{' '}
                </Link>
              </h4>
            </div>
            <p className="text-center py-2 signUpRed">
              Do not have an account? <Link to="">Sign-up Here</Link>
            </p>
          </div>
          <div className="logInLeft float-left">
            <div className="signUpPage float-left w-100">
              <h5>Log-In as Franchies Partner</h5>
              <Form>
                <FormGroup>
                  <Link className="changeAct">Change</Link>
                  <Input
                    type="text"
                    name="Mobile"
                    placeholder="Mobile Number"
                    className="signUpInput"
                  />
                </FormGroup>

                <FormGroup>
                  <Link className="changeAct">Resend</Link>
                  <Input
                    type="text"
                    name="otp"
                    placeholder="Enteer OTO"
                    className="signUpInput"
                  />
                </FormGroup>
                <p class="text-right mb-3">
                  <a href="/">Forgot Password?</a>
                </p>
                <Button className="applyBtn btn-block">Send OTP</Button>
                <strong className="d-block my-3 text-center">OR</strong>
                <Button className="applyBtn backBtn btn-block">Log-in </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FranchiesLogin;
