/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_HOME_PAGE,
  GETCATEGORIES,
  GETBRANDS,
  GETHOTDEALS,
  GETSMARTEXCLUSIVE,
  GETSTOREDEALS,
  GETHOMEBANNERS,
  GETFOOTER,
  GETHEADERMENU,
  GETFOOTERMENU,
  GETFAQDATA,
  RECOMMENDED
} from '../actions/homepageActions';

const initialState = {
  homedata: null,
  categories:null,
  brands:[],
  hotdeals:null,
  smartExclusive:null,
  storedeals:null,
  homebanners:[],
  allbrands:[],
  alloffers:[],
  headermenus:[],
  bannerbottoms:[],
  footermenu:[],
  faq:[],
  recommended:null
  
};

const homepageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOME_PAGE: {
      const  { homedata }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.homepagedata = homedata;
      });
    }

    case GETCATEGORIES: {
      const  { category }  = action.payload;
      return produce(state, (draft) => {
        if(state.categories){
          const prevdata  = state.categories.data
          const nextData  = category.data
          category.data = prevdata.concat(nextData);
        }
        // Ensure we clear current session
        draft.categories = category;
      });
    }

    case GETBRANDS: {
      const  { brand }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.brands = brand;
      });
    }

    case GETHOTDEALS: {
      const  { hotdeal }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        if(state.hotdeals){
          const prevdata  = state.hotdeals.data
          const nextData  = hotdeal.data
          hotdeal.data = prevdata.concat(nextData);
        }
        draft.hotdeals = hotdeal;
      });
    }

    case RECOMMENDED: {
      const  { recommendeds }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        if(state.recommended){
          const prevdata  = state.recommended.data
          const nextData  = recommendeds.data
          recommendeds.data = prevdata.concat(nextData);
        }
        draft.recommended = recommendeds;
      });
    }



    case GETSMARTEXCLUSIVE: {
      const  { smartexclusive }  = action.payload;
      return produce(state, (draft) => {
        if(state.smartExclusive){
          const prevdata  = state.smartexclusive.data
          const nextData  = smartexclusive.data
          smartexclusive.data = prevdata.concat(nextData);
        }
        // Ensure we clear current session
        draft.smartExclusive = smartexclusive;
      });
    }

    case GETSTOREDEALS: {
      const  { storeDeals }  = action.payload;
      return produce(state, (draft) => {
        if(state.storedeals){
          const prevdata  = state.storedeals.data
          const nextData  = storeDeals.data
          storeDeals.data = prevdata.concat(nextData);
        }
        // Ensure we clear current session
        draft.storedeals = storeDeals;
      });
    }

    case GETHOMEBANNERS: {
      const  { homebanner,bannerbottom }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.homebanners = homebanner;
        draft.bannerbottoms = bannerbottom;
        
      });
    }

    case GETFOOTER: {
      const  { brands, offers }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.alloffers = offers;
        draft.allbrands = brands;
      });
    }

    case GETHEADERMENU: {
      const  { headermenu }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.headermenus = headermenu;
        
      });
    }

    case GETFOOTERMENU: {
      console.log("action",action.payload)
      const  { footermenu }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.footermenu = footermenu;
        
      });
    }

    case GETFAQDATA: {
      console.log("action",action.payload)
      const  { faq }  = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.faq = faq;
        
      });
    }

    default: {
      return state;
    }
  }
};

export default homepageReducer;
