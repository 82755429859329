import React from 'react'
import axios from '../utils/axios';
import { NotificationManager } from 'react-notifications';
export const GET_HOME_PAGE = '@product/home-page';
export const GETCATEGORIES = '@product/home-categories';
export const GETBRANDS = '@product/home-brands';
export const GETHOTDEALS = '@product/home-hotdeals';
export const GETSMARTEXCLUSIVE = '@product/home-smartexclusive';
export const GETSTOREDEALS = '@product/home-store-deals';
export const ADDTOCART = '@product/home-banners';
export const GETPRODUCTS = '@product/get-products';
export const GETPRODUCT = '@product/get-product';
export const GETPRODUCTSBYCATEGORY = '@product/get-product-by-category';
export const GETPRODUCTSBYBRANDS = '@product/get-product-by-brands';
export const ADDQUANTITY = '@product/add-quantity';
export const SUBQUANTITY = '@product/sub-quantity';
export const ADDTOWISHLIST = '@product/add-to-wishlist';
export const APPLYCOUPONCODE = '@product/apply-coupon-code'
export const SETFINALORDER = '@product/final-order'
export const CREATEORDER = '@product/create-order'
export const BUYNOWADDTOCART ='@product/create-buynow'
const authToken = localStorage.getItem('x-auth') ? localStorage.getItem('x-auth') : localStorage.getItem('userId');
const isLoggedIn = !!authToken 

export function get_product(productSlug) {
  return async (dispatch) => {
    try {
      const [product, recomedproduct] = await Promise.all([
        axios.get(`/product-detail/${productSlug}`),
        axios.get(`/product-recommend/${productSlug}`),
      ]);

      if (product.statusText === 'OK') {
        const products = { ...product.data.data };
        products.images = [...products.images, { filename: products.image }];

        dispatch({
          type: GETPRODUCT,
          payload: { product: products, recomend: recomedproduct.data.data },
        });
      }
    } catch (error) {
      console.log(error);
      // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function get_products(req, pageNo) {
  return async (dispatch) => {
    try {
      const result = await axios.post(
        `product-list-brand-wise?page=${pageNo}`,
        req
      );

      if (result.statusText === 'OK') {
        dispatch({
          type: GETPRODUCTS,
          payload: { products: result.data.data },
        });
      }

      // if (type === 'hot-deals') {
      //   const result = await axios.get('home/hot-deals?page=' + pageNo);
      //   if (result.statusText === 'OK') {
      //     dispatch({
      //       type: GETPRODUCTS,
      //       payload: { products: result.data.data },
      //     });
      //   }
      // }

      // if (type === 'smart-exclusive') {
      //   const result = await axios.get('home/smart-deals?page=' + pageNo);
      //   if (result.statusText === 'OK') {
      //     dispatch({
      //       type: GETPRODUCTS,
      //       payload: { products: result.data.data },
      //     });
      //   }
      // }

      // if (type === 'store-99') {
      //   const result = await axios.get('home/store-deals?page=' + pageNo);
      //   if (result.statusText === 'OK') {
      //     dispatch({
      //       type: GETPRODUCTS,
      //       payload: { products: result.data.data },
      //     });
      //   }
      // }

      // if (type === 'recommend') {
      //   const result = await axios.get('home/store-deals?page=' + pageNo);
      //   if (result.statusText === 'OK') {
      //     dispatch({
      //       type: GETPRODUCTS,
      //       payload: { products: result.data.data },
      //     });
      //   }
      // }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function get_productByCategory(data, pageNo) {
  return async (dispatch) => {
    try {
      const result = await axios.post(
        `product-list-category-wise?page=${pageNo}`,
        data
      );
      //const result = await axios.post(`product-list-category-wise/?page=${pageNo}`,data);

      if (result.statusText === 'OK') {
        dispatch({
          type: GETPRODUCTSBYCATEGORY,
          payload: { productsbycategory: result.data.data },
        });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function get_productByBrands(req, pageNo) {
  return async (dispatch) => {
    try {
      dispatch({
        type: GETPRODUCTSBYBRANDS,
        payload: { productsbybrands: [] },
      });

      const result = await axios.post(
        `product-list-brand-wise?page=${pageNo}`,
        req
      );

      if (result.statusText === 'OK') {
        dispatch({
          type: GETPRODUCTSBYBRANDS,
          payload: { productsbybrands: result.data.data },
        });
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
}

export function get_brands() {
  return async (dispatch) => {
    try {
      const result = await axios.get('home/brand');

      if (result.statusText === 'OK') {
        dispatch({
          type: GETBRANDS,
          payload: { brand: result.data.data.data },
        });
      }
    } catch (error) {
      console.log(error);
      // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function get_hotdeals(page) {
  return async (dispatch) => {
    try {
      const result = await axios.get('home/hot-deals?page=' + page);

      if (result.statusText === 'OK') {
        dispatch({
          type: GETHOTDEALS,
          payload: { hotdeal: result.data.data },
        });
      }
    } catch (error) {
      console.log(error);
      // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function get_smartExclusive(page) {
  return async (dispatch) => {
    try {
      const result = await axios.get('home/smart-deals?page=' + page);

      if (result.statusText === 'OK') {
        dispatch({
          type: GETSMARTEXCLUSIVE,
          payload: { smartexclusive: result.data.data },
        });
      }
    } catch (error) {
      console.log(error);
      // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function get_storeDeals(page) {
  return async (dispatch) => {
    try {
      const result = await axios.get('home/store-deals?page=' + page);

      if (result.statusText === 'OK') {
        dispatch({
          type: GETSTOREDEALS,
          payload: { storeDeals: result.data.data },
        });
      }
    } catch (error) {
      console.log(error);
      // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function addTocart_database(user,cart) {
  return async (dispatch) => {
    try {
      const result = await axios.post('cart-add',{user_id:user, cart:cart});

      if (result.statusText === 'OK') {
        dispatch({
          type: GETSTOREDEALS,
          payload: { storeDeals: result.data.data },
        });
      }
    } catch (error) {
      //console.log(error);
      // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function addTocart(cart) {
  return async (dispatch) => {
    try {
      const cartData = localStorage.getItem('cart')
        ? JSON.parse(localStorage.getItem('cart'))
        : [];
      if (cartData.length > 0) {
        const Index = cartData.findIndex(
          (item) => Number(item.id) === Number(cart.id)
        );
        if (Index !== -1) {
          cartData[Index].quintity = cartData[Index].quintity + cart.quintity;
          localStorage.setItem('cart', JSON.stringify(cartData));
          dispatch({
            type: ADDTOCART,
            payload: {
              cart: cartData,
            },
          });
          if(localStorage.getItem('userId')){
            await axios.post('cart-add', {
              user_id: localStorage.getItem('userId'),
              cart: cartData,
            });
          }

        } else {
          const setcart = [...cartData, cart];
          localStorage.setItem('cart', JSON.stringify(setcart));
          dispatch({
            type: ADDTOCART,
            payload: {
              cart: setcart,
            },
          });
          if(localStorage.getItem('userId')){
            await axios.post('cart-add', {
              user_id: localStorage.getItem('userId'),
              cart: cartData,
            });
          }

        }
      } else {
        const setcart = [...cartData, cart];
        localStorage.setItem('cart', JSON.stringify(setcart));
        dispatch({
          type: ADDTOCART,
          payload: {
            cart: setcart,
          },
        });
        if(localStorage.getItem('userId')){
          await axios.post('cart-add', {
            user_id: localStorage.getItem('userId'),
            cart: cartData,
          });
        }
      }
    } catch (error) {
      console.log(error);
      // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function get_cart(request) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`cart-get`, { user_id: request });
      if (response.statusText === 'OK' && response.data) {
        if(response.data.status === 1){
          if(response.data.data.cart_data.length > 0){
            dispatch({
              type: ADDTOCART,
              payload: {
                cart: response.data.data.cart_data,
              },
            }); 
          }
        }

      }
    } catch (error) {
      throw error;
    }
  };
}


export function addTocartbuyNow(cart,history) {
  return async (dispatch) => {
    try {
      const cartData = [];
        const setcart = [...cartData, cart];
       await localStorage.setItem('buynow', JSON.stringify(setcart));
       await dispatch({
          type: BUYNOWADDTOCART,
          payload:setcart,
        });
        history.push(
          {
            pathname: '/chooseaddress',
            state: {buynow:'buynow'},
          }
          )
    } catch (error) {
      console.log(error);
      // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function removeCartItem(cart) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ADDTOCART,
        payload: {
          cart: [],
        },
      });
      await localStorage.setItem('cart', JSON.stringify(cart));

      dispatch({
        type: ADDTOCART,
        payload: {
          cart: cart,
        },
      });

      if(localStorage.getItem('userId')){
        await axios.post('cart-add', {
          user_id: localStorage.getItem('userId'),
          cart: cart,
        });
      }

    } catch (error) {
      throw error;
    }
  };
}

export function removeCart() {
  return async (dispatch) => {
    try {
      await localStorage.removeItem('cart');
      await localStorage.removeItem('buynow');
      dispatch({
        type: ADDTOCART,
        payload: {
          cart: [],
        },
      });
      if(localStorage.getItem('userId')){
        await axios.post('cart-add', {
          user_id: localStorage.getItem('userId'),
          cart: [],
        });
      }
    } catch (error) {
      throw error;
    }
  };
}



export function inCreasseQuantity(item) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ADDQUANTITY,
        payload: {
          product: item,
        },
      });
    } catch (error) {
      throw error;
    }
  };
}

export function deCreasseQuantity(item) {
  return async (dispatch) => {
    try {
      dispatch({
        type: SUBQUANTITY,
        payload: {
          product: item,
        },
      });

      if(localStorage.getItem('userId') && item.quintity === 1){
        let new_items = JSON.parse(localStorage.getItem('cart')).filter(product=> product.id !== item.id)
        localStorage.setItem('cart',JSON.stringify(new_items))
        await axios.post('cart-add', {
          user_id: localStorage.getItem('userId'),
          cart: new_items,
        });
      }

    } catch (error) {
      throw error;
    }
  };
}

export function add_to_wishlist(data) {
  return async (dispatch) => {
    try {
      const result = await axios.post('wishlist-add-update', data);

      if (result.statusText === 'OK') {
        NotificationManager.success("Item added in your wishlist.",'',2000)
        // dispatch({
        //   type: ADDTOWISHLIST,
        //   payload: { hotdeal: result.data.data },
        // });
      }
    } catch (error) {
      console.log(error);
      // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function applyCouponCode(data) {
  return async (dispatch) => {
    try {
      const result = await axios.post('coupan-applied', {code:data});
      if (result.statusText === 'OK' && result.data) {
        if(result.data.status === 1){
          dispatch({
            type: APPLYCOUPONCODE,
            payload: { couponinfo: result.data.data },
          });
        }
        else{
          throw new Error(result.data.message)
        }

      }
    } catch (error) {
      console.log(error);
      // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export const finalCalculate=(requestData)=>{
  return async (dispatch) => {
    try {
          dispatch({
            type: SETFINALORDER,
            payload: { finalOrder: requestData},
          });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export const create_orders=(requestData)=>{
  return async (dispatch) => {
    try {
      const result = await axios.post('order-create', requestData);
      if (result.statusText === 'OK' && result.data) {
        if(result.data.status === 1){
          dispatch({
            type: CREATEORDER,
            payload: { orderInfo: result.data.data },
          });
        }
        else{
          throw new Error(result.data.message)
        }

      }
    } catch (error) {
      console.log(error);
      // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export const ordersummary=(requestData)=>{
  return async (dispatch) => {
    try {
      dispatch({
        type: CREATEORDER,
        payload: { orderInfo: null },
      });
    } catch (error) {
      // dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

