
import propTypes from 'prop-types';
import React, { createContext, useEffect } from 'react';
import { useAuth } from '../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../actions/accountActions';
import { get_cart } from '../actions/productAction'

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { isLoggedIn } = useAuth();
 const {user} = useSelector((state)=>state.account)
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(setUserData())
    }
  }, [isLoggedIn]);

  useEffect(()=>{
    if(user && localStorage.getItem("cartset")){
      dispatch(get_cart(user.id))
      localStorage.removeItem("cartset")
    }
  },[user,isLoggedIn])

  return <UserContext.Provider value={user && user}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: propTypes.node.isRequired
};
