/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Breadcrumb from './Includes/breadcrumb'
import Sitemap from './Includes/Sitemap'

const HomePage = (props) => {
  return (
    <div>
      <section class="homePageSect w-100 float-left pt-2 pb-3">
        <div class="container">
            <Breadcrumb />
            <Sitemap />    
          </div>
      </section>
    </div>
  );
};

HomePage.propTypes = {};

export default HomePage;
