import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { BuyNowCart, AddAddressModal } from '../../../Components';
import { AppId,CodCharge } from '../../../../config';
import { Spinner } from 'reactstrap';

import { create_orders, ordersummary } from '../../../../actions/productAction';

const OrderSummary = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    //setAddress(null)
  };
  const [commition, setCommition] = useState(0);
  const calculateTotal = () => {
    cart.forEach((item) => {
      // gst
      // delivery_charge
      // quintity
      // plus_price
      setCommition((preVal) => preVal + item.fp_commission * item.quintity);
    });
  };

  const { finalOrder, orderInfo } = useSelector((state) => state.product);
  const { cart } = useSelector((state) => state.buyproduct);
  const { user, selectedAddress } = useSelector((state) => state.account);
  const [payInicaiting, setPayInicaiting] = useState(false);
  const create_order = async (req) => {
    try {
      setPayInicaiting(true);
      await dispatch(create_orders(req));
      setPayInicaiting(false);
    } catch (error) {
      setPayInicaiting(false);
      console.log(error);
    }
  };
  const makePayment = (e) => {
    e.preventDefault();
    if (selectedAddress && selectedAddress[0].address) {
      const createOrder = {
        user_id: user && user.id,
        product_id: [...cart],
        product_name: '',
        price: '',
        qty: '',
        total_price: '',
        sku: '',
        couponId: '',
        shipping: deliveryCharge,
        discount: couponAmount,
        payment_status: 1,
        shipping_status: 1,
        status: 1,
        total: subTotal + deliveryCharge + taxamount - couponAmount,
        subtotal: subTotal,
        balance: subTotal,
        billing_address_id: selectedAddress[1].address
          ? selectedAddress[1].address.id
          : selectedAddress[0].address.id,
        toname: selectedAddress[0].address.name,
        ship_address1: selectedAddress[0].address.ship_address1,
        ship_address2: selectedAddress[0].address.ship_address2,
        ship_country: selectedAddress[0].address.ship_country,
        ship_city: selectedAddress[0].address.ship_city,
        ship_state: selectedAddress[0].address.ship_state,
        ship_pincode: selectedAddress[0].address.ship_pincode,
        payment_method: 'online',
        ship_landmark: selectedAddress[0].address.ship_landmark,
        phone: selectedAddress[0].address.mobile,
        email: user && user.email ? user.email : '',
        commission: user && user.act_type === 1 ? commition : '',
        userActType: user && user.act_type,
      };
      create_order(createOrder);
    } else {
      NotificationManager.error('Please select a delivery address');
    }
  };

  const payOncash = () => {
    if (selectedAddress && selectedAddress[0].address) {
      const createOrder = {
        user_id: user && user.id,
        product_id: [...cart],
        product_name: '',
        price: '',
        qty: '',
        total_price: '',
        sku: '',
        couponId: '',
        shipping: deliveryCharge,
        discount: couponAmount,
        payment_status: 1,
        shipping_status: 1,
        status: 1,
        total: CodCharge + subTotal + deliveryCharge + taxamount - couponAmount,
        subtotal: subTotal,
        balance: subTotal,
        billing_address_id: selectedAddress[1].address
          ? selectedAddress[1].address.id
          : selectedAddress[0].address.id,
        toname: selectedAddress[0].address.name,
        ship_address1: selectedAddress[0].address.ship_address1,
        ship_address2: selectedAddress[0].address.ship_address2,
        ship_country: selectedAddress[0].address.ship_country,
        ship_city: selectedAddress[0].address.ship_city,
        ship_state: selectedAddress[0].address.ship_state,
        ship_pincode: selectedAddress[0].address.ship_pincode,
        payment_method: 'cash',
        ship_landmark: 'Road',
        phone: selectedAddress[0].address.mobile,
        email: user && user.email ? user.email : '',
        commission: user && user.act_type === 1 ? commition : '',
        userActType: user && user.act_type,
      };
      create_order(createOrder);
      console.log(createOrder);
    } else {
      NotificationManager.error('Please select a delivery address');
    }
  };

  useEffect(() => {
    calculateTotal();
    if (orderInfo) {
      if (orderInfo.payment_method === 'online') {
        setTimeout(function () {
          document.getElementById('redirectForm').submit();
        }, 2000);
      } else {
        localStorage.removeItem('cart');
        history.push(`/orderDetails/${orderInfo.id}`);
      }
    }
  }, [orderInfo]);

  const [paymentMode, setPaymentMode] = useState('online');
  const [codCharge, setcodCharge] = useState(CodCharge);

  const { subTotal, deliveryCharge, taxamount, couponAmount } = finalOrder
    ? finalOrder
    : {};

  return (
    <Fragment>
      <div class="cartPage float-left w-100 py-3">
        <div class="pageTitle float-left w-100 mb-3">
          <h3>Order Summary</h3>
        </div>
        <BuyNowCart paymentMode={paymentMode} />
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <h5>
            <strong>Payment Mode</strong>
          </h5>
          <p>
            <input
              type="radio"
              value="online"
              checked={paymentMode === 'online'}
              onChange={(e) => setPaymentMode(e.target.value)}
            />{' '}
            Online payment &nbsp;{' '}
            <input
              type="radio"
              value="cod"
              checked={paymentMode === 'cod'}
              onChange={(e) => setPaymentMode(e.target.value)}
            />{' '}
            Cash on delivery {CodCharge > 0 ? `(Pay extra Rs ${CodCharge} in cash mode)`: null }
          </p>
        </div>
        <div class="cardBox float-left w-100 mb-4 p-0 borderNone">
          <div class="row">
            <div class="col-md-3 mb-3">
              <div class="adrsBorderBx float-left w-100 h-100">
                <div class="addNewAdrs float-left w-100 text-center">
                  <a href title="Add New Address">
                    <span
                      class="d-block pb-2"
                      onClick={() =>
                        history.push({
                          pathname: '/chooseaddress',
                          state: { buynow: 'buynow' },
                        })
                      }
                    >
                      <img src="/images/icoPlusBlk.png" alt="" />
                    </span>
                    Choose delivery address
                  </a>
                </div>
              </div>
            </div>
            {selectedAddress && selectedAddress[0].address && (
              <div class="col-md-3">
                <div class="float-left w-100 h-100 addressDtl">
                  <h5>Shipping Address</h5>
                  <div class="adrsInfo float-left w-100 p-3">
                    <span>{selectedAddress[0].address.name}</span>
                    <span>{selectedAddress[0].address.ship_address1}</span>
                    <span>{selectedAddress[0].address.ship_address2}</span>
                    <span>{selectedAddress[0].address.ship_city}</span>
                    <span>{selectedAddress[0].address.ship_city}</span>
                    <span>{selectedAddress[0].address.ship_state}</span>
                    <span>{selectedAddress[0].address.ship_pincode}</span>
                    <span>Contact : {selectedAddress[0].address.mobile}</span>
                  </div>
                </div>
              </div>
            )}

            {selectedAddress && selectedAddress[1].address && (
              <div class="col-md-3">
                <div class="float-left w-100 h-100 addressDtl">
                  <h5>Billing Address</h5>
                  <div class="adrsInfo float-left w-100 p-3">
                    <span>{selectedAddress[0].address.name}</span>
                    <span>{selectedAddress[0].address.ship_address1}</span>
                    <span>{selectedAddress[0].address.ship_address2}</span>
                    <span>{selectedAddress[0].address.ship_city}</span>
                    <span>{selectedAddress[0].address.ship_city}</span>
                    <span>{selectedAddress[0].address.ship_state}</span>
                    <span>{selectedAddress[0].address.ship_pincode}</span>
                    <span>Contact : {selectedAddress[0].address.mobile}</span>
                  </div>
                </div>
              </div>
            )}

            {user && user.act_type === 1 && (
              <div class="col-md-3">
                <div class="float-left w-100 h-100 addressDtl">
                  <h5>Commission</h5>
                  <div class="adrsInfo float-left w-100 p-3">
                    <span>Commission earned : {commition}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div class="cardBox float-left w-100 mb-2 py-0 borderNone">
          <div class="row">
            <div class="col-md-12 p-0">
              <div class="float-right w-100 text-center ">
                <form
                  id="redirectForm"
                  method="post"
                  action="https://100plusmall.com/payment.php"
                  onSubmit={makePayment}
                >
                  <input type="hidden" name="appId" value={AppId} />
                  <input
                    type="hidden"
                    name="orderId"
                    value={orderInfo && orderInfo.id}
                  />
                  <input
                    type="hidden"
                    name="orderAmount"
                    value={subTotal + deliveryCharge - couponAmount}
                  />
                  <input type="hidden" name="orderCurrency" value="INR" />
                  <input type="hidden" name="orderNote" value="test" />
                  <input
                    type="hidden"
                    name="customerName"
                    value={
                      selectedAddress[0].address
                        ? selectedAddress[0].address.name
                        : ''
                    }
                  />
                  <input
                    type="hidden"
                    name="customerEmail"
                    value={user && user.email ? user.email : ''}
                  />
                  <input
                    type="hidden"
                    name="customerPhone"
                    value={user && user.mobile}
                  />
                  <input
                    type="hidden"
                    name="returnUrl"
                    value="https://100plusmall.com/response.php"
                  />
                  <input
                    type="hidden"
                    name="notifyUrl"
                    value="https://100plusmall.com/response.php"
                  />
                  {/* <button type="submit">payment</button> */}
                  <button
                    class=" applyBtn d-inline-block mr-2 py-2 backBtn"
                    onClick={() => history.goBack()}
                  >
                    Back
                  </button>

                  {paymentMode === 'online' && (
                    <button
                      class=" applyBtn d-inline-block mx-2 py-2"
                      type="submit"
                    >
                      {payInicaiting && <Spinner color="primary" />} Place Order
                    </button>
                  )}
                  {paymentMode === 'cod' && (
                    <button
                      class=" applyBtn d-inline-block mx-2 py-2"
                      type="button"
                      onClick={payOncash}
                    >
                      {payInicaiting && <Spinner color="primary" />} Place Order
                    </button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <AddAddressModal
          modal={modal}
          toggle={toggle}
          setModal={setModal}
          address={null}
        />
      </div>
    </Fragment>
  );
};

export default OrderSummary;
