/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import FranchiesLogin from './Includes/FranchiesLogin'


const HomePage = (props) => {
  return (
    <div>
      <section class="homePageSect w-100 float-left pt-2 pb-3">
        <div class="container">
            <FranchiesLogin />    
          </div>
      </section>
      
    </div>
  );
};

HomePage.propTypes = {};

export default HomePage;
