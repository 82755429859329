/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import { ProductSlider, SocailShare } from '../../../Components';
import { ImgBaseUrl } from '../../../../config';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Link } from 'react-router-dom';

const ProductDtls = ({
  product,
  isLoading,
  quintity,
  setaddTocart,
  setQuintity,
  addToWishList,
  buyNow,
}) => {
  const { user } = useSelector((state) => state.account);
  const [showcommition, setShowcommition] = useState(false);

  return (
    <>
    <div className="w-100 float-left">
      <div className="row detailPages">
        {isLoading ? (
          <p>Loading ...</p>
        ) : (
          <>
            <Helmet>
              <title>{product && product.metatitle}</title>
              <meta
                name="description"
                content={product && product.metadescription}
              />
              <meta
                name="metakeyword"
                content={product && product.metakeyword}
              />
            </Helmet>
            <div className="col-md-6 ">
              <div className="DtlSliderSect float-left w-100 pr-2">
                <div className="SliderSect float-left w-100">
                  <div className="LargeSlider float-left w-100 mb-3">
                    <div className="shareProduct">
                      <Link
                        title="wish List"
                        onClick={() => addToWishList(product && product.id)}
                      >
                        <img
                          src="/images/ico_wishList_Green.png"
                          alt="Wish List"
                        />
                      </Link>
                    </div>
                    <div className="socialIco">
                      <SocailShare
                        url={window.location.href}
                        media={product && ImgBaseUrl + product.image}
                        prodttitle={product && product.title}
                      />
                      {/* <ul>
                        <li>
                          <Link to="">
                            <img src="/images/ico_fb.png" alt="" />
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <img src="/images/ico_insta.png" alt="" />
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <img src="/images/ico_twiter.png" alt="" />
                          </Link>
                        </li>
                        <li>
                          <Link to="">
                            <img src="/images/ico_wp.png" alt="" />
                          </Link>
                        </li>
                      </ul> */}
                    </div>

                    <div className="imgb float-left w-100">
                      {product && (
                        <ProductSlider
                          productImgage={
                            product && product.images.length > 0
                              ? product.images.map(
                                  ({ filename }) => ImgBaseUrl + filename
                                )
                              : []
                          }
                          //singleImage={product && ImgBaseUrl + product.image}
                        >
                          {product && product.images.length > 0
                            ? product.images.map((item, index) => (
                                <div className="largimageBx">
                                  <img
                                    src={ImgBaseUrl + item.filename}
                                    onError={(e) => {
                                      e.target.src = '/images/Img_product.jpg';
                                    }}
                                    alt="product Image"
                                  />
                                </div>
                              ))
                            : null}
                        </ProductSlider>
                      )}
                    </div>
                  </div>

                  <div className="text-center float-left w-100 pb-2">
                    <label className="pb-1 font-weight-bold mb-0">
                      {' '}
                      Quantity
                    </label>
                    <div className="counterSect w-100 float-left text-center pb-2">
                      <div className="float-left btnSect">
                        <button
                          className="d-inline-block decreaceBtn counterBtn"
                          onClick={() =>
                            setQuintity(quintity > 0 ? quintity - 1 : 0)
                          }
                        >
                          -
                        </button>
                        <input
                          type="text"
                          value={quintity}
                          className="counterInput d-inline-block"
                        />
                        <button
                          className="d-inline-block increaceBtn counterBtn"
                          onClick={() => setQuintity(quintity + 1)}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="text-center float-left w-100 dtnBtn">
                    <button
                      className="customBtn greenBtn yelowBtn addtocart"
                      disabled={quintity > 0 ? false : true}
                    >
                      <span className="float-left pr-2">
                        <img src="/images/ico_cartBlack.png" alt="" />
                      </span>
                      <span
                        className="float-left"
                        onClick={() => setaddTocart(product)}
                      >
                        Add to Cart
                      </span>
                    </button>
                    <button className="customBtn greenBtn">
                      <span className="float-left pr-2">
                        <img src="/images/img_beg.png" alt="" />
                      </span>
                      <span
                        className="float-left"
                        onClick={() => buyNow(product)}
                      >
                        Buy Now
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="detlsDespt float-left w-100">
                <h4>{product && product.title}</h4>
                <div className="dtlPrice float-left w-100 pb-2">
                  <div className="priceSect w-100 float-left">
                    <p>
                      M.R.P :
                      <span className="d-inline-block pb-1 priceDiscount pl-1">
                        <samp className="d-inline-block ">
                          <img src="/images/ico_rupeeLarg.png" alt="" />
                        </samp>
                        <samp className="d-inline-block">
                          {product && product.mrp}
                        </samp>
                      </span>
                    </p>
                    <p>
                      {' '}
                      Price :
                      <span className="d-inline-block pb-1 pl-1 discountColor">
                        {' '}
                        <samp className="d-inline-block ">
                          <img src="/images/ico_rupeeRed.png" alt="" />
                        </samp>
                        <samp className="d-inline-block smallSize">
                          {product && product.plus_price}
                        </samp>
                      </span>
                    </p>
                  </div>
                </div>

                <div className="dtlcAccordions float-left w-100">
                  <Accordion allowZeroExpanded preExpanded={['a', 'b']}>
                    <AccordionItem uuid="a">
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4>Description</h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="accContainer float-left w-100">
                          <p>{product && ReactHtmlParser(product.content)}</p>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    {product && product.specification && (
                      <AccordionItem uuid="b">
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <h4>Specification</h4>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="accContainer float-left w-100">
                            {product && ReactHtmlParser(product.specification)}
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    )}
                  </Accordion>
                  {user && user.act_type === 1 && (
                    <div className="addcupon float-left w-100 pt-4 pb-3">
                      <span
                        className="float-left showcuponSect"
                        onClick={() => setShowcommition(true)}
                      >
                        Show Commision{' '}
                      </span>
                      <div
                        className="priceSect float-left"
                        style={{
                          display: showcommition ? 'block' : 'none',
                        }}
                      >
                        <span className="d-inline-block px-2 prices">
                          <samp className="d-inline-block float-left">
                            <img src="/images/ico_rupeeRed.png" alt="" />
                          </samp>
                          <samp className="d-inline-block float-left">
                            {product && product.fp_commission}
                          </samp>
                        </span>
                      </div>
                    </div>
                  )}
                  {product && product.special_note && (
                    <div className="specialNotes float-left w-100 mb-4">
                      <h5>Special Notes</h5>
                      {product && ReactHtmlParser(product.special_note)}
                    </div>
                  )}

                  {product &&
                    (product.return_period || product.delivery_period) && (
                      <div className="specialNotes float-left w-100 mb-4">
                        <h5>Additional Notes</h5>
                        <div className="px-3 py-2">
                          <p>
                            <strong className="float-left">
                              Delivery Period &nbsp; &nbsp; :
                            </strong>{' '}
                            <span className="float-left">
                              {product && product.delivery_period}
                            </span>
                          </p>
                          <p>
                            <strong className="float-left">
                              Return &nbsp; &nbsp; :{' '}
                            </strong>{' '}
                            <span className="float-left">
                              {' '}
                              {product && product.return_period}
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      </div>
    </>
  );
};

export default ProductDtls;
