import propTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';
import firebase from '../firebase';

export const AuthContext = createContext();

const useAuthProvider = () => {
  const authToken = localStorage.getItem('x-auth') ? localStorage.getItem('x-auth') : localStorage.getItem('userId');
  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!authToken);

  // const login = (email, password) =>
  //   firebase
  //     .auth()
  //     .signInWithEmailAndPassword(email, password)
  //     .then(({ user: firebaseUser }) => {
  //       if (!firebaseUser) {
  //         setIsLoggedIn(false);
  //         return null;
  //       }
  //       if (firebaseUser) {
  //         return firebase
  //           .auth()
  //           .currentUser.getIdToken()
  //           .then(token => {
  //             localStorage.setItem('x-auth', token);
  //             setIsLoggedIn(true);
  //             return firebaseUser;
  //           });
  //       }
  //       throw Error({
  //         status: 'email_not_verified',
  //         message: 'Your email is not verified, please verify your email to proceed.'
  //       });
  //     });

  const logout = () =>
    firebase
      .auth()
      .signOut()
      .then(() => {
        localStorage.removeItem('x-auth');
        localStorage.removeItem('UID');
        localStorage.removeItem('userId');
        localStorage.removeItem('cart');
        setIsLoggedIn(false);
      });

      const loginWithmobile_no = async (mobile) =>{
        var appVerifier = window.recaptchaVerifier;
       return firebase.auth().signInWithPhoneNumber(mobile, appVerifier)
            .then(function (confirmationResult) {
              // SMS sent. Prompt user to type the code from the message, then sign the
              // user in with confirmationResult.confirm(code).
              window.confirmationResult = confirmationResult;
            })
      }

      const loadCaptchaCode = async () =>{

        window.recaptchaVerifier = await new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          'size': 'invisible',
        });
        const appVerifier  = window.recaptchaVerifier;
       return appVerifier.render().then(function(widgetId) {
          window.recaptchaWidgetId = widgetId;
        });
      }

      const verifyOpt = async (code) =>{
        //var code = getCodeFromUserInput();
        const confirmationResult = await window.confirmationResult;
        const result = await confirmationResult.confirm(code);
        var user = result.user;

         if (!user) {
            console.log(user,"user not login")
            setIsLoggedIn(false);
            return null;
          }
        return firebase
        .auth()
        .currentUser.getIdToken()
        .then(token => {
          localStorage.setItem('x-auth', token);
          localStorage.setItem('UID', user.uid);
          setIsLoggedIn(true);
          return user;
        });
      }


  // const loginWithAuthProvider = providerId => {
  //   const provider = new firebase.auth.OAuthProvider(providerId);

  //   return firebase
  //     .auth()
  //     .signInWithPopup(provider)
  //     .then(({ user: firebaseUser }) => {
      
  //       if (!firebaseUser) {
  //         localStorage.removeItem('x-auth');
  //         setIsLoggedIn(false);
  //         return null;
  //       }
  //       return firebase
  //         .auth()
  //         .currentUser.getIdToken()
  //         .then(token => {
  //           console.log(token,'mahipal')
  //           localStorage.setItem('x-auth', token);
            
  //           setIsLoggedIn(true);
  //           return firebaseUser;
  //         });
  //     });
  // };

  // const verifyEmailAddress = () =>
  //   firebase
  //     .auth()
  //     .currentUser.sendEmailVerification()
  //     .then(() => true);

  // const registerWithEmailAndPassword = (email, password) =>
  //   firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(email, password)
  //     .then(({ user: firebaseUser }) => firebaseUser);

  // const verifyEmailAddressLink = actionCode =>
  //   firebase
  //     .auth()
  //     .applyActionCode(actionCode)
  //     .then(() => true);

  // const deleteAccount = () =>
  //   firebase
  //     .auth()
  //     .currentUser.delete()
  //     .then(() => true);

  // useEffect(() => {
  //   const unsubscribe = firebase.auth().onAuthStateChanged(firebaseUser => {
  //     if (firebaseUser) {
  //       return firebase
  //         .auth()
  //         .currentUser.getIdToken()
  //         .then(token => {
  //           localStorage.setItem('x-auth', token);
  //           setIsLoggedIn(true);
  //           return firebaseUser;
  //         });
  //     }
  //     setIsLoggedIn(false);
  //     localStorage.removeItem('x-auth');
  //     return null;
  //   });

  //   // Cleanup subscription on unmount
  //   return () => unsubscribe();
  // }, []);

  return {
    //deleteAccount,
    isLoggedIn,
    // login,
    // loginWithAuthProvider,
     logout,
     setIsLoggedIn,
    // registerWithEmailAndPassword,
    // verifyEmailAddress,
    // verifyEmailAddressLink,
     loginWithmobile_no,
     loadCaptchaCode,
     verifyOpt
  };
};

export const AuthProvider = ({ children }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: propTypes.node.isRequired
};
