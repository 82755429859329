/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import Slider from './Includes/slider';
import Category from './Includes/category';
import Brands from './Includes/brands';
import Deals from './Includes/deals';
import SmartExclusive from './Includes/smartExlusive';
import Store from './Includes/store';
import Recommend from './Includes/recommend';
import { useDispatch, useSelector } from 'react-redux';
import { get_homepageData } from '../../../actions/homepageActions';
import { Helmet } from '../../Components';

const HomePage = (props) => {
  const dispatch = useDispatch();

  const { homepagedata } = useSelector((state) => state.home);

  const fetch_homepage = async () => {
    try {
      await dispatch(get_homepageData());
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetch_homepage();
  }, []);

  console.log(homepagedata);

  return (
    <div>
      <Helmet
        title="Buy Latest Kitchenware Items Online in India - 100 Plus Mall"
        description="Buy Latest Kitchenware items, utensils, crockery, Appliances at a heavy discount. Latest Kitchenware items online in India at lowest Prices on 100 Plus Mall"
        matakeyword="100plusmall"
      />
      <section class="homePageSect w-100 float-left pt-2 pb-3">
        <div class="container">
          <Slider />
          <Category
            categories={
              homepagedata && homepagedata.Category ? homepagedata.Category : []
            }
          />
          <Brands
            brands={
              homepagedata && homepagedata.brands ? homepagedata.brands : []
            }
          />
          <Deals
            hotdeals={
              homepagedata && homepagedata.hotDeals ? homepagedata.hotDeals : []
            }
          />
          <SmartExclusive
            smartDeals={
              homepagedata && homepagedata.smartDeals
                ? homepagedata.smartDeals
                : []
            }
          />
          <Store
            storeDeals={
              homepagedata && homepagedata.storeDeals
                ? homepagedata.storeDeals
                : []
            }
          />
          <Recommend />
        </div>
      </section>
    </div>
  );
};

HomePage.propTypes = {};

export default HomePage;
