import React, { Fragment, useState, useEffect } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImgBaseUrl,CodCharge } from '../../../config';
import {
  removeCartItem,
  inCreasseQuantity,
  deCreasseQuantity,
  applyCouponCode,
  finalCalculate,
} from '../../../actions/productAction';
import { NotificationManager } from 'react-notifications';

const BuyNowCart = ({ paymentMode = 'online' }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [couponCode, setCouponCode] = useState('');
  const [taxamount, setTaxamount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [couponAmount, setCouponAmount] = useState(0);
  const { user } = useSelector((state) => state.account);
  const { couponinfo } = useSelector((state) => state.product);
  const { cart } = useSelector((state) => state.buyproduct);

  const calculateTotal = () => {
    cart.forEach((item) => {
      // gst
      // delivery_charge
      // quintity
      // plus_price
      // if (item.gst) {
      //   console.log('tax');
      //   setTaxamount((preVal) =>  preVal + item.delivery_charge * item.quintity);
      // } else {
      //   console.log('hello taxt');
      // }
      setSubTotal((preValue) => preValue + item.plus_price * item.quintity);
      setDeliveryCharge((preState) => {
        return preState + item.delivery_charge * item.quintity > 298
          ? 298
          : preState + item.delivery_charge * item.quintity;
      });
    });
  };
  const [errorCoupon, setError] = useState();
  const couponApply = async () => {
    try {
      console.log(couponCode);
      await dispatch(applyCouponCode(couponCode));
      NotificationManager.success('Product added in your cart.','',1000);
    } catch (error) {
      setError(error.message);
      console.log(error.message);
    }
  };

  useEffect(() => {
    setTaxamount(0);
    setSubTotal(0);
    setCouponAmount(0);
    setDeliveryCharge(0);
    calculateTotal();
  }, [cart]);

  useEffect(() => {
    if (couponinfo) {
      if (couponinfo && couponinfo.coupon_type === 'percentage') {
        const calculateDis = Math.floor(
          (subTotal * Number(couponinfo.value)) / 100
        );
        console.log(couponinfo, calculateDis);
        setCouponAmount(calculateDis);
      } else {
        setCouponAmount(Number(couponinfo.value));
      }
    }
  }, [couponinfo]);

  const removeItem = async (productId) => {
    const Index = cart.findIndex(
      (item) => Number(item.id) === Number(productId)
    );
    const removeItem = [...cart];
    removeItem.splice(Index, 1);
    setTaxamount(0);
    setSubTotal(0);
    setCouponAmount(0);
    setDeliveryCharge(0);
    await dispatch(removeCartItem(removeItem));
  };

  const increase_quanity = async (item) => {
    setTaxamount(0);
    setSubTotal(0);
    setCouponAmount(0);
    setDeliveryCharge(0);
    const product = { ...item };
    product.quintity += 1;
    // console.log(product)
    await dispatch(inCreasseQuantity(item));
    calculateTotal();
  };

  const decrease_quanity = (item) => {
    setTaxamount(0);
    setSubTotal(0);
    setCouponAmount(0);
    setDeliveryCharge(0);
    dispatch(deCreasseQuantity(item));
    calculateTotal();
  };

  useEffect(() => {
    const reqParams = {
      subTotal,
      deliveryCharge: deliveryCharge > 298 ? 298 : deliveryCharge,
      taxamount,
      couponAmount,
    };
    dispatch(finalCalculate(reqParams));
  }, [subTotal, deliveryCharge, taxamount, couponAmount]);

  return (
    <Fragment>
      <div className="cartPage float-left w-100 py-3">
        {cart && cart.length > 0 ? (
          cart.map((item) => {
            return (
              <div className="cardBox float-left w-100 mb-2">
                <div className="row">
                  <div className="col-md-2 imageBx">
                    <div className="imgb float-left w-100">
                      <a href title="product image" className="d-block">
                        <img
                          src={`${ImgBaseUrl}${item.image}`}
                          alt={item.title}
                          className="w-100"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 cartProductName">
                    <div className="cartProductDtl float-left w-100 h-100">
                      <h4>
                        <Link to={`/prducts/${item.slug}`} title={item.title}>
                          {item.title}
                        </Link>
                      </h4>
                      {location.pathname !== '/ordersummary' && (
                        <div className="float-left w-100 cartCounterSect">
                          <div className="float-left btnSect">
                            <button
                              className="d-inline-block decreaceBtn counterBtn"
                              onClick={() => decrease_quanity(item)}
                            >
                              -
                            </button>
                            <input
                              type="text"
                              value={item.quintity}
                              className="counterInput d-inline-block"
                            />
                            <button
                              className="d-inline-block increaceBtn counterBtn"
                              onClick={() => increase_quanity(item)}
                            >
                              +
                            </button>
                          </div>
                          <div className="float-left cartActList">
                            <ul>
                              <li
                                className="removeProduct"
                                onClick={() => removeItem(item.id)}
                              >
                                <a href title="Remove">
                                  Remove
                                </a>
                              </li>
                              <li className="addWishList">
                                <a href title="wish List">
                                  &nbsp;
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4 cartPrice">
                    <div className="cartProductPrice float-left w-100 h-100">
                      <div className="PriceCommision float-left w-100">
                        <div className="priceSect w-100 float-left">
                          <p>
                            <strong>Price </strong>
                            <span className="d-inline-block pb-1 pl-1">
                              :
                              <samp className="d-inline-block ">
                                <img src="/images/ico_rupeeSmall.png" alt="" />
                              </samp>
                              <samp className="d-inline-block">
                                {item.plus_price}
                              </samp>
                            </span>
                          </p>
                          {location.pathname === '/ordersummary' &&
                            paymentMode === 'cod' && (
                              <p>
                                <strong>Extra Charge</strong>
                                <span className="d-inline-block pb-1 pl-1 ">
                                  {' '}
                                  :
                                  <samp className="d-inline-block ">
                                    <img
                                      src="/images/ico_rupeeSmall.png"
                                      alt=""
                                    />
                                  </samp>
                                  <samp className="d-inline-block smallSize">
                                    {CodCharge}
                                  </samp>
                                </span>
                              </p>
                            )}

                          {user && user.act_type === 1 && (
                            <p>
                              <strong>Commission</strong>
                              <span className="d-inline-block pb-1 pl-1 ">
                                :
                                <samp className="d-inline-block ">
                                  <img
                                    src="/images/ico_rupeeSmall.png"
                                    alt=""
                                  />
                                </samp>
                                <samp className="d-inline-block smallSize">
                                  {item.fp_commission}
                                </samp>
                              </span>
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="deliveryCharge w-100">
                        <div className="PriceCommision float-left w-100">
                          <div className="priceSect w-100 float-left">
                            <p>
                              <strong>Delivery Charges </strong>
                              <span className="d-inline-block pb-1 pl-1">
                                :
                                <samp className="d-inline-block ">
                                  <img src="/images/ico_rupeeSmall.png" />
                                </samp>
                                <samp className="d-inline-block">
                                  {item.delivery_charge}
                                </samp>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p>No item is available in cart.</p>
        )}

        <div className="cardBox float-left w-100 borderNone">
          {cart && cart.length > 0 && (
            <div className="row">
              <div className="col-md-2 imageBx">&nbsp;</div>
              <div className="col-md-6 cartProductName">
                {location.pathname === '/ordersummary' && (
                  <div className="cuponCodeAply float-right">
                    <div className="float-left pr-4">
                      <strong>Have A Coupon Code?</strong>
                    </div>
                    <div className="float-left">
                      <div>
                        <input
                          type="text"
                          placeholder=""
                          className="InputCupon float-left"
                          value={couponCode}
                          onChange={(e) => setCouponCode(e.target.value)}
                          onFocus={() => setError('')}
                        />
                        <button
                          className="float-left applyBtn"
                          disabled={couponCode === ''}
                          onClick={() => couponApply()}
                        >
                          Apply
                        </button>
                      </div>
                      <div className="py-2 px-3 float-left codeBtm">
                        {errorCoupon && (
                          <span>
                            <span
                              className="float-left pr-2 "
                              style={{ color: 'red' }}
                            >
                              {errorCoupon}
                            </span>
                            {/* <samp className="float-left">
                          <img src="/images/rightTick.png" alt="right " />
                        </samp> */}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-4 cartPrice">
                <div className="cartProductPrice float-left w-100 h-100">
                  <div className="PriceCommision float-left w-100">
                    <div className="priceSect w-100 float-left">
                      <p>
                        <strong>Subtotal ({cart.length}Items) </strong>
                        <span className="d-inline-block pb-1 pl-1">
                          :
                          <samp className="d-inline-block ">
                            <img src="/images/ico_rupeeSmall.png" />
                          </samp>
                          <samp className="d-inline-block">{subTotal}</samp>
                        </span>
                      </p>
                      {/* <p>
                        <strong>GST</strong>
                        <span className="d-inline-block pb-1 pl-1 ">
                          {' '}
                          :
                          <samp className="d-inline-block ">
                            <img src="/images/ico_rupeeSmall.png" />
                          </samp>
                          <samp className="d-inline-block smallSize">
                            {taxamount}
                          </samp>
                        </span>
                      </p> */}
                      <p>
                        <strong>Delivery Charge</strong>
                        <span className="d-inline-block pb-1 pl-1 ">
                          {' '}
                          :
                          <samp className="d-inline-block ">
                            <img src="/images/ico_rupeeSmall.png" />
                          </samp>
                          <samp className="d-inline-block smallSize">
                            {deliveryCharge}
                          </samp>
                        </span>
                      </p>
                      {location.pathname === '/ordersummary' && paymentMode ==='cod' && (
                      <p>
                        <strong>Extra Charge</strong>
                        <span className="d-inline-block pb-1 pl-1 ">
                          {' '}
                          :
                          <samp className="d-inline-block ">
                            <img src="/images/ico_rupeeSmall.png" alt="" />
                          </samp>
                          <samp className="d-inline-block smallSize">
                            {CodCharge}
                          </samp>
                        </span>
                      </p>)
                      }
                      <p>
                        <strong>Discount </strong>
                        <span className="d-inline-block pb-1 pl-1 ">
                          {' '}
                          :
                          <samp className="d-inline-block ">
                            <img src="/images/ico_rupeeSmall.png" />
                          </samp>
                          <samp className="d-inline-block smallSize">
                            {couponAmount}
                          </samp>
                        </span>
                      </p>
                      <p>
                        <strong>Total</strong>
                        <span className="d-inline-block pb-1 pl-1 ">
                          {' '}
                          :
                          <samp className="d-inline-block ">
                            <img src="/images/ico_rupeeSmall.png" />
                          </samp>
                          <samp className="d-inline-block smallSize">
                            {/* {subTotal + deliveryCharge + -couponAmount} */}
                            {paymentMode==='cod'? CodCharge + subTotal + deliveryCharge + -couponAmount : 0 + subTotal + deliveryCharge + -couponAmount}
                          </samp>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default BuyNowCart;
