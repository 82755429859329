/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
    BUYNOWADDTOCART
} from '../actions/productAction';

const initialState = {
    cart : localStorage.getItem('buynow') ? JSON.parse(localStorage.getItem('buynow')) :[],
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
     case BUYNOWADDTOCART: {
      return produce(state, (draft) => {
         draft.cart = action.payload;
       });
     }
    default: {
      return state;
    }
  }
};

export default accountReducer;
