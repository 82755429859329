import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import homepageReducer from './homepageReducer';
import productReducer from './productReducer';
import buynowReducer from './buynowReducer'

const rootReducer = combineReducers({
  account: accountReducer,
  home: homepageReducer,
  product: productReducer,
  buyproduct: buynowReducer
});

export default rootReducer;
