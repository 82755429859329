/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Fragment, useEffect,useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Sidebar } from '../../../Components';
import { useDispatch, useSelector } from 'react-redux';
import {
  getWishlist,
  removeWishlistItem,
} from '../../../../actions/accountActions';
import { addTocart } from '../../../../actions/productAction';
import { Link } from 'react-router-dom';
import { ImgBaseUrl } from '../../../../config';
import { NotificationManager } from 'react-notifications';
import { Helmet } from 'react-helmet';

const Wishlist = () => {
  const dispatch = useDispatch();
  const { user, wishlists } = useSelector((state) => state.account);
  const [isLoading,setIsLoading] = useState(false);
  const setaddTocart = async (item) => {
    const cartItem = { ...item };
    cartItem.quintity = 1;
    await dispatch(addTocart(cartItem));
    await removeWishlist(cartItem);
    NotificationManager.success('Product added in your cart.','',1000);
    
  };



  useEffect(() => {
    if (user) {
      const fatchWishlist = async()=>{
        try {
          setIsLoading(true)
          await dispatch(getWishlist(user && user.id));
          
        } finally {
          setIsLoading(false)
        }

   }
    fatchWishlist()
    }
  }, [user]);

  const removeWishlist = (item) => {
    const reqData = {
      user_id: user && user.id,
      product_id: item.id,
      id: item.wish_id,
    };
    dispatch(removeWishlistItem(reqData));
  };

  return (
    <Fragment>
      <div class="userDashboard float-left w-100 my-3">
        <div class="row">
          <Sidebar />
          <div class="col-md-9">
            <div class="wishListPage float-left w-100 ">
              <h4>Items in your Wishlist </h4>

              <div class="float-left w-100 wishProductList pt-2">
                {isLoading ? <p>Loading ...</p>:wishlists && wishlists.length > 0 ? (
                  wishlists.map((item, index) => {
                    return (
                      <div class="row wishProd mb-4">
                        <div class="col-md-3">
                          <div class="imgb float-left w-100">
                            <a href title="">
                              <img
                                src={ImgBaseUrl + item.image}
                                onError={(e) => {
                                  e.target.src = '/images/Img_product.jpg';
                                }}
                                alt="product Image"
                                class="w-100"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="wishProDtls float-left w-100">
                            <Link
                              title="remove"
                              class="removeProd"
                              onClick={() => removeWishlist(item)}
                            >
                              X{' '}
                            </Link>
                            <h5>
                              <Link
                                to={`${'/prducts/'}${item.slug}`}
                                title={item.title}
                              >
                                {item.title}
                              </Link>
                            </h5>
                            <div class="dtlPrice float-left w-100 pb-2">
                              <div class="priceSect w-100 float-left">
                                <p>
                                  M.R.P :
                                  <span class="d-inline-block pb-1 priceDiscount pl-1">
                                    <samp class="d-inline-block ">
                                      <img
                                        src="/images/ico_rupeeLarg.png"
                                        alt=""
                                      />
                                    </samp>
                                    <samp class="d-inline-block">
                                      {item.mrp}
                                    </samp>
                                  </span>
                                </p>
                                <p>
                                  {' '}
                                  Price :
                                  <span class="d-inline-block pb-1 pl-1 discountColor">
                                    {' '}
                                    <samp class="d-inline-block ">
                                      <img
                                        src="/images/ico_rupeeRed.png"
                                        alt=""
                                      />
                                    </samp>
                                    <samp class="d-inline-block smallSize">
                                      {item.plus_price}
                                    </samp>
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div class="float-left w-100">
                              <button class="customBtn greenBtn yelowBtn">
                                <span class="float-left pr-2">
                                  <img src="/images/ico_cartBlack.png" alt="" />
                                </span>
                                <span
                                  class="float-left"
                                  onClick={() => setaddTocart(item)}
                                >
                                  Add to Cart
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div class="float-left w-100 pt-2 wishProductList ">
                    <div class=" text-center float-left w-100 py-5">
                      <div class="nowishListItem float-left w-100 ">
                        <samp>
                          <img
                            src="images/no_wishlistItem.png"
                            alt="No wish list Item"
                          />
                        </samp>
                        <span>Add some itmes to your list </span>
                        <a href title="Explore Now">
                          Explore Now
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Wishlist;
