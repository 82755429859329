import React, { Fragment,useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImgBaseUrl } from '../../../../config';
import { get_FooterMenu } from '../../../../actions/homepageActions';
import parse from 'html-react-parser';
const Aboutus = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { footermenu } = useSelector((state) => state.home);
  const [isLoading, setIsLoading] = useState(false);
  
  const fetch_products = async () => {
    try {
      setIsLoading(true);
      await dispatch(get_FooterMenu());
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetch_products();
  }, []);

  

  return (
    
    <Fragment>

  <div className="float-left w-100 my-3 wow fadeInUp">
      {footermenu.map((item, index) => (
        <div className="w-100">
         { 
         (item.alias === 'about-us')
         ? <div> 
        {parse(item.content.article_description)}
     
        </div> 
          : <div> </div> 
        }
        </div>
        ))}
      </div>

      
    </Fragment>
  );
};

export default Aboutus;
