/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

class ProductSlider extends Component {

  render() {  
    const paging = this.props.productImgage
    const settings = {
      customPaging: function (i) {
        return (
            <a>
              <img src={paging[i]} alt="product Image" />
            </a>
        );
      },
      dots: true,
      dotsClass: 'slick-dots slick-thumb',
      infinite: true,
      centerMode: true,
      slidesToShow: this.props.slideshow,
      slidesToScroll: 1,
      speed: 500,
      centerPadding: 0,
      arrows:false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return <Slider {...settings}>{this.props.children}</Slider>;
  }
}

ProductSlider.defaultProps = {
  theme: 'secondary',
  productImgage:[],
  slideshow: 1,
  changePage: (e) => {
    console.log(e, 'mahipal');
  },
};

ProductSlider.propTypes = {
  children: PropTypes.node,
  slideshow: PropTypes.number,
  changePage: PropTypes.func,
  productImgage:PropTypes.array
};

export default ProductSlider;
