import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { zipcodebaseApiKey } from '../../../config';

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormText,
  Spinner
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import {
  addAddress,
  getUseraddress,
  update_address,
} from '../../../actions/accountActions';

const formFields = {
  user_id: '',
  name: '',
  mobile: '',
  ship_address1: '',
  ship_address2: '',
  ship_city: '',
  ship_state: '',
  ship_pincode: '',
  ship_landmark: '',
  ship_country: 'India',
};

const formFieldsErr = {
  name: '',
  mobile: '',
  ship_address1: '',
  ship_address2: '',
  ship_city: '',
  ship_state: '',
  ship_pincode: '',
  ship_country: '',
};

function AddAddressModal({ modal, setModal, toggle, address }) {
  const [formValues, setFormValues] = useState(formFields);
  const [formErr, setFormErr] = useState(formFieldsErr);

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.account);
  const handleChange = (e) => {
    const fromvalue = { ...formValues };
    const fromerror = { ...formErr };
    const name = e.target.name;
    const value = e.target.value;
    fromvalue[name] = value;
    fromerror[name] = '';

    setFormValues(fromvalue);
    setFormErr(fromerror);
  };

  const formValidation = () => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const fromvalue = { ...formValues };
    const fromerror = { ...formErr };
    let error = true;
    if (!fromvalue.name) {
      fromerror.name = 'Name field is required!';
      error = false;
    }
    if (!fromvalue.mobile) {
      fromerror.mobile = 'Mobile field is required!';
      error = false;
    }
    if (fromvalue.mobile && !phoneno.test(fromvalue.mobile)) {
      fromerror.mobile = 'Enter valid phone no.';
      error = false;
    }
    if (!fromvalue.ship_address1) {
      fromerror.ship_address1 = 'Field is required!';
      error = false;
    }
    if (!fromvalue.ship_address2) {
      fromerror.ship_address2 = 'Field is required!';
      error = false;
    }
    if (!fromvalue.ship_city) {
      fromerror.ship_city = 'City is required!';
      error = false;
    }
    if (!fromvalue.ship_state) {
      fromerror.ship_state = 'State is required!';
      error = false;
    }

    if (!fromvalue.ship_pincode) {
      fromerror.ship_pincode = 'Pin code is required!';
      error = false;
    }
    if (!fromvalue.ship_country) {
      fromerror.ship_country = 'Country is required!';
      error = false;
    }
    setFormErr(fromerror);
    return error;
  };
  const [isLoading, setIsLoading] = useState(false);

  const fetchaddAddress = async (reqData) => {
    try {
      if (updateStatus) {
        setIsLoading(true)
        await dispatch(update_address(reqData));
      } else {
        setIsLoading(true)
        await dispatch(addAddress(reqData));
      }
      toggle();
      await dispatch(getUseraddress(user && user.id));
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (formValidation()) {
      console.log(user);
      const reqData = { ...formValues };
      reqData.user_id = user && user.id;
      reqData.full_villege = reqData.ship_address2;
      reqData.full_house = reqData.ship_address1;

      fetchaddAddress(reqData);
    }
  };

  const [updateStatus, setupdateStatus] = useState(false);

  const fetchstate = async () => {
    if (address) {
      await getStatecityedit(address.ship_pincode.trim())
      await setFormValues(address);
      setupdateStatus(true);

    }
  };
  useEffect(() => {
    fetchstate();
  }, [address]);

  const [cityOption, setCityoption] = useState([]);
  const getStatecityedit = async (pincode) => {
    try {
      if (pincode) {
        setCityoption([]);
        const fromvalue = { ...formValues };
        const result = await axios.get(
          `https://app.zipcodebase.com/api/v1/search?apikey=${zipcodebaseApiKey}&codes=${pincode}`
        );
        const record = result.data.results[pincode].filter(
          (item) => item.country_code === 'IN'
        )[0];

        const cityValue = result.data.results[pincode].map(
          (item) => item.province
        );
        setCityoption([...new Set(cityValue)]);

        //fromvalue.ship_city = record.province;
        fromvalue.ship_state = record.state;
        setFormValues(fromvalue);
      }
    } catch (error) {}
  };

  const getStatecity = async (e) => {
    try {
      if (formValues.ship_pincode) {
        setCityoption([]);
        const fromvalue = { ...formValues };
        const result = await axios.get(
          `https://app.zipcodebase.com/api/v1/search?apikey=${zipcodebaseApiKey}&codes=${formValues.ship_pincode}`
        );
        const record = result.data.results[formValues.ship_pincode].filter(
          (item) => item.country_code === 'IN'
        )[0];

        const cityValue = result.data.results[formValues.ship_pincode].map(
          (item) => item.province
        );
        setCityoption([...new Set(cityValue)]);

        fromvalue.ship_city = record.province;
        fromvalue.ship_state = record.state;
        setFormValues(fromvalue);
      }
    } catch (error) {}
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      centered
      backdrop={false}
      scrollable
      size="lg"
    >
      <ModalHeader toggle={toggle}>
        <h3>Enter Address</h3>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={submitForm}>
          <div className="form-group">
            <label for="inputname">Name</label>
            <input
              onChange={handleChange}
              value={formValues.name}
              name="name"
              type="text"
              className="form-control"
              id="inputname"
              placeholder="Enter Name"
            />
            <FormText color="danger">{formErr.name}</FormText>
          </div>
          <div className="form-group">
            <label for="inputname">Mobile No.</label>
            <input
              onChange={handleChange}
              value={formValues.mobile}
              name="mobile"
              type="text"
              className="form-control"
              id="inputname"
              placeholder="Enter Mobile"
              onKeyDown={(e) => {
                //console.log(e.keyCode)

                e.keyCode > 31 &&
                  (e.keyCode < 48 || e.keyCode > 57) &&
                  e.preventDefault();
              }}
            />
            <FormText color="danger">{formErr.mobile}</FormText>
          </div>
          <div className="form-group">
            <label for="inputHn">
              House No, Flat, Street Number, Building, Office
            </label>
            <input
              onChange={handleChange}
              value={formValues.ship_address1}
              name="ship_address1"
              type="text"
              className="form-control"
              id="inputHn"
              placeholder="Enter House No"
            />
            <FormText color="danger">{formErr.ship_address1}</FormText>
          </div>
          <div className="form-group">
            <label for="inputArea">Village, Area, Locality, Colony</label>
            <input
              onChange={handleChange}
              value={formValues.ship_address2}
              name="ship_address2"
              type="text"
              className="form-control"
              id="inputArea"
              placeholder="Enter Village, Area, Locality, Colony"
            />
            <FormText color="danger">{formErr.ship_address2}</FormText>
          </div>
          <div className="form-group">
            <label for="inputLandmark">Landmark</label>
            <input
              onChange={handleChange}
              value={formValues.ship_landmark}
              name="ship_landmark"
              type="text"
              className="form-control"
              id="inputLandmark"
              placeholder="Enter Landmark"
            />
          </div>
          <div className="form-group">
            <label for="inputPin">Pincode</label>
            <input
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === " ") {
                  e.preventDefault();
                }
              }}
              value={formValues.ship_pincode}
              name="ship_pincode"
              type="text"
              className="form-control"
              onBlur={(e) => getStatecity(e)}
              id="inputPin"
              placeholder="Enter Pincode"
            />
            <FormText color="danger">{formErr.ship_pincode}</FormText>
          </div>
          <div className="form-group">
            <label for="inputCity">City/District</label>
            <select
              onChange={handleChange}
              value={formValues.ship_city}
              name="ship_city"
              className="form-control"
              disabled={cityOption.length > 0 ? false : true}
            >
              <option>select</option>
              {cityOption.map((item) => (
                <option value={item} key={item}>
                  {item}
                </option>
              ))}
            </select>
            {/* <input
              type="text"
              onChange={handleChange}
              value={formValues.ship_city}
              name="ship_city"
              className="form-control"
              id="inputCity"
              placeholder="Enter City/District"
              readOnly
            /> */}
            <FormText color="danger">{formErr.ship_city}</FormText>
          </div>
          <div className="form-group">
            <label for="inputstate">State</label>
            <input
              onChange={handleChange}
              readOnly
              value={formValues.ship_state}
              name="ship_state"
              type="text"
              className="form-control"
              id="inputstate"
              placeholder="Enter State"
            />
            <FormText color="danger">{formErr.ship_state}</FormText>
          </div>
          <div className="form-group">
            <label for="inputstate">Country</label>
            <input
              onChange={handleChange}
              value={formValues.ship_country}
              name="ship_country"
              type="text"
              className="form-control"
              id="inputstate"
              placeholder="Enter country"
              readOnly
            />
            <FormText color="danger">{formErr.ship_country}</FormText>
          </div>

          <ModalFooter>
            <button
              type="button"
              className="applyBtn d-inline-block mr-2 backBtn"
              onClick={toggle}
            >
              Cancel
            </button>
            <button type="submit" className="applyBtn d-inline-block">
              {isLoading &&  <Spinner color="secondary" />}
              {address ? 'Update address' : 'Save Address'}

            </button>
          </ModalFooter>
        </form>
      </ModalBody>
    </Modal>
  );
}

AddAddressModal.propTypes = {};

export default AddAddressModal;
