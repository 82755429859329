import React, { Fragment,useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ImgBaseUrl } from '../../../../config';
import { get_FaqData } from '../../../../actions/homepageActions';


import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const Faq = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { faq } = useSelector((state) => state.home);
  const [isLoading, setIsLoading] = useState(false);
  
  const fetch_products = async () => {
    try {
      setIsLoading(true);
      await dispatch(get_FaqData());
      setIsLoading(false);
      console.log(faq);
    } catch (err) {
      setIsLoading(false);
      console.log(faq);
    }
  };

  useEffect(() => {
    fetch_products();
  }, []);

  return (
    <Fragment>
      
      <div class="float-left w-100 contactPage my-3 wow fadeInUp">
          <h3><span>Faq</span></h3>
          <div class="row">
              <div class="col-md-12 faqpage">
              <Accordion>
                { faq.length > 0 && faq.map((item, index) => (
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        <h4>{item.question}</h4>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="float-left w-100 faqContent">
                        <p>{item.answer} </p>
                      </div>  
                    </AccordionItemPanel>
                </AccordionItem>
                ))}
            </Accordion>
              </div>
          </div>
        </div>
    </Fragment>
  );
};

export default Faq;
