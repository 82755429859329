import React, { Fragment, useEffect, useState } from 'react';
import { AddAddressModal } from '../../../Components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import {
  getAddresswithoutpagination,
  getDefaultAddress,
  change_Address,
} from '../../../../actions/accountActions';

const ChooseAddress = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.product);
  const { user, userAddrsss, selectedAddress } = useSelector(
    (state) => state.account
  );
  const location = useLocation();
  const { state } = location;

  const [isLoading, setIsLoading] = useState();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    //setAddress(null)
  };

  useEffect(() => {
    setIsLoading(true);
    if (user && !modal) {
      const fetchAddress = async (userId) => {
        try {
          setIsLoading(true);
          await dispatch(getAddresswithoutpagination(user && user.id));
          await dispatch(getDefaultAddress(user && user.id));
        } finally {
          setIsLoading(false);
        }
      };
      fetchAddress();
    }
  }, [user, modal]);
  const [choose, setChoose] = useState([
    { address: {}, add_type: 'ship' },
    { address: {}, add_type: 'bill' },
  ]);

  const [statusAddressship, setstatusAddressship] = useState(2);
  const [statusAddresssbill, setstatusAddressbill] = useState(3);

  const changeAddress = (item, status) => {
    console.log(status);
    const chooseAdd = [...selectedAddress];

    if (status === 'ship') {
      chooseAdd[0] = { address: { ...item }, add_type: 'ship' };
      setstatusAddressship('');
    }
    if (status === 'bill') {
      chooseAdd[1] = { address: { ...item }, add_type: 'bill' };
      setstatusAddressbill('');
    }
    setChoose(chooseAdd);
    dispatch(change_Address(chooseAdd));
  };

  const { data = [] } = userAddrsss ? userAddrsss : {};

  const [checkbox, setcheckbox] = useState('no');
  const handleChange = (e) => {
    setcheckbox(e.target.value);
    const chooseAdd = [...selectedAddress];
    if (e.target.value === 'yes') {
      if (chooseAdd && chooseAdd[0].address) {
        chooseAdd[1] = {
          address: { ...chooseAdd[0].address },
          add_type: 'bill',
        };
        dispatch(change_Address(chooseAdd));
      } else {
        NotificationManager.error('Please select first shipping address');
      }
    } else {
      chooseAdd[1] = { address: null, add_type: 'bill' };
      dispatch(change_Address(chooseAdd));
    }
  };

  return (
    <Fragment>
      <div className="chooseressPage float-left w-100 my-2">
        <div className="pageTitle float-left w-100 bgNone">
          <h3>Choose Shipping Address</h3>
        </div>
        <div className="chooseAdrsBx float-left w-100 my-2">
          <div className="adresScrolBx">
            <div className="row">
              <div className="col-md-3 mb-3">
                <div className="adrsBorderBx float-left w-100 h-100">
                  <div className="addNewAdrs float-left w-100 text-center">
                    <a href title="Add New Address">
                      <span className="d-block pb-2" onClick={toggle}>
                        <img src="/images/icoPlusBlk.png" alt="" />
                      </span>
                      Add New Address
                    </a>
                  </div>
                </div>
              </div>
              {isLoading ? (
                <p>Loading ...</p>
              ) : userAddrsss && userAddrsss.length > 0 ? (
                userAddrsss.map((item) => (
                  <div className="col-md-3 mb-3">
                    <div className="adrsBorderBx float-left w-100 h-100">
                      <div className="userAdrssInfo float-left w-100 h-100">
                        <div className="float-left w-100 py-2">
                          <div className="adrsLeft">
                            <span>Name</span>
                          </div>
                          <div className="adrsRight">
                            <span>{item.name}</span>
                          </div>
                        </div>
                        <div className="float-left w-100 py-2">
                          <div className="adrsLeft">
                            <span>Address</span>
                          </div>
                          <div className="adrsRight">
                            <span>{item.ship_address1}</span>
                            <span>{item.ship_address2}</span>
                            <span>{item.ship_city}</span>
                            <span>{item.ship_city}</span>
                            <span>{item.ship_state}</span>
                            <span>{item.ship_pincode}</span>
                          </div>
                        </div>
                        <div className="float-left w-100 py-2">
                          <div className="adrsLeft">
                            <span>Contact</span>
                          </div>
                          <div className="adrsRight">
                            <span>{item.mobile}</span>
                          </div>
                        </div>
                        {selectedAddress &&
                        selectedAddress[0].address &&
                        selectedAddress[0].address.id === item.id ? (
                          <div className="selectAdrs float-left w-100 text-center">
                            <button
                              color="success"
                              title="Select"
                              className="m-0 by-1 btn-block applyBtn"
                            >
                              Selected
                            </button>
                          </div>
                        ) : item.status === statusAddressship ? (
                          <div className="selectAdrs float-left w-100 py-1 text-center">
                            <button
                              color="success"
                              title="Select"
                              className="m-0 btn-block applyBtn"
                            >
                              Selected
                            </button>
                          </div>
                        ) : (
                          <div className="selectAdrs float-left w-100 text-center">
                            <button
                              className="applyBtn btn-block py-1 backBtn"
                              onClick={() => changeAddress(item, 'ship')}
                            >
                              Select
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No record</p>
              )}
            </div>
          </div>
        </div>

        <div className="billingAddress float-left w-100 mt-4">
          <h4>Billing Address Same as Shipping Address?</h4>
          <div className="w-100 float-left mb-2">
            <label className="billingAddressLabel">
              Yes
              <input
                type="radio"
                value="yes"
                name="radio"
                checked={checkbox === 'yes'}
                onChange={handleChange}
              />
              <span className="checkmark"></span>
            </label>
            <label className="billingAddressLabel">
              No
              <input
                type="radio"
                name="radio"
                value="no"
                checked={checkbox === 'no'}
                onChange={handleChange}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="w-100 float-left mb-3 addressLabel">
            <strong>Select Billing Address</strong>
          </div>
          <div className="chooseAdrsBx float-left w-100 my-2">
            <div className="adresScrolBx">
              <div className="row">
                <div className="col-md-3 mb-3">
                  <div className="adrsBorderBx float-left w-100 h-100">
                    <div className="addNewAdrs float-left w-100 text-center">
                      <a href title="Add New Address">
                        <span className="d-block pb-2" onClick={toggle}>
                          <img src="/images/icoPlusBlk.png" alt="" />
                        </span>
                        Add New Address
                      </a>
                    </div>
                  </div>
                </div>

                {isLoading ? (
                  <p>Loading ...</p>
                ) : userAddrsss && userAddrsss.length > 0 ? (
                  userAddrsss.map((item) => (
                    <div className="col-md-3 mb-3">
                      <div className="adrsBorderBx float-left w-100 h-100">
                        <div className="userAdrssInfo float-left w-100 h-100">
                          <div className="float-left w-100 py-2">
                            <div className="adrsLeft">
                              <span>Name</span>
                            </div>
                            <div className="adrsRight">
                              <span>{item.name}</span>
                            </div>
                          </div>
                          <div className="float-left w-100 py-2">
                            <div className="adrsLeft">
                              <span>Address</span>
                            </div>
                            <div className="adrsRight">
                              <span>{item.ship_address1}</span>
                              <span>{item.ship_address2}</span>
                              <span>{item.ship_city}</span>
                              <span>{item.ship_city}</span>
                              <span>{item.ship_state}</span>
                              <span>{item.ship_pincode}</span>
                            </div>
                          </div>
                          <div className="float-left w-100 py-2">
                            <div className="adrsLeft">
                              <span>Contact</span>
                            </div>
                            <div className="adrsRight">
                              <span>{item.mobile}</span>
                            </div>
                          </div>
                          {selectedAddress &&
                          selectedAddress[1].address &&
                          selectedAddress[1].address.id === item.id ? (
                            <div className="selectAdrs float-left w-100 text-center">
                              <button
                                color="success"
                                title="Select"
                                className="m-0 btn-block applyBtn"
                              >
                                selected
                              </button>
                            </div>
                          ) : item.status === statusAddresssbill ? (
                            <div className="selectAdrs float-left w-100 text-center">
                              <button
                                color="success"
                                title="Select"
                                className="m-0 btn-block applyBtn"
                              >
                                selected
                              </button>
                            </div>
                          ) : (
                            <div className="selectAdrs float-left w-100 text-center">
                              <button
                                className="applyBtn btn-block py-2 backBtn"
                                onClick={() => changeAddress(item, 'bill')}
                              >
                                Select
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No record</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <AddAddressModal
          modal={modal}
          toggle={toggle}
          setModal={setModal}
          address={null}
        />
        <div className="float-left w-100 my-3">
          <div className="float-right w-100 text-center ">
            <button
              className=" applyBtn d-inline-block mx-2 py-2 backBtn"
              onClick={() =>
                state && state.buynow
                  ? history.push({
                      pathname: '/ordersummary',
                      state: { buynow: 'buynow' },
                    })
                  : history.push('/ordersummary')
              }
            >
              Back
            </button>
            <button
              className=" applyBtn d-inline-block mx-2 py-2"
              onClick={() =>
                state && state.buynow
                  ? history.push({
                      pathname: '/ordersummary',
                      state: { buynow: 'buynow' },
                    })
                  : history.push('/ordersummary')
              }
            >
              See Order Summary
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ChooseAddress;
