/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { ImgBaseUrl } from '../../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { get_hotdeals } from '../../../../actions/homepageActions';
import { SlickSlider } from '../../../Components';
import { ProjectCard } from '../../../Components';
function Deals() {
  const dispatch = useDispatch();

  const { hotdeals } = useSelector((state) => state.home);
  const [pageNo, setPageNo] = useState(1);

  const fetch_hotdeals = async (page) => {
    try {
      await dispatch(get_hotdeals(page));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const { last_page } = hotdeals ? hotdeals:{}
    if(pageNo <= last_page  ){
      fetch_hotdeals(pageNo);
    }
  }, [pageNo]);

  useEffect(() => {
    fetch_hotdeals(pageNo);
  }, []);

  const { data = [] } = hotdeals ? hotdeals:{};

  return (
    <>
      <div className="productBx w-100 float-left ">
        <div className="productTop w-100 float-left pb-2">
          <Link to="/products/hot-deals" title="View More" className="greenBtn float-right">
            View More
          </Link>
          <h3>Hot Deals </h3>
        </div>
        <div className="productBtm w-100 float-left">
          <SlickSlider changePage={(next)=>{ next +1 > pageNo && setPageNo(next + 1)}}>
            {hotdeals &&
              data.map((item, index) => (
                <div className="proSect" key={item.id}>
                   <ProjectCard item={item}  />
                </div>
              ))}
          </SlickSlider>
        </div>
      </div>
    </>
  );
}

export default Deals;
