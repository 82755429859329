import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import axios from './../../../../utils/axios';
import moment from 'moment';
import { withRouter }  from 'react-router-dom'
import './invoice.css';
class OrderInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice: '',
      isLoading:false
    };
  }

 async componentDidMount() {
    //console.log(JSON.stringify(this.props))
    this.setState({isLoading:true})
   const { orderID ,userID }=this.props.match.params 
    const reqData = {
      order_id: orderID,
      user_id: userID,
    };
    let self = this;
    axios
      .post(`order-invoice`, reqData)
      .then((user) => {
        if (user.data) {
          if (user.data.status === 1) {
            self.setState({
              invoice: user.data.data,
              isLoading:false
            });
          }
          document.addEventListener('click', this.exportPDFWithComponent);
        // self.exportPDFWithComponent() 
        // window.close();
         this.setState({isLoading:false})
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  
  exportPDFWithComponent = () => {
    this.pdfExportComponent.save();
  };

  render() {
    let invoice;
    let transaction_id;
    if (this.state.invoice) {
      invoice = this.state.invoice;
    }

    return (
        this.state.isLoading ? <p>Downloading ...</p>:
      <div>
        <div className="example-config">
          <button className="k-button" onClick={this.exportPDFWithComponent}>
            Download Pdf
          </button>
        </div>
        <div className="border rounded p-2">
          <PDFExport
            ref={(component) => (this.pdfExportComponent = component)}
            paperSize="auto"
            margin={40}
            fileName={`Invoice`}
            author="KendoReact Team"
          >
            <div ref={(container) => (this.container = container)}>
              <div class="offset-xl-2 col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 padding">
                <div class="card">
                  <div class="card-header p-4">
                    <a
                      class="pt-2 d-inline-block"
                      href="index.html"
                      data-abc="true"
                    >
                      <img src="/images/logo.png" alt="logo" />
                    </a>
                    <div class="float-right">
                      <h3 class="mb-0">Invoice {transaction_id}</h3>
                      {invoice &&
                        moment(invoice.updated_at).format('DD MMMM, YYYY')}
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row mb-4">
                      <div class="col-sm-6">
                        <h5 class="mb-3">From:</h5>
                        <h3 class="text-dark mb-1">100 Plus</h3>
                        <div>A-45 Pinacal Tower</div>
                        <div>Sector 64 Noida - 201301</div>
                        <div>Email: Order@hundredplus.in</div>
                        <div>Phone: +91 966 779 0872</div>
                      </div>
                      <div class="col-sm-6 ">
                        <h5 class="mb-3">To:</h5>
                        <h3 class="text-dark mb-1">
                          {invoice && invoice.toname}
                        </h3>
                        <div>{invoice && invoice.ship_address2}</div>

                        <div>
                          {invoice
                            ? invoice.ship_city +
                              ', ' +
                              invoice.ship_landmark +
                              ', ' +
                              invoice.ship_state +
                              ', ' +
                              invoice.ship_pincode
                            : ''}
                        </div>
                        <div>Email: {invoice && invoice.email}</div>
                        <div>Phone: +91 {invoice && invoice.phone}</div>
                      </div>
                    </div>
                    <div class="table-responsive-sm">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="center">#</th>
                            <th>Item</th>

                            <th class="right">Price</th>
                            <th class="center">Qty</th>
                            <th class="right">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoice &&
                            invoice.Products.length > 0 &&
                            invoice.Products.map((item, key) => (
                              <tr key={key}>
                                <td class="center">{key + 1}</td>
                                <td class="left strong">{item.name}</td>
                                <td class="right">{item.price * item.qty}</td>
                                <td class="center">{item.qty}</td>
                                <td class="right">{item.price * item.qty}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-sm-5"></div>
                      <div class="col-lg-4 col-sm-5 ml-auto">
                        <table class="table table-clear">
                          <tbody>
                            <tr>
                              <td class="left">
                                <strong class="text-dark">Subtotal</strong>
                              </td>
                              <td class="right">
                                {invoice && invoice.subtotal}/-
                              </td>
                            </tr>
                            <tr>
                              <td class="left">
                                <strong class="text-dark">Discount</strong>
                              </td>
                              <td class="right">
                                {invoice && invoice.discount} /-
                              </td>
                            </tr>

                            <tr>
                              <td class="left">
                                <strong class="text-dark">Total</strong>{' '}
                              </td>
                              <td class="right">
                                <strong class="text-dark">
                                  {invoice &&
                                    invoice.subtotal - invoice.discount}
                                  /-
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer bg-white">
                    {/* <p class="mb-0">BBBootstrap.com, Sounth Block, New delhi, 110034</p> */}
                  </div>
                </div>
              </div>
            </div>
          </PDFExport>
        </div>
      </div>
    );
  }

}
export default withRouter(OrderInvoice);
