import React from 'react';
import { Link } from 'react-router-dom';

function Breadcrumb(props) {
  return (
    <>
      <div class="breadCrumb w-100 float-left pb-3">
        <ul>
          <li>
            <Link to="/" title="Home">
              Home
            </Link>
          </li>
          <li>
            <Link title="Product">Wishlist</Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Breadcrumb;
