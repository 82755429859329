import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Cashfree from 'cashfree-sdk'
import moment from 'moment'



function Payment({formData}) {

    const makePayment=(e)=>{

    }

    useEffect(()=>{
        // let Payouts = Cashfree.Payouts;
        // const initlise = Payouts.Init({
        //     "ENV": "TEST", 
        //     "ClientID": "8579707b81255ce62c7151349758",
        //     "ClientSecret": "9dbd415d5017e9a20de6393303d95284a801c948"
        // }).then(res=>{
        //     console.log(res,'mahipal')
        // });
        makePayment()
    },[])

    var d = new Date();
    var time = d.getTime();

    return (
    <div>
    <form id="redirectForm" method="post" action="http://daivu.in/payment.php" onSubmit={makePayment}>
    <input type="hidden" name="appId" value="8579707b81255ce62c7151349758"/>
    <input type="hidden" name="orderId" value={time}/>
    <input type="hidden" name="orderAmount" value="100"/>
    <input type="hidden" name="orderCurrency" value="INR"/>
    <input type="hidden" name="orderNote" value="test"/>
    <input type="hidden" name="customerName" value="John Doe"/>
    <input type="hidden" name="customerEmail" value="Johndoe@test.com"/>
    <input type="hidden" name="customerPhone" value="9999999999"/>
    <input type="hidden" name="returnUrl" value="http://daivu.in/response.php"/>
    <input type="hidden" name="notifyUrl" value="http://daivu.in/response.php"/>
    {/* <button type="submit">payment</button> */}
  </form>
        </div>
    );
}

Payment.propTypes = {
    
};
export default Payment;