import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Sidebar } from '../../../Components';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
  FormText,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateProfile,
  changePassword,
  send_opt,
  verifyotp,
} from '../../../../actions/accountActions';

const personaInfo = {
  id: '',
  email: '',
  first_name: '',
  last_name: '',
  mobile: '',
  gender: '',
  dob: '',
};

const passworChangeForm = {
  oldpassword: '',
  password: '',
  cppassword: '',
  user_id: '',
};
const UserInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.account);
  const [personalForm, setpersonalForm] = useState(personaInfo);
  const [formErr, setFormErr] = useState(personaInfo);
  const [changepassForm, setChangepassForm] = useState(passworChangeForm);
  const [changepassFormErr, setChangepassFormErr] = useState(passworChangeForm);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [otpValue, setOtpValue] = useState();

  useEffect(() => {
    if (user) {
      const userinfo = {
        id: user.id,
        email: user.email && user.email === user.mobile   ? '': user.email,
        first_name: user.first_name ? user.first_name : '',
        last_name: user.last_name ? user.last_name : '',
        mobile: user.mobile ? user.mobile : '',
        gender: user.gender ? user.gender : '',
        dob: user.dob ? user.dob : '',
      };
      setpersonalForm(userinfo);
    }
  }, [user]);

  const formValidation = () => {
    let error = true;
    const formerror = { ...formErr };
    if (!personalForm.first_name) {
      error = false;
      formerror.first_name = 'First name is required!';
    }
    if (!personalForm.last_name) {
      error = false;
      formerror.last_name = 'Last name is required!';
    }
    if (!personalForm.gender) {
      error = false;
      formerror.gender = 'Gender is required!';
    }
    setFormErr(formerror);
    return error;
  };

  const constactValidation = () => {
    let error = true;
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const formerror = { ...formErr };
    // if (!personalForm.email) {
    //   error = false;
    //   formerror.mobile = 'mobile No. is required!';
    // }
    if (!personalForm.email) {
      error = false;
      formerror.email = 'Email is required!';
    }
    if (
      personalForm.email &&
      !re.test(String(personalForm.email).toLowerCase())
    ) {
      error = false;
      formerror.email = 'Enter valid email!';
    }

    setFormErr(formerror);
    return error;
  };

  const changePasswordValidation = () => {
    let error = true;
    const formerror = { ...changepassFormErr };
    if (!changepassForm.oldpassword) {
      error = false;
      formerror.oldpassword = 'Old password is required!';
    }
    if (!changepassForm.password) {
      error = false;
      changepassForm.password = 'Password is required!';
    }
    if (!changepassForm.cppassword) {
      error = false;
      formerror.cppassword = 'Confirm password is required!';
    }
    if (
      changepassForm.cppassword &&
      changepassForm.password &&
      changepassForm.cppassword !== changepassForm.password
    ) {
      error = false;
      formerror.cppassword = 'Confirm password and password should be same!';
    }
    setChangepassFormErr(formerror);
    return error;
  };

  const [mobilErr, setMobile_noErr] = useState('');
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const formvalue = { ...personalForm };
    const formError = { ...formErr };
    formError[name] = '';
    formvalue[name] = value;
    setpersonalForm(formvalue);
    setFormErr(formError);
    setMobile_noErr('');
  };

  const handleChangePassword = (e) => {
    console.log(e.target);
    const name = e.target.name;
    const value = e.target.value;
    const formvalue = { ...changepassForm };
    const formError = { ...changepassFormErr };
    formError[name] = '';
    formvalue[name] = value;
    setChangepassForm(formvalue);
    setChangepassFormErr(formError);
  };

  const updateUserInfo = async (reqData) => {
    await dispatch(updateProfile(reqData));
  };

  const submitUserinfo = (e) => {
    e.preventDefault();
    console.log('hello');
    if (formValidation()) {
      updateUserInfo(personalForm);
    }
  };

  const submitChangePassword = (e) => {
    e.preventDefault();
    if (changePasswordValidation()) {
      const reqData = { ...changepassForm };
      reqData.user_id = user && user.id;
      console.log(reqData);
      dispatch(changePassword(reqData));
    }
  };

  const submitContactForm = (e) => {
    e.preventDefault();
    if (constactValidation()) {
      updateUserInfo(personalForm);
    }
  };

  const formValidationphon = () => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let error = true;
    if (!personalForm.mobile) {
      setMobile_noErr('Phone No is required.');
      error = false;
    }

    if (personalForm.mobile && !phoneno.test(personalForm.mobile)) {
      setMobile_noErr('Enter valid phone no.');
      error = false;
    }
    return error;
  };

  const [sentOtp, setSentOtp] = useState(false);
  const handleBlur = async () => {
    // if (!sentOtp) {
      if (formValidationphon()) {
        await dispatch(send_opt({ mobile: personalForm.mobile }));
        setModal(true)
        setSentOtp(true);
      } else {
      }
   // }
  };
  const [optError, setOtpError] = useState('');

 const changeMobile = async()=>{
        try {
          const verifyOtp = {
            mobile: personalForm.mobile,
            otp: otpValue,
          };
          //setIsLoading(true);
          await dispatch(verifyotp(verifyOtp));
          await dispatch(updateProfile(personalForm));
          setModal(false)
        } catch (error) {
          //setModal(false)
        }
  }

  const submitOtp = (e) => {
    e.preventDefault();
    if (otpValue) {
      changeMobile()
    } else {
      setOtpError('Otp is required');
    }
  };

  return (
    <Fragment>
      <div className="userDashboard float-left w-100 my-3">
        <div className="row">
          <Sidebar />
          <div className="col-md-9">
            <div className="userInfoDtl float-left w-100">
              <h4>Personal Information</h4>
              <form onSubmit={submitUserinfo}>
                <div class="personalInfo float-left w-100 py-3">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label
                          for="staticEmail"
                          class="col-sm-4 col-form-label"
                        >
                          First Name
                        </label>
                        <div class="col-sm-8">
                          <input
                            type="text"
                            //readonly
                            placeholder="Enter First Name"
                            class="form-control"
                            id="staticEmail"
                            name="first_name"
                            value={personalForm.first_name}
                            onChange={handleChange}
                          />
                          <FormText color="danger">
                            {formErr.first_name}
                          </FormText>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label
                          for="inputPassword"
                          class="col-sm-4 col-form-label"
                        >
                          Last Name
                        </label>
                        <div class="col-sm-8">
                          <input
                            type="text"
                            class="form-control"
                            id="inputPassword"
                            placeholder="Enter Last Name"
                            name="last_name"
                            value={personalForm.last_name}
                            onChange={handleChange}
                          />
                          <FormText color="danger">
                            {formErr.last_name}
                          </FormText>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label for="gender" class="col-sm-4 col-form-label">
                          Gender
                        </label>
                        <div class="col-sm-8">
                          <select
                            id="gender"
                            class="form-control"
                            name="gender"
                            value={personalForm.gender}
                            onChange={handleChange}
                          >
                            <option selected="">Choose Gender</option>
                            <option value="male">male</option>
                            <option value="female">female</option>
                          </select>

                          <FormText color="danger">{formErr.gender}</FormText>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label for="date" class="col-sm-4 col-form-label">
                          Date of Birth
                        </label>
                        <div class="col-sm-8">
                          <input
                            type="date"
                            class="form-control "
                            id="date"
                            placeholder="Enter DOB"
                            name="dob"
                            onChange={handleChange}
                            value={personalForm.dob}
                          />
                          <FormText color="danger">{formErr.dob}</FormText>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">&nbsp;</div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <div class="col-sm-12 pt-2 text-center ">
                          <button type="submit" class="applyBtn">
                            {' '}
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="userInfoDtl float-left w-100 ">
              <h4>Contact Details </h4>
              <form onSubmit={submitContactForm}>
                <div class="personalInfo float-left w-100 py-3">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group row">
                        <label
                          for="phonenumber"
                          class="col-sm-4 col-form-label"
                        >
                          Phone Number
                        </label>
                        <div class="col-sm-8">
                          <input
                            type="text"
                            //readonly
                            ///disabled
                            required
                            class="form-control"
                            id="phonenumber"
                            name="mobile"
                            value={personalForm.mobile}
                            onChange={handleChange}
                            //onBlur={handleBlur}
                          />
                          <FormText color="danger">{mobilErr}</FormText>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group row">
                        <div class="col-sm-12 pt-2 text-center ">
                          <button
                            type="button"
                            onClick={handleBlur}
                            class="applyBtn"
                          >
                            {' '}
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group row">
                        <label for="email" class="col-sm-4 col-form-label">
                          Email
                        </label>
                        <div class="col-sm-8">
                          <input
                            type="email"
                            class="form-control"
                            id="emai"
                            placeholder="Email"
                            onChange={handleChange}
                            value={personalForm.mobile === personalForm.email ? '': personalForm.email}
                            name="email"
                          />
                          <FormText color="danger">{formErr.email}</FormText>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group row">
                        <div class="col-sm-12 pt-2 text-center ">
                          <button type="submit" class="applyBtn">
                            {' '}
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div class="row">
                    <div class="col-md-6">&nbsp;</div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <div class="col-sm-12 pt-2 text-center ">
                          <button type="submit" class="applyBtn">
                            {' '}
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </form>
            </div>

            <div className="userInfoDtl float-left w-100 ">
              <h4>Change Password</h4>
              <form onSubmit={submitChangePassword}>
                <div class="personalInfo float-left w-100 py-3">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label
                          for="oldpassword"
                          class="col-sm-4 col-form-label"
                        >
                          Old Password
                        </label>
                        <div class="col-sm-8">
                          <input
                            type="password"
                            class="form-control"
                            id="oldpassword"
                            placeholder="Old Password"
                            name="oldpassword"
                            onChange={handleChangePassword}
                            value={changepassForm.oldpassword}
                          />
                          <FormText color="danger">
                            {changepassFormErr.oldpassword}
                          </FormText>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">&nbsp;</div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label
                          for="newpassword"
                          class="col-sm-4 col-form-label"
                        >
                          New Password
                        </label>
                        <div class="col-sm-8">
                          <input
                            type="password"
                            class="form-control"
                            id="newpassword"
                            placeholder="New Password"
                            name="password"
                            onChange={handleChangePassword}
                            value={changepassForm.password}
                          />
                          <FormText color="danger">
                            {changepassFormErr.password}
                          </FormText>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label
                          for="confirmpassword"
                          class="col-sm-4 col-form-label"
                        >
                          Confirm Password
                        </label>
                        <div class="col-sm-8">
                          <input
                            type="password"
                            class="form-control"
                            id="confirmpassword"
                            placeholder="Confirm Password"
                            name="cppassword"
                            onChange={handleChangePassword}
                            value={changepassForm.cppassword}
                          />
                          <FormText color="danger">
                            {changepassFormErr.cppassword}
                          </FormText>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">&nbsp;</div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <div class="col-sm-12 pt-2 text-center ">
                          <button type="submit" class="applyBtn">
                            {' '}
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <form onSubmit={submitOtp}>
          <ModalBody>
            <div class="float-left w-100 popupInner text-center">
              {/* <div class="popUpTitle float-left w-100">
              <h5>Incorrect OTP Entered. Enter New OTP</h5>
            </div> */}
              <div className="popUpBody float-left w-100">
                <strong>Enter OTP</strong>
                <div class="personalInfo float-left w-100 pt-3 pb-2">
                  <input
                    type="text"
                    class="form-control text-center"
                    value={otpValue}
                    placeholder="Enter otp"
                    onChange={(e) => {
                      setOtpValue(e.target.value);
                      setOtpError('');
                    }}
                  />
                  <FormText color="danger">{optError}</FormText>
                </div>
                <Link onClick={async()=>{
                   await setSentOtp(false)
                   handleBlur()
                 
                  }} title="Resend" class="reSend">
                  Resend
                </Link>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button color="primary" type="submit" className="applyBtn">
              {/* {updating && <Spinner color="success" />} Yes */}
              Save
            </button>
            <button
              type="button"
              color="secondary"
              onClick={toggle}
              className="applyBtn backBtn"
            >
              Cancel
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default UserInfo;
