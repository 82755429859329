/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_product, addTocart,add_to_wishlist,addTocartbuyNow } from '../../../actions/productAction';
import { useParams } from 'react-router-dom';
import Breadcrumb from './Includes/breadcrumb';
import ProductDtls from './Includes/productDtl';
import Recommend from './Includes/recommend';
import { useLocation,useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

const HomePage = (props) => {
  const { product_slug } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { product, recomendProduct } = useSelector((state) => state.product);
  const { user } = useSelector((state) => state.account);
  const [isLoading, setIsLoading] = useState(false);
  const [quintity, setQuintity] = useState(1);

  const fetch_product = async (slug) => {
    try {
      setIsLoading(true);
      await dispatch(get_product(slug));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const setaddTocart = (item) => {
    if(quintity > 0){
      const cartItem = {...item}
      cartItem.quintity = quintity
      dispatch(addTocart(cartItem))
      NotificationManager.success('Product added in your cart.','',1000);
    }
    else{
      console.log("please choose quintity");
      NotificationManager.error('Please choose quintity','',2000);
    }
   
  };

  const setbuyaddTocart = (item) => {
    if(quintity > 0){
      const cartItem = {...item}
      cartItem.quintity = quintity
      dispatch(addTocart(cartItem))
      NotificationManager.success('Product added in your cart.','',2000);
    }
    else{
      console.log("please choose quintity");
      NotificationManager.error('Please choose quintity','',2000);
    }
   
  };

  useEffect(() => {
    fetch_product(product_slug);
  }, [product_slug]);

  const addToWishList = (id) => {
    if (user) {
      const reqtoData = {
        user_id: user.id,
        product_id: id,
        status: 2,
        quantity: 0,
      };
      dispatch(add_to_wishlist(reqtoData));
    } else {
    localStorage.setItem('redirectUrl',`${location.pathname}`)
      history.push('/login');
    }
  };

  const buyNow = async(item)=>{
        const cartItem = {...item}
        cartItem.quintity = quintity
        dispatch(addTocartbuyNow(cartItem,history))
  }

  return (
    <div>
      <section class="homePageSect w-100 float-left pt-2 pb-3">
        <div class="container">
          <Breadcrumb url={product_slug}/>
          <ProductDtls
            product={product && product}
            isLoading={isLoading}
            quintity={quintity}
            setaddTocart={setaddTocart}
            setQuintity={setQuintity}
            addToWishList={addToWishList}
            buyNow={buyNow}
          />
          {recomendProduct && recomendProduct.length > 0 && (
            <Recommend recomendProduct={recomendProduct && recomendProduct} />
          )}
        </div>
      </section>
    </div>
  );
};

HomePage.propTypes = {};

export default HomePage;
