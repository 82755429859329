import React from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Sidebar = (props) => {
  const location = useLocation();
  console.log(location.pathname);
  const { user } = useSelector((state) => state.account);

  return (
    <div className="col-md-3">
      <div className="userMenu float-left w-100">
        <ul>
          <li
            className={
              location.pathname === '/userinfo' ? 'profile active' : 'profile'
            }
          >
            <NavLink to="/userinfo" activeClassName="active" title="My Profile">
              {' '}
              My Profile{' '}
            </NavLink>
          </li>
          <li
            className={
              location.pathname === '/myorder' ? 'myorder active' : 'myorder'
            }
          >
            <NavLink to="/myorder" activeClassName="active" title="My Orders">
              My Orders
            </NavLink>
          </li>
          <li
            className={
              location.pathname === '/wishlist' ? 'wishList active' : 'wishList'
            }
          >
            <NavLink to="/wishlist" activeClassName="active" title="Wishlist">
              Wishlist
            </NavLink>
          </li>
          <li
            className={
              location.pathname === '/useraddress'
                ? 'address active'
                : 'address'
            }
          >
            <NavLink to="/useraddress" activeClassName="active" title="Address">
              Address
            </NavLink>
          </li>
          {/* {user && user.act_type !== 1 && (
            <li
              className={
                location.pathname === '/franchiespartner'
                  ? 'franchPartner active'
                  : 'franchPartner'
              }
            >
              <NavLink
                to="/franchiespartner"
                activeClassName="active"
                title="Franchise Partner"
              >
                Franchise Partner
              </NavLink>
            </li>
          )} */}
        </ul>
      </div>
    </div>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
