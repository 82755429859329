import React, { Fragment, useEffect, useState } from 'react';
import { Button, FormGroup, Input, FormText, Spinner } from 'reactstrap';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../hooks';
import { login } from '../../../../actions/accountActions';

const LogIn = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const { user } = useSelector((state) => state.account);
  const [sendOtp, setSendOtp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobile_no, setMobile_no] = useState('');
  const [mobile_noErr, setMobile_noErr] = useState('');
  const [otpverify, setOtpverify] = useState('');
  const [otpverifyErr, setOtpverifyErr] = useState('');
  const [password, setPassword] = useState('');
  const [passwordErr, setPasswordErr] = useState('');

  const handleMobile = (e) => {
    setMobile_no(e.target.value);
    setMobile_noErr('');
  };

  const formValidation = () => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let error = true;
    if (!mobile_no) {
      setMobile_noErr('Phone No is required.');
      error = false;
    }

    if (mobile_no && !phoneno.test(mobile_no)) {
      setMobile_noErr('Enter valid phone no.');
      error = false;
    }
    return error;
  };

  const getCaptchcode = async () => {
    try {
      console.log('loading...');
      setIsLoading(true);
      await auth.loadCaptchaCode();
      console.log('loaded');
      setIsLoading(false);
    } catch (error) {
      console.log();
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCaptchcode();
  }, []);

  const [formLoading, setFormLoading] = useState(false);
  const submitForm = async () => {
    try {
      setFormLoading(true);
      await auth.loginWithmobile_no(`+91${mobile_no}`);
      setSendOtp(true);
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);
      const code = error.code;
      let msg = '';
      switch (code) {
        case 'auth/too-many-requests':
          msg = 'You have tried to many times try after some times.';
          break;

        default:
          msg = 'Opps somethings went wrong';
          break;
      }

      setMobile_noErr(msg);
      console.log(error.code);
    }
  };

  const sendOtpMobile = (e) => {
    e.preventDefault();
    if (formValidation()) {
      submitForm();
    }
  };

  const submitFormOtp = async () => {
    try {
      setFormLoading(true);
      const result = await auth.verifyOpt(otpverify);
      const reqData = {
        mobile: mobile_no,
        uid: result.uid,
        password: '',
        act_type: state && state.logintype === 'franchise' ? 1 : 0,
      };
      await dispatch(login(reqData));
      setFormLoading(false);
      if (localStorage.getItem('redirectUrl')) {
        await history.push(localStorage.getItem('redirectUrl'));
        localStorage.removeItem('redirectUrl');
      } else {
        history.push('/');
      }
    } catch (error) {
      setFormLoading(false);
      const code = error.code;
      let msg = '';
      switch (code) {
        case 'auth/invalid-verification-code':
          msg = 'OTP is not valid!';
          break;
        case 'auth/code-expired':
          msg = 'Otp is expired resend';
          break;

        default:
          msg = error ? error : 'Opps somethings went wrong';
          break;
      }
      setOtpverifyErr(msg);
    }
  };

  const submitOtpForm = (e) => {
    e.preventDefault();
    if (otpverify) {
      submitFormOtp();
    } else {
      setOtpverifyErr('OTP is required!');
    }
  };

  const [loginWithPassword, setLoginWithPassword] = useState(false);

  const loginByPassword = () => {
    if (formValidation()) {
      setLoginWithPassword(true);
      setSendOtp(true);
    }
  };

  const submitFormLoginWithpass = async () => {
    try {
      setFormLoading(true);

      const reqData = {
        mobile: mobile_no,
        password: password,
        act_type: state && state.logintype === 'franchise' ? 1 : 0,
      };
      await dispatch(login(reqData));
      auth.setIsLoggedIn(true);
      setFormLoading(false);

      if (localStorage.getItem('redirectUrl')) {
        await history.push(localStorage.getItem('redirectUrl'));
        localStorage.removeItem('redirectUrl');
      } else {
        history.push('/');
      }
    } catch (error) {
      setFormLoading(false);
      setPasswordErr(error);
    }
  };

  const submitLoginwithPassword = (e) => {
    e.preventDefault();
    if (password) {
      submitFormLoginWithpass();
    } else {
      setPasswordErr('password is required!');
    }
  };

  return (
    <Fragment>
      {localStorage.getItem('userId') && <Redirect to="/" />}
      <div className="logInPage LogInPageSect float-left">
        <div className="float-left w-100 logInDtls">
          {state && state.logintype === 'franchise' ? (
            <div className="logInRight float-left text-center">
              <h3>Welcome Back</h3>
              <div className="float-left w-100 greenBx mt-2">
                <h4>
                  <Link
                    to="/login"
                    onClick={() => {
                      setSendOtp(false);
                      setMobile_no('');
                      setLoginWithPassword(false);
                      setPassword('');
                      setPasswordErr('');
                    }}
                  >
                    Log-In <span className="d-block">as Customer</span>{' '}
                  </Link>
                </h4>
              </div>
              <p className="text-center py-2 signUpRed">
                Do not have an account? <Link to="/register">Sign-up Here</Link>
              </p>
            </div>
          ) : (
            <div className="logInRight float-right text-center ">
              <h3>Welcome Back</h3>
              <div className="float-left w-100 greenBx mt-2">
                <h4>
                  <Link
                    to={{
                      pathname: '/login',
                      state: { logintype: 'franchise' },
                    }}
                    onClick={() => {
                      setSendOtp(false);
                      setMobile_no('');
                      setLoginWithPassword(false);
                      setPassword('');
                      setPasswordErr('');
                    }}
                  >
                    Log-In <span className="d-block">Franchise Partner</span>{' '}
                  </Link>
                </h4>
              </div>
              <p className="text-center py-2 signUpRed">
                Do not have an account? <Link to="/register">Sign-up Here</Link>
              </p>
            </div>
          )}

          <div className="logInLeft float-left">
            <div className="signUpPage float-left w-100">
              {state && state.logintype === 'franchise' ? (
                <h5>Log-In as Franchise Partner</h5>
              ) : (
                <h5>Log-In as Customer</h5>
              )}
              {isLoading ? (
                <Spinner
                  style={{ width: '2rem', height: '2rem' }}
                  color="primary"
                />
              ) : !sendOtp ? (
                <form onSubmit={sendOtpMobile}>
                  <FormGroup>
                    <span
                      className="changeAct"
                      onClick={() => setMobile_no('')}
                    >
                      Change
                    </span>
                    <Input
                      type="text"
                      name="Mobile"
                      placeholder="Enter phone No."
                      className="signUpInput"
                      onChange={(e) => {
                        handleMobile(e);
                      }}
                      value={mobile_no}
                      onKeyDown={(e) => {
                        e.keyCode > 31 &&
                          (e.keyCode < 48 || e.keyCode > 57) &&
                          e.preventDefault();
                      }}
                    />
                    <FormText color="danger">{mobile_noErr}</FormText>
                  </FormGroup>
                  <Button
                    onClick={loginByPassword}
                    type="button"
                    className="applyBtn btn-block"
                    // disabled={formLoading}
                  >
                   Login via Password
                  </Button>
                  <span style={{ textAlign: 'center' }} className="orBtn">
                    OR
                  </span>

                  <Button
                    type="submit"
                    className="applyBtn btn-block"
                    disabled={formLoading}
                  >
                    {formLoading && (
                      <Spinner
                        style={{ width: '1.5rem', height: '1.5rem' }}
                        color="primary"
                      />
                    )}{' '}
                   Login via OTP
                  </Button>
                </form>
              ) : loginWithPassword ? (
                <form id="form-2">
                  <FormGroup>
                    {/* <Link
                      className="changeAct"
                      onClick={() => setSendOtp(false)}
                    >
                      Resend
                    </Link> */}
                    <Input
                      type="password"
                      name="password"
                      placeholder="Enter password"
                      className="signUpInput"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      onFocus={() => setPasswordErr('')}
                    />
                    <FormText color="danger">{passwordErr}</FormText>
                  </FormGroup>

                  {/* <strong className="d-block my-3 text-center">OR</strong> */}
                  <Button
                    className="applyBtn backBtn btn-block"
                    disabled={formLoading}
                    onClick={(e) => submitLoginwithPassword(e)}
                  >
                    {formLoading && (
                      <Spinner
                        style={{ width: '1.5rem', height: '1.5rem' }}
                        color="primary"
                      />
                    )}{' '}
                    Log-in
                  </Button>
                  <span style={{ textAlign: 'center' }} className="orBtn">
                    OR
                  </span>

                  <span
                    className="applyBtn btn-block"
                    onClick={() => {
                      setSendOtp(false);
                      setLoginWithPassword(false);
                    }}
                    style={{ textAlign: 'center' }}
                  >
                    Back
                  </span>
                </form>
              ) : (
                <form id="form-2" onSubmit={submitOtpForm}>
                  <FormGroup>
                    <Link
                      className="changeAct"
                      onClick={() => {
                        //setSendOtp(false);
                        //setLoginWithPassword(false);
                        submitForm();
                      }}
                    >
                      Resend
                    </Link>
                    <Input
                      type="password"
                      name="otp"
                      placeholder="Enter OTP"
                      className="signUpInput"
                      onChange={(e) => setOtpverify(e.target.value)}
                      value={otpverify}
                      onFocus={() => setOtpverifyErr('')}
                    />
                    <FormText color="danger">{otpverifyErr}</FormText>
                  </FormGroup>

                  {/* <strong className="d-block my-3 text-center">OR</strong> */}
                  <Button
                    className="applyBtn backBtn btn-block"
                    disabled={formLoading}
                  >
                    {formLoading && (
                      <Spinner
                        style={{ width: '1.5rem', height: '1.5rem' }}
                        color="primary"
                      />
                    )}{' '}
                   Verify OTP
                  </Button>
                  <span style={{ textAlign: 'center' }} className="orBtn">
                    OR
                  </span>

                  <span
                    className="applyBtn btn-block"
                    onClick={() => {
                      setSendOtp(false);
                      setLoginWithPassword(false);
                    }}
                    style={{ textAlign: 'center' }}
                  >
                    Back
                  </span>
                </form>
              )}
            {!isLoading && <div className="text-right w-100 pt-2 pr-2"><Link to="/forgotpassword">Forgot password</Link></div>}
              <div id="recaptcha-container"></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LogIn;
