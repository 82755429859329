import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



class Slickslider extends Component {
    render() {

        const settings = {
            dots: false,
            infinite: true,
            centerMode:true,
            slidesToShow: this.props.slideshow,
            slidesToScroll: 1,
            speed: 500,
            centerPadding:0,
            beforeChange: (current, next) => {
              console.log(current, next)
              return this.props.changePage(next)},
           
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
          };

        return (
            <Slider {...settings}>
                {this.props.children}
            </Slider>
        );
    }
}

Slickslider.defaultProps = {
    theme: "secondary",
    slideshow: 4,
    changePage:(e)=>{ console.log(e,"mahipal") }
  };

Slickslider.propTypes = {
    children: PropTypes.node,
    slideshow:PropTypes.number,
    changePage:PropTypes.func
};

export default Slickslider;