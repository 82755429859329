import React, { Fragment, useEffect, useState } from 'react';
import { Button, FormGroup, Input, FormText, Spinner } from 'reactstrap';
import { Link, useHistory, useLocation,Redirect } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../../hooks';
import { register,validate_user } from '../../../../actions/accountActions';
import VerifyAccount from '../../VerifyAccount/Includes/VerifyAccount';

const SignUp = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const [sendOtp, setSendOtp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobile_no, setMobile_no] = useState('');
  const [mobile_noErr, setMobile_noErr] = useState('');
  const [otpverify, setOtpverify] = useState('');
  const [otpverifyErr, setOtpverifyErr] = useState('');
  const [form, setForm] = useState({ password: '', act_type: '' });
  const [formErr, setFormErr] = useState({ password: '', act_type: '' });
  const [error, setError] = useState('');
  const [showmessage, setShowmessage] = useState(false);

  const handleMobile = (e) => {
    setMobile_no(e.target.value);
    setMobile_noErr('');
  };

  const formValidation = () => {
    const formValue = { ...form };
    const formError = { ...formErr };

    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    let error = true;
    if (!mobile_no) {
      setMobile_noErr('Phone No is required.');
      error = false;
    }

    if (mobile_no && !phoneno.test(mobile_no)) {
      setMobile_noErr('Enter valid phone no.');
      error = false;
    }

    if (!formValue.password) {
      formError.password = 'Password is required!';
      error = false;
    }

    if (!formValue.cppassword) {
      formError.cppassword = 'Confirm password is required!';
      error = false;
    }

    if (
      formValue.password &&
      formValue.cppassword &&
      formValue.cppassword !== formValue.password
    ) {
      formError.cppassword = 'Password and confirm password should be same.';
      error = false;
    }

    if (formValue.act_type === '') {
      formError.act_type = 'Account type is required!';
      error = false;
    }
    setFormErr(formError);
    return error;
  };

  const getCaptchcode = async () => {
    try {
      setIsLoading(true);
      await auth.loadCaptchaCode();
      console.log('loaded');
      setIsLoading(false);
    } catch (error) {
      console.log();
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCaptchcode();
  }, []);

  const [formLoading, setFormLoading] = useState(false);
  const submitForm = async () => {
    try {
      setFormLoading(true);
      setError('');
      const reqData = {
        mobile: mobile_no,
        password: form.password,
        act_type: form.act_type,
        uid: '',
      };
      await dispatch(validate_user(reqData));

      await auth.loginWithmobile_no(`+91${mobile_no}`);
      setSendOtp(true);
      setFormLoading(false);
    } catch (error) {
      setFormLoading(false);
      const code = error.code;
      let msg = '';
      switch (code) {
        case 'auth/too-many-requests':
          msg = 'You have tried to many times try after some times.';
          setMobile_noErr(msg);
          break;
        case 'allready/user':
          msg = 'Number is already registered, please login.';
          setError(msg);
          break;
        default:
          msg = error ? error : 'Opps somethings went wrong';
          setError(msg);
          break;
      }

      console.log(error, 'eroor');
    }
  };

  const sendOtpMobile = (e) => {
    e.preventDefault();
    if (formValidation()) {
      submitForm();
    }
  };

  const submitFormOtp = async () => {
    try {
      setFormLoading(true);
      const result = await auth.verifyOpt(otpverify);
      const reqData = {
        mobile: mobile_no,
        password: form.password,
        act_type: form.act_type,
        uid: result.uid,
      };
      await dispatch(register(reqData));
      setFormLoading(false);

      if (localStorage.getItem('redirectUrl') && form.act_type !== '1') {
        await history.push(localStorage.getItem('redirectUrl'));
        localStorage.removeItem('redirectUrl');
      }
      if (form.act_type === '1') {
        await auth.logout();
        setShowmessage(true);
        
      } else {
        history.push('/');
      }
    } catch (error) {
      setFormLoading(false);
      const code = error.code;
   
      let msg = '';
      switch (code) {
        case 'auth/invalid-verification-code':
          msg = 'OTP is not valid!';
          break;
        case 'auth/code-expired':
          msg = 'Otp is expired resend';
          break;

        case 'allready/user':
          msg = 'Number is already registered, please login.';
          break;

        default:
          msg = error ? error : 'Opps somethings went wrong';
          break;
      }
      console.log(msg, error);
      setOtpverifyErr(msg);
     
    }
  };

  const submitOtpForm = (e) => {
    e.preventDefault();
    if (otpverify) {
      submitFormOtp();
    } else {
      setOtpverifyErr('OTP is required!');
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const formValue = { ...form };
    const formError = { ...formErr };
    formValue[name] = value;
    formError[name] = '';
    setForm(formValue);
    setFormErr(formError);
  };

  return (
    <Fragment>
      {localStorage.getItem('userId') && <Redirect to="/" />}
      {showmessage ? (
        <VerifyAccount />
      ) : (
        <div className="logInPage float-left wow fadeInUp">
          <div className="float-left w-100 logInDtls">
            <div className="logInRight float-right text-center">
              <h3>
                Welcome to<span className="d-block"> 100PlusMall</span>{' '}
              </h3>
            </div>
            <div className="logInLeft float-left">
              <div className="signUpPage float-left w-100">
                <h5>Create Account</h5>
                {isLoading ? (
                  <Spinner
                    style={{ width: '2rem', height: '2rem' }}
                    color="primary"
                  />
                ) : !sendOtp ? (
                  <form onSubmit={sendOtpMobile}>
                    <FormGroup>
                      <span
                        className="changeAct"
                        onClick={() => setMobile_no('')}
                      >
                        Change
                      </span>
                      <Input
                        maxLength={10}
                        type="text"
                        name="Mobile"
                        placeholder="Enter phone No."
                        className="signUpInput"
                        onChange={(e) => {
                          handleMobile(e);
                        }}
                        value={mobile_no}
                        onKeyDown={(e) => {
                          //console.log(e.keyCode)

                          e.keyCode > 31 &&
                            (e.keyCode < 48 || e.keyCode > 57) &&
                            e.preventDefault();
                        }}
                      />
                      <FormText color="danger">{mobile_noErr}</FormText>
                    </FormGroup>

                    <FormGroup>
                      <Input
                        type="select"
                        onChange={handleChange}
                        value={form.act_type}
                        name="act_type"
                      >
                        <option value={''}>Account Type</option>
                        <option value={1}>Franchise Partner</option>
                        <option value={0}>Customer</option>
                      </Input>
                      <FormText color="danger">{formErr.act_type}</FormText>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        required
                        title="Password at least 6 or more characters"
                        minLength={6}
                        type="password"
                        name="password"
                        placeholder="password"
                        className="signUpInput"
                        onChange={handleChange}
                        value={form.password}
                      />
                      <FormText color="danger">{formErr.password}</FormText>
                    </FormGroup>
                    <FormGroup>
                      <Input
                        required
                        title="Password at least 6 or more characters"
                        minLength={6}
                        type="password"
                        name="cppassword"
                        placeholder="confirm password"
                        className="signUpInput"
                        onChange={handleChange}
                        value={form.cppassword}
                      />
                      <FormText color="danger">{formErr.cppassword}</FormText>
                      <FormText color="danger">{error}</FormText>
                    </FormGroup>
                    
                    <Button
                      className="applyBtn btn-block"
                      disabled={formLoading}
                    >
                      {formLoading && (
                        <Spinner
                          style={{ width: '1.5rem', height: '1.5rem' }}
                          color="primary"
                        />
                      )}
                      Create Account
                    </Button>
                    <p className="text-center pt-3">
                      Already have an account?{' '}
                      <Link to="/login">Log-in here</Link>
                    </p>
                   
                  </form>
                ) : (
                  <form id="form-2" onSubmit={submitOtpForm}>
                    <FormGroup>
                      <Link
                        className="changeAct"
                        onClick={() => setSendOtp(false)}
                      >
                        Resend
                      </Link>
                      <Input
                        type="password"
                        name="otp"
                        placeholder="Enter OTP"
                        className="signUpInput"
                        onChange={(e) => setOtpverify(e.target.value)}
                        value={otpverify}
                        onFocus={() => setOtpverifyErr('')}
                      />
                      <FormText color="danger">{otpverifyErr}</FormText>
                    </FormGroup>

                    {/* <strong className="d-block my-3 text-center">OR</strong> */}
                    <Button
                      className="applyBtn backBtn btn-block"
                      disabled={formLoading}
                    >
                      {formLoading && (
                        <Spinner
                          style={{ width: '1.5rem', height: '1.5rem' }}
                          color="primary"
                        />
                      )}{' '}
                      Verify OTP
                    </Button>
                  </form>
                )}
              </div>
              <div id="recaptcha-container"></div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SignUp;
