import React, { useEffect } from 'react';
import { ImgBaseUrl } from '../../../../config';
import { useDispatch, useSelector } from 'react-redux';
import { get_brands } from '../../../../actions/homepageActions';
import { SlickSlider } from '../../../Components';
import { Link } from 'react-router-dom'

const Brands = () => {
  const dispatch = useDispatch();

  const { brands } = useSelector((state) => state.home);

  const fetch_brands = async (page) => {
    try {
      await dispatch(get_brands({ page }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetch_brands(1);
  }, []);
  return (
    <>
      <div class="productBx w-100 float-left ">
        <div class="shopByBrand w-100 float-left">
          <div class="shopByBrandTop w-100 float-left">
            <h3>Shop By Brand </h3>
          </div>
          <div class="shopByBrand w-100 float-left">
            <div class="brandArea w-100 float-left">
             
                <SlickSlider slideshow={5}>
                {brands.map((item, index) => (
                  <Link 
                  //to={`/brands/${item.name.split(' ').join('-').toLowerCase()}/${item.id}`}
                  to={{
                    pathname: `/brands/${item.name
                      .split(' ')
                      .join('-')
                      .toLowerCase()}/${item.id}`,
                    state: {
                      metatitle: item.metatitle,
                      description: item.metatitle,
                      metakeyword: item.metakeyword,
                    },
                  }}
                  >
                  <div class="col text-center">
                    <img
                      src={ImgBaseUrl+item.image}
                      onError={(e) => {
                        e.target.src = 'images/img_brand1.jpg';
                      }}
                      alt=""
                    />
                  </div>
                  </Link>
                ))}
                </SlickSlider>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
